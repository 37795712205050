<template>
  <div class="Recruitment">
    <div :class="$global.isMobile ? 'recruitment-list-mobile' : 'recruitment-list-pc'">
      <div class="recruitment-list-title"> 招聘岗位 </div>
      <div class="recruitment-list-content" v-if="localResults">
        <div class="recruitment-list-content-list" v-for="(item, index) in localResults.recruitments" :key="index" :class="recruitmentIndex === index ? 'autoHeight' : ''"
          @click="onRecruitmentClick(index)">
          <div class="recruitment-list-content-list-contact" v-if="recruitmentIndex === index">
            <div class="recruitment-list-content-list-contact-image"> <img src="../../assets/images/Recruitment/jux.png" />
            </div>
            <div class="recruitment-list-content-list-contact-title"> 招聘邮箱 </div>
            <div class="recruitment-list-content-list-contact-email"> 邮箱：{{ item.email }} </div>
            <div class="recruitment-list-content-list-contact-title"> 招聘电话 </div>
            <div class="recruitment-list-content-list-contact-email"> 手机/微信：{{ item.phone }} </div>
          </div>
          <div class="recruitment-list-content-list-title"> {{ item.name }} </div>
          <div class="recruitment-list-content-list-line"></div>
          <div class="recruitment-list-content-list-position-statement"> 岗位职责 </div>
          <div class="recruitment-list-content-list-position-statement-list" v-html="item.duty">
            <!-- <ul>
              <li v-for="(i_item, i_index) in 4" :key="i_index">
                1）对未来几十年的人工智能持续看好，看好人工智能的商业应用；
              </li>
            </ul> -->
            {{ item.duty }}
          </div>
          <div class="recruitment-list-content-list-job-requirements"> 岗位要求 </div>
          <div class="recruitment-list-content-list-job-requirements-list" v-html="item.claim">
            <!-- <ul>
              <li v-for="(i_item, i_index) in 4" :key="i_index">
                1）对未来几十年的人工智能持续看好，看好人工智能的商业应用；
              </li>
            </ul> -->
            {{ item.claim }}
          </div>
          <div class="recruitment-list-content-list-packup">
            <div class="recruitment-list-content-list-packup-image-1" :class="recruitmentIndex==index ? '' : 'hideHeight'">
              <img src="../../assets/images/Recruitment/recruitment.png" />
            </div>
            <div class="recruitment-list-content-list-packup-image-2" :class="recruitmentIndex==index ? 'hideHeight' : ''">
              <img src="../../assets/images/Recruitment/recruitment2.png" />
            </div>
            <div class="recruitment-list-content-list-packup-bgcolor" :class="recruitmentIndex==index ? 'hideHeight' : ''">
              <img src="../../assets/images/Recruitment/bgcolor.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div :class="$global.isMobile ? 'recruitment-process-mobile' : 'recruitment-process-pc'">
      <div class="recruitment-process-content">
        <div class="recruitment-process-content-title"> 招聘流程 </div>
        <div class="recruitment-process-content-image"> <img src="../../assets/images/Recruitment/rec.png" /> </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  /* 关于我们-加入我们 */
  name: 'Recruitment',
  components: { },
  metaInfo () {
    return {
      title: this.headMetaInfo.title,
      meta: [
        { name: 'keywords', content: this.headMetaInfo.keywords },
        { name: 'discription', content: this.headMetaInfo.content }
      ]
    }
  },
  data () {
    return {
      headMetaInfo: {
        title: '广州帕克西软件开发有限公司',
        keywords: '',
        content: ''
      },
      nav_id: -1,
      recruitmentIndex: 0,
      localResults: []
    }
  },
  watch: {
    $route () {
      this.reqlocalResults()
    }
  },
  created () { },
  mounted () {
    this.headMetaInfo.title = this.$global.head_title
    this.headMetaInfo.keywords = this.$global.head_keywords
    this.headMetaInfo.content = this.$global.head_description
    this.reqlocalResults()
  },
  methods: {
    reqlocalResults: function () {
      if (this.$route.params.id) {
        this.nav_id = this.$route.params.id
      } else if (this.$route.query.id) {
        this.nav_id = this.$route.query.id
      } else {
        this.nav_id = -1
      }
      if (this.nav_id !== -1) {
        this.$axios({
          url: 'api/join-us/' + this.nav_id,
          method: 'get'
        }).then((res) => {
          /* console.log(res.data) */
          this.localResults = null
          if (res.data) {
            if (res.data.result && res.data.result.length > 0) {
              this.localResults = res.data.result[0]
              // console.log(this.localResults)
              /* 设置Header的banner图 */
              if (this.localResults.banner) {
                this.$emit('commandEvent', 'header-banner', [{ thumb: this.localResults.banner }])
              } else {
                this.$emit('commandEvent', 'header-banner', null)
              }
              if (this.localResults) {
                this.$global.onUpdateHeadTitle('关于我们-加入我们-广州帕克西软件开发有限公司')
                this.headMetaInfo.title = '关于我们-加入我们-广州帕克西软件开发有限公司'
                var headKeywords = []
                if (this.localResults.recruitments && this.localResults.recruitments.length > 0) {
                  for (var r = 0; r < this.localResults.recruitments.length; r++) {
                    if (this.localResults.recruitments[r] && this.localResults.recruitments[r].name) {
                      const nameString = this.localResults.recruitments[r].name.replace(/\s+|&nbsp;/ig, '').trim()
                      const nameIndexfirst1 = nameString.indexOf('）')
                      const nameIndexlast1 = nameString.lastIndexOf('（')
                      var firstIndex = 0
                      if (nameIndexlast1 !== -1) {
                        if (nameIndexfirst1 < nameIndexlast1 && nameIndexfirst1 > 0) {
                          firstIndex = nameIndexfirst1 + 1
                        }
                        headKeywords.push(this.localResults.recruitments[r].name.substring(firstIndex, nameIndexlast1))
                      } else {
                        const nameIndexfirst2 = nameString.indexOf(')')
                        const nameIndexlast2 = nameString.lastIndexOf('(')
                        if (nameIndexlast2 !== -1) {
                          if (nameIndexfirst2 < nameIndexlast2 && nameIndexfirst2 > 0) {
                            firstIndex = nameIndexfirst2 + 1
                          }
                          headKeywords.push(this.localResults.recruitments[r].name.substring(0, nameIndexlast2))
                        } else {
                          headKeywords.push(this.localResults.recruitments[r].name)
                        }
                      }
                    }
                  }
                }
                headKeywords.push('加入我们')
                headKeywords.push('招聘岗位')
                headKeywords.push('招聘')
                this.$global.onUpdateHeadKeywords(headKeywords, false)
                this.headMetaInfo.keywords = this.$global.getHeadKeywords(headKeywords, false)
              }
            } else {
              this.$emit('commandEvent', 'header-banner', null)
            }
          } else {
            this.$emit('commandEvent', 'header-banner', null)
          }
        })
      } else {
        console.log('跳转首页')
        if (window.location.href.indexOf('localhost') === -1) { // 要加上这句判断，否则打包有问题，缺少按照路由文件夹打包
          this.$router.push({ path: '/' })
        } else {
          this.localResults = null
          this.$emit('commandEvent', 'header-banner', null)
        }
      }
    },
    onRecruitmentClick: function (index) {
      if (index !== this.recruitmentIndex) {
        this.recruitmentIndex = index
      } else {
        this.recruitmentIndex = -1
      }
    }
  }
}
</script>

<style lang="less" scoped>
.Recruitment {
  color: #333333;
  width: 100%;
  overflow: hidden;
  background-color: #F6F6F6;
  .recruitment-list-pc {
    width: var(--v-width);
    overflow: hidden;
    margin: 0 auto;
    .recruitment-list-title {
      font-size: 32px;
      font-weight: 700;
      text-align: center;
      padding: 50px 0;
      margin: 0 auto;
    }
    .recruitment-list-content {
      width: 1099px;
      margin: 0 auto;
      position: relative;
      .recruitment-list-content-list {
        position: relative;
        background-color: #FFFFFF;
        padding-top: 50px;
        height: 323px;
        margin-bottom: 20px;
        overflow: hidden;
        .recruitment-list-content-list-contact {
          width: 261px;
          height: 297px;
          position: absolute;
          right: 87px;
          top: 0px;
          .recruitment-list-content-list-contact-image {
            width: 100%;
            height: 100%;
            position: absolute;
            img {
              width: 100%;
            }
          }
          .recruitment-list-content-list-contact-title {
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            color: #ffffff;
            margin-top: 40px;
            margin-bottom: 20px;
            position: relative;
          }
          .recruitment-list-content-list-contact-email {
            font-size: 14px;
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            position: relative;
          }
        }
        .recruitment-list-content-list-title {
          font-size: 26px;
          font-weight: 700;
          text-align: left;
          // padding: 40px 0px;
          padding-top: 40px;
          width: 925px;
          padding: 0 87px;
        }
        .recruitment-list-content-list-line {
          width: 424px;
          height: 1px;
          background-color: #d1d1d1;
          margin: 40px 87px;

        }
        .recruitment-list-content-list-position-statement {
          font-size: 20px;
          font-weight: bold;
          text-align: left;
          margin: 0 0 20px 0;
          width: 925px;
          padding: 0 87px;
        }
        .recruitment-list-content-list-position-statement-list {
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          line-height: 40px;
          width: 640px;
          padding: 0 87px;
          ul {
            li {
              font-size: 16px;
              font-weight: 400;
              text-align: left;
              line-height: 40px;
              width: 925px;
              padding: 0 87px;
            }
          }
        }
        .recruitment-list-content-list-job-requirements {
          font-size: 20px;
          font-weight: bold;
          text-align: left;
          margin: 20px 0;
          width: 925px;
          padding: 0 87px;
        }
        .recruitment-list-content-list-job-requirements-list {
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          line-height: 40px;
          width: 640px;
          padding: 0 87px;
          ul {
            li {
              font-size: 16px;
              font-weight: 400;
              text-align: left;
              line-height: 40px;
              width: 925px;
              padding: 0 87px;
            }
          }
        }
        .recruitment-list-content-list-packup {
          width: 100%;
          height: 41px;
          text-align: center;
          background-color: #FFFFFF;
          width: 925px;
          padding: 0 87px;
          margin: 0 auto;
          position: absolute;
          bottom: 0px;
          cursor: pointer;
          .recruitment-list-content-list-packup-image-1 {
            width: 22px;
            height: 41px;
            line-height: 41px;
            text-align: center;
            margin: 0 auto;
            position: relative;
            img {
              position: absolute;
              top: 15px;
            }
          }
          .recruitment-list-content-list-packup-image-2 {
            width: 22px;
            height: 41px;
            line-height: 41px;
            text-align: center;
            margin: 0 auto;
            position: relative;
            img {
              position: absolute;
              top: 15px;
            }
          }
          .recruitment-list-content-list-packup-bgcolor {
            width: 100%;
            height: 41px;
            position: absolute;
            bottom: 41px;
            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .recruitment-list-mobile {
    width: var(--v-width);
    overflow: hidden;
    margin: 0 auto;
    .recruitment-list-title {
      font-size: var(--v-font-size-32);
      font-weight: 700;
      text-align: center;
      padding: 30px 0;
      margin: 0 auto;
    }
    .recruitment-list-content {
      width: calc(100% - 20px);
      margin: 0 auto;
      position: relative;
      .recruitment-list-content-list {
        position: relative;
        background-color: #FFFFFF;
        padding-top: 10px;
        //height: 323px;
        height: 210px;
        margin-bottom: 10px;
        overflow: hidden;
        .recruitment-list-content-list-contact {
          width: 110px;
          //height: 297px;
          position: absolute;
          right: 0px;
          top: 0px;
          padding: 40px auto;
          // 自动换行
          word-wrap: break-word;
          .recruitment-list-content-list-contact-image {
            width: 100%;
            height: 100%;
            position: absolute;
            img {
              width: 100%;
            }
          }
          .recruitment-list-content-list-contact-title {
            font-size: var(--v-font-size-16);
            font-weight: 700;
            text-align: center;
            color: #ffffff;
            margin-top: 5px;
            margin-bottom: 2px;
            position: relative;
          }
          .recruitment-list-content-list-contact-email {
            font-size: var(--v-font-size-14);
            font-weight: 400;
            text-align: center;
            color: #ffffff;
            position: relative;
          }
        }
        .recruitment-list-content-list-title {
          font-size: var(--v-font-size-24);
          font-weight: 700;
          text-align: left;
          // padding: 40px 0px;
          padding-top: 40px;
          //width: 925px;
          width: 100%;
          padding: 10px 20px;
        }
        .recruitment-list-content-list-line {
          //width: 424px;
          width: 60%;
          height: 1px;
          background-color: #d1d1d1;
          margin: 10px 20px;
        }
        .recruitment-list-content-list-position-statement {
          font-size: var(--v-font-size-18);
          font-weight: bold;
          text-align: left;
          margin: 0 0 10px 0;
          //width: 925px;
          width: 100%;
          padding: 0 20px;
        }
        .recruitment-list-content-list-position-statement-list {
          font-size: var(--v-font-size-16);
          font-weight: 400;
          text-align: left;
          line-height: 20px;
          //width: 640px;
          //padding: 0 87px;
          width: calc(100% - 40px);
          padding: 0 20px;
          ul {
            li {
              font-size: 16px;
              font-weight: 400;
              text-align: left;
              line-height: 20px;
              //width: 925px;
              width: 80%;
              padding: 0 87px;
            }
          }
        }
        .recruitment-list-content-list-job-requirements {
          font-size: var(--v-font-size-18);
          font-weight: bold;
          text-align: left;
          margin: 10px 0 10px 0;
          //width: 925px;
          width: 100%;
          padding: 0 20px;
        }
        .recruitment-list-content-list-job-requirements-list {
          font-size: var(--v-font-size-16);
          font-weight: 400;
          text-align: left;
          line-height: 20px;
          //width: 640px;
          //padding: 0 87px;
          width: calc(100% - 40px);
          padding: 0 20px;
          ul {
            li {
              font-size: 16px;
              font-weight: 400;
              text-align: left;
              line-height: 40px;
              //width: 925px;
              width: 80%;
              padding: 0 87px;
            }
          }
        }
        .recruitment-list-content-list-packup {
          width: 100%;
          height: 41px;
          text-align: center;
          background-color: #FFFFFF;
          //padding: 0 87px;
          margin: 0 auto;
          position: absolute;
          bottom: 0px;
          cursor: pointer;
          .recruitment-list-content-list-packup-image-1 {
            width: 22px;
            height: 41px;
            line-height: 41px;
            text-align: center;
            margin: 0 auto;
            position: relative;
            img {
              position: absolute;
              top: 15px;
            }
          }
          .recruitment-list-content-list-packup-image-2 {
            width: 22px;
            height: 41px;
            line-height: 41px;
            text-align: center;
            margin: 0 auto;
            position: relative;
            img {
              position: absolute;
              top: 15px;
            }
          }
          .recruitment-list-content-list-packup-bgcolor {
            width: 100%;
            //height: 41px;
            height: 31px;
            position: absolute;
            //bottom: 41px;
            bottom: 31px;
            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .recruitment-process-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    padding-bottom: 150px;
    .recruitment-process-content {
      width: var(--v-width);
      overflow: hidden;
      margin: 0 auto;
      .recruitment-process-content-title {
        font-size: 32px;
        font-weight: 700;
        text-align: center;
        padding: 50px 0;
      }
      .recruitment-process-content-image {
        width: 1127px;
        height: 164px;
        margin: 0 auto;
        img {
          width: 100%;
        }
      }
    }
  }
  .recruitment-process-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    padding-bottom: 30px;
    .recruitment-process-content {
      width: var(--v-width);
      overflow: hidden;
      margin: 0 auto;
      .recruitment-process-content-title {
        font-size: var(--v-font-size-32);
        font-weight: 700;
        text-align: center;
        padding: 30px 0;
      }
      .recruitment-process-content-image {
        //width: 1127px;
        //height: 164px;
        width: calc(100% - 40px);
        margin: 0 20px;
        img {
          width: 100%;
        }
      }
    }
  }
}

.autoHeight {
  height: auto !important;
  padding-bottom: 50px;
}

.hideHeight {
  display: none !important;
}
</style>
