<template>
  <div class="NewsDetails">
    <div v-if="localResults">
      <div
        :class="$global.isMobile ? 'news-details-content-mobile' : 'news-details-content-pc'"
        v-if="localResults['news']"
      >
        <div class="news-details-content-title"> {{ localResults['news'].title }} </div>
        <div class="news-details-content-time">
          发布日期：{{ localResults['news'].created_at | formatDate }}
        </div>
        <div style="width: 100%; height: 1px; background: #d1d1d1; margin-top: 10px;" />
        <div class="news-details-content-html" v-html="localResults['news'].content"></div>
        <div class="news-details-content-share">
          <ul>
            <div class="news-details-content-share-title">分享</div>
            <li @mouseenter="onClickIconWeibo" @mouseleave="onClickIconWeibo">
              <a
                href="javascript:(function(){window.open('http://v.t.sina.com.cn/share/share.php?title='+encodeURIComponent(document.title)+'&url='+encodeURIComponent(location.href)+'&source=bookmark','_blank');})()"
                title="新浪微博分享"
              >
                <img v-show="isShowIoc3" src="../../assets/images/NewsDetails/ioc3.png" />
                <img v-show="!isShowIoc3" src="../../assets/images/NewsDetails/ioc33.png" />
              </a>
            </li>
            <li @mouseenter="onClickIconWechat" @mouseleave="onClickIconWechat">
              <img v-show="isShowIoc2" src="../../assets/images/NewsDetails/ioc2.png" />
              <img v-show="!isShowIoc2" src="../../assets/images/NewsDetails/ioc22.png" />
            </li>
            <div id="qrcode" class="qrcode" v-show="showQrcode" ref="qrcodeDiv">
              <!-- https://www.cnblogs.com/ajuan/p/10100931.html -->
              <!-- https://www.cnblogs.com/steamed-twisted-roll/p/11271829.html -->
              <!-- <img title='' style="cursor:pointer" src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=window.location.href" /> -->
            </div>
            <li @mouseenter="onClickIconQQ" @mouseleave="onClickIconQQ">
              <a
                href="javascript:(function(){window.open('http://connect.qq.com/widget/shareqq/index.html?url='+encodeURIComponent(location.href)+'&sharesource=qzone&title='+encodeURIComponent(document.title)+'','_blank');})()"
                title="QQ分享"
              >
                <img v-show="isShowIoc1" src="../../assets/images/NewsDetails/ioc1.png" />
                <img v-show="!isShowIoc1" src="../../assets/images/NewsDetails/ioc11.png" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <NullPage />
      </div>
      <div
        :class="$global.isMobile ? 'news-details-related-mobile' : 'news-details-related-pc'"
        v-if="localResults['related_news'] && localResults['related_news'].length > 0"
      >
        <div class="news-details-related-content">
          <div class="news-details-related-content-title"> 相关新闻 </div>
          <div class="news-details-related-content-list">
            <ul>
              <li v-for="(item, index) in localResults['related_news']" :key="index">
                <div class="news-details-related-content-image">
                  <img :src="item.thumb" />
                </div>
                <div class="news-details-related-content-title"> {{ item.title }} </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else style="height: 50px"></div>
    </div>
    <div v-else>
      <NullPage />
    </div>
  </div>
</template>

<script>
import NullPage from '@/components/NullPage'
import QRCode from 'qrcodejs2'
export default {
  /* 新闻详情 */
  name: 'NewsDetails',
  components: {
    NullPage
  },
  metaInfo () {
    return {
      title: this.headMetaInfo.title,
      meta: [
        { name: 'keywords', content: this.headMetaInfo.keywords },
        { name: 'discription', content: this.headMetaInfo.content }
      ]
    }
  },
  data () {
    return {
      headMetaInfo: {
        title: '广州帕克西软件开发有限公司',
        keywords: '',
        content: ''
      },
      isShowIoc3: true,
      isShowIoc2: true,
      isShowIoc1: true,
      showQrcode: false,
      new_id: -1,
      localResults: null
    }
  },
  watch: {
    $route () { this.reqlocalResults() }
  },
  created () {
    this.$emit('commandEvent', 'header-banner', [])
  },
  mounted () {
    this.headMetaInfo.title = this.$global.head_title
    this.headMetaInfo.keywords = this.$global.head_keywords
    this.headMetaInfo.content = this.$global.head_description
    this.reqlocalResults()
    this.$emit('commandEvent', 'header-banner', [])
  },
  computed: { },
  methods: {
    onClickIconWeibo: function () {
      this.isShowIoc3 = !this.isShowIoc3
    },
    onClickIconWechat: function () {
      this.isShowIoc2 = !this.isShowIoc2
      this.showQrcode = !this.showQrcode
      document.getElementById('qrcode').innerHTML = ''
      if (this.showQrcode) {
        /* eslint-disable no-new */
        new QRCode(this.$refs.qrcodeDiv, {
          text: window.location.href,
          width: 200,
          height: 200,
          colorDark: '#333333', // 二维码颜色
          colorLight: '#ffffff', // 二维码背景色
          correctLevel: QRCode.CorrectLevel.L // 容错率，L/M/H
        })
      }
    },
    onClickIconQQ: function () {
      this.isShowIoc1 = !this.isShowIoc1
    },
    reqlocalResults: function () {
      if (this.$route.params.id) {
        this.new_id = this.$route.params.id
      } else if (this.$route.query.id) {
        this.new_id = this.$route.query.id
      } else {
        this.new_id = -1
      }
      if (this.new_id !== -1) {
        this.$axios({
          url: 'api/news/detail/' + this.new_id,
          method: 'get'
        }).then(res => {
          /* console.log(this.new_id, res.data) */
          this.localResults = null
          if (res.data) {
            this.localResults = res.data
            // console.log(this.localResults)
            if (this.localResults.news) {
              if (this.localResults.news.title) {
                this.$global.onUpdateHeadTitle(this.localResults.news.title + '-广州帕克西')
                this.headMetaInfo.title = this.localResults.news.title + '-广州帕克西'
              }
              const headKeywords = ['新闻动态', '新闻详情']
              this.$global.onUpdateHeadKeywords(headKeywords)
              this.headMetaInfo.keywords = this.$global.getHeadKeywords(headKeywords)
              if (this.localResults.news.title && this.localResults.news.description) {
                this.$global.onUpdateHeadDescription(this.localResults.news.title + '；' + this.localResults.news.description)
                this.headMetaInfo.content = this.localResults.news.title + '；' + this.localResults.news.description
              }
            }
          }
        })
      } else {
        console.log('跳转首页')
        if (window.location.href.indexOf('localhost') === -1) { // 要加上这句判断，否则打包有问题，缺少按照路由文件夹打包
          this.$router.push({ path: '/' })
        } else {
          this.localResults = null
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}
img {
  vertical-align: top;
  max-width: 100%;
}
.NewsDetails {
  width: 100%;
  overflow: hidden;
  text-align: center;
  color: #333;
  .news-details-content-pc {
    padding-top: 70px;
    width: var(--v-width);
    margin: 0 auto;
    border-bottom: 1px solid #b9b9b9;
    position: relative;
    .news-details-content-title {
      padding: 50px 0px 30px 0px;
      font-size: 24px;
      font-weight: bold;
      text-align: center;
    }
    .news-details-content-time {
      font-size: 14px;
      text-align: center;
      padding-bottom: 10px;
      font-weight: bold;
    }
    .news-details-content-html {
      width: calc(100% - 50px);
      padding: 50px 25px;
      text-align: left;
      line-height: 25px;
    }
    // https://www.cnblogs.com/younghxp/p/13448406.html
    // https://www.cnblogs.com/gqx-html/p/11812276.html
    // https://www.love85g.com/?p=1879
    /deep/ .news-details-content-html{
      img{
        margin: 10px auto;
      }
    }
    .news-details-content-share {
      width: 100%;
      overflow: hidden;
      ul {
        .news-details-content-share-title {
          float: left;
          height: 31px;
          padding: 0 20px;
          line-height: 31px;
          text-align: center;
          font-weight: bold;
          font-size: 14px;
        }
        li {
          float: left;
          margin: 0 10px;
          height: 30px;
          img {
            height: 100%;
            cursor: pointer;
          }
        }
      }
      .qrcode {
        width: 150px;
        height: 150px;
        position: absolute;
        left: 65px;
        bottom: 40px;
        background-color: #eee;
        padding: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .news-details-content-mobile {
    width: calc(var(--v-width) - 20px);
    margin: 0 auto;
    border-bottom: 1px solid #b9b9b9;
    position: relative;
    .news-details-content-title {
      padding: 20px 0px 10px 0px;
      font-size: var(--v-font-size-24);
      font-weight: bold;
      text-align: center;
    }
    .news-details-content-time {
      font-size: var(--v-font-size-14);
      text-align: center;
      padding-bottom: 5px;
      //font-weight: bold;
    }
    .news-details-content-html {
      width: calc(100% - 20px);
      //padding: 50px 25px;
      padding-left: 10px;
      padding-bottom: 20px;
      text-align: left;
    }
    // https://www.cnblogs.com/younghxp/p/13448406.html
    // https://www.cnblogs.com/gqx-html/p/11812276.html
    // https://www.love85g.com/?p=1879
    /deep/ .news-details-content-html{
      p {
        line-height: 72% !important;
      }
      img{
        margin: 10px auto;
      }
    }
    .news-details-content-share {
      width: 100%;
      overflow: hidden;
      ul {
        .news-details-content-share-title {
          float: left;
          height: 30px;
          padding: 0 20px;
          line-height: 24px;
          text-align: center;
          font-weight: bold;
          font-size: var(--v-font-size-14);
        }
        li {
          float: left;
          margin: 0 8px;
          height: 24px;
          img {
            height: 100%;
          }
        }
      }
      .qrcode {
        width: 158px;
        height: 168px;
        position: absolute;
        left: 65px;
        bottom: 40px;
        background-color: #eee;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .news-details-related-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .news-details-related-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 80px 0px;
      .news-details-related-content-title {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
      }
      .news-details-related-content-list {
        width: 100%;
        overflow: hidden;
        margin: 50px auto 0px auto;
        text-align: center;
        ul {
          overflow: hidden;
          display: inline-block;
          li {
            width: 373px;
            height: 300px;
            float: left;
            cursor: pointer;
            background-color: #fff;
            .news-details-related-content-image {
              width: 100%;
              height: 216px;
              img {
                height: 100%;
              }
            }
            .news-details-related-content-title {
              font-size: 16px;
              font-weight: bold;
              text-align: center;
              margin: 20px 0px 10px 0px;
            }
          }
        }
      }
    }
  }
  .news-details-related-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .news-details-related-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 80px 0px;
      .news-details-related-content-title {
        font-size: 20px;
        font-weight: 700;
        text-align: center;
      }
      .news-details-related-content-list {
        width: 100%;
        overflow: hidden;
        margin: 50px auto 0px auto;
        text-align: center;
        ul {
          overflow: hidden;
          display: inline-block;
          li {
            width: 100%;
            //width: 373px;
            //height: 300px;
            float: left;
            cursor: pointer;
            background-color: #fff;
            .news-details-related-content-image {
              width: 100%;
              height: 216px;
              img {
                height: 100%;
              }
            }
            .news-details-related-content-title {
              font-size: 16px;
              font-weight: bold;
              text-align: center;
              margin: 20px 0px 10px 0px;
            }
          }
        }
      }
    }
  }
  iframe {
    width: 100%;
    border: none;
  }
}
</style>
