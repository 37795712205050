<template>
  <div class="Product">
    <TopControl />
    <div v-if="localResults">
      <!-- 导航栏 -->
      <div id="product-nav" :class="$global.isMobile ? 'product-nav-mobile' : 'product-nav-pc'">
        <div class="product-nav-content">
          <div class="product-nav-list">
            <ul>
              <li v-for="(item, index) in productnavResults" :key="index">
                <div :class="{'isActive' : index === productnavIndex}" @click.stop="onProductNavClick(index, item.id)">
                  <div class="product-nav-list-title">{{ item.text }}</div>
                  <div
                    v-if="index == productnavIndex"
                    class="product-nav-list-line"
                  ></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 介绍 -->
      <div id="scrollInto_introduction"></div>
      <div id="introduction" :class="$global.isMobile ? 'product-introduction-mobile' : 'product-introduction-pc'">
        <div class="product-introduction-content">
          <div class="product-introduction-content-left">
            <div
              class="product-introduction-content-left-placeholder"
              @click.stop="onVideoPlayClick()"
            ></div>
            <video
              id="product-introduction-content-left-video"
              :width="videoSize.width"
              :height="videoSize.height"
              :src="localResults['thumb']"
              controls="controls"
              controlslist="nodownload noremoteplayback"
            ></video>
            <div
              class="product-introduction-content-left-play"
              @click.stop="onVideoPlayClick()"
            ></div>
          </div>
          <div class="product-introduction-content-right">
            <div class="product-introduction-content-right-content">
              <div class="product-introduction-content-right-content-name">
                {{ localResults["name"] }}
              </div>
              <div class="product-introduction-content-right-content-title">
                {{ localResults["title"] }}
              </div>
              <span
                class="product-introduction-content-right-content-line"
              ></span>
            </div>
            <div class="product-introduction-content-right-text">
              {{ localResults["brief"] }}
            </div>
          </div>
        </div>
      </div>
      <!-- 核心优势 -->
      <div id="scrollInto_superiorities"></div>
      <div id="superiorities"
        :class="$global.isMobile ? 'product-superiorities-mobile' : 'product-superiorities-pc'"
        v-if="localResults['superiorities'] && localResults['superiorities'].length > 0"
      >
        <div class="product-superiorities-bg">
          <!-- <img src="../../assets/images/ProductCenter/bg.png" /> -->
          <img :src="localResults['bgimg']" />
          <!-- <div id="solution-maketingsOffline-content-list-bg" class="solution-maketingsOffline-content-list-thumb">
            <img src="../../assets/images/Solution/bg.png" />
          </div> -->
        </div>
        <div class="product-superiorities-bg">
          <img src="../../assets/images/Common/mask.png" />
        </div>
        <div class="product-superiorities-content">
          <div class="product-superiorities-content-title">核心优势</div>
          <div class="product-superiorities-content-list">
            <div class="product-superiorities-content-list-left">
              <div
                class="product-superiorities-content-list-left-content"
                v-if="localResults['superiorities'].length >= 1"
              >
                <!-- '#fff' : '#6F6D6A' -->
                <div
                  :style="
                    'color:' + (superioritiesIndex == 0 ? '#fff' : '#B4B4B4')
                  "
                >
                  <div class="product-superiorities-content-list-left-name">
                    {{ localResults["superiorities"][0].name }}
                  </div>
                  <div class="product-superiorities-content-list-left-brief">
                    {{ localResults["superiorities"][0].brief }}
                  </div>
                </div>
              </div>
              <div
                class="product-superiorities-content-list-left-content"
                v-if="localResults['superiorities'].length >= 2"
              >
                <!-- '#fff' : '#6F6D6A' -->
                <div
                  :style="
                    'color:' + (superioritiesIndex == 1 ? '#fff' : '#B4B4B4')
                  "
                >
                  <div class="product-superiorities-content-list-left-name">
                    {{ localResults["superiorities"][1].name }}
                  </div>
                  <div class="product-superiorities-content-list-left-brief">
                    {{ localResults["superiorities"][1].brief }}
                  </div>
                </div>
              </div>
            </div>
            <div class="product-superiorities-content-list-center">
              <div class="product-superiorities-content-list-center-bg">
                <img src="../../assets/images/ProductCenter/tuoyuan.png" />
              </div>
              <div
                class="product-superiorities-content-list-center-upper-left-corner"
                v-if="localResults['superiorities'].length >= 1"
                @mouseenter="onMouseenterSuperiorities(0)"
              >
                <!-- ../../assets/images/ProductCenter/ioc1.png -->
                <!-- localResults['superiorities'][0] -->
                <img
                  :src="
                    superioritiesIndex == 0
                      ? localResults['superiorities'][0].bright
                      : localResults['superiorities'][0].dark
                  "
                />
              </div>
              <div
                class="product-superiorities-content-list-center-lower-left-corner"
                v-if="localResults['superiorities'].length >= 2"
                @mouseenter="onMouseenterSuperiorities(1)"
              >
                <!-- ../../assets/images/ProductCenter/ioc3.png -->
                <!-- localResults['superiorities'][1] -->
                <img
                  :src="
                    superioritiesIndex == 1
                      ? localResults['superiorities'][1].bright
                      : localResults['superiorities'][1].dark
                  "
                />
              </div>
              <div
                class="product-superiorities-content-list-center-upper-right-corner"
                v-if="localResults['superiorities'].length >= 3"
                @mouseenter="onMouseenterSuperiorities(2)"
              >
                <!-- ../../assets/images/ProductCenter/ioc2.png -->
                <!-- localResults['superiorities'][2] -->
                <img
                  :src="
                    superioritiesIndex == 2
                      ? localResults['superiorities'][2].bright
                      : localResults['superiorities'][2].dark
                  "
                />
              </div>
              <div
                class="product-superiorities-content-list-center-lower-right-corner"
                v-if="localResults['superiorities'].length >= 4"
                @mouseenter="onMouseenterSuperiorities(3)"
              >
                <!-- ../../assets/images/ProductCenter/ioc4.png -->
                <!-- localResults['superiorities'][3] -->
                <img
                  :src="
                    superioritiesIndex == 3
                      ? localResults['superiorities'][3].bright
                      : localResults['superiorities'][3].dark
                  "
                />
              </div>
              <div class="product-superiorities-content-list-center-img">
                <img
                  :src="
                    localResults['superiorities'][superioritiesIndex]['thumb']
                  "
                />
              </div>
            </div>
            <div class="product-superiorities-content-list-right">
              <div
                class="product-superiorities-content-list-right-content"
                v-if="localResults['superiorities'].length >= 3"
              >
                <!-- '#fff' : '#6F6D6A' -->
                <div
                  :style="
                    'color:' + (superioritiesIndex == 2 ? '#fff' : '#B4B4B4')
                  "
                >
                  <div class="product-superiorities-content-list-right-name">
                    {{ localResults["superiorities"][2].name }}
                  </div>
                  <div class="product-superiorities-content-list-right-brief">
                    {{ localResults["superiorities"][2].brief }}
                  </div>
                </div>
              </div>
              <div
                class="product-superiorities-content-list-right-content"
                v-if="localResults['superiorities'].length >= 4"
              >
                <!-- '#fff' : '#6F6D6A' -->
                <div
                  :style="
                    'color:' + (superioritiesIndex == 3 ? '#fff' : '#B4B4B4')
                  "
                >
                  <div class="product-superiorities-content-list-right-name">
                    {{ localResults["superiorities"][3].name }}
                  </div>
                  <div class="product-superiorities-content-list-right-brief">
                    {{ localResults["superiorities"][3].brief }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品推荐 -->
      <div id="scrollInto_recommends"></div>
      <div id="recommends"
        :class="$global.isMobile ? 'product-recommends-mobile' : 'product-recommends-pc'"
        v-if="localResults['recommends'] && localResults['recommends'].length > 0"
      >
        <div class="product-recommends-content">
          <div class="product-recommends-content-title">产品推荐</div>
          <div class="product-recommends-content-subtitle">
            丰富的产品形态，满足多场景需求
          </div>
          <div
            class="product-recommends-content-list"
            v-if="localResults['recommends'].length > recommendIndex"
          >
            <div class="product-recommends-content-list-left">
              <img :src="localResults['recommends'][recommendIndex]['recommend_thumb']" />
            </div>
            <div class="product-recommends-content-list-right">
              <div class="product-recommends-content-list-right-name">
                <span>
                  {{ localResults["recommends"][recommendIndex]["name"] }}
                </span>
                <span class="product-recommends-content-list-right-line"></span>
              </div>
              <div class="product-recommends-content-list-right-brief">
                {{ localResults["recommends"][recommendIndex]["description"] }}
              </div>
            </div>
            <div v-if="localResults['recommends'].length > 1">
              <div
                class="product-recommends-content-list-prev"
                @click.stop="onProductRecommendClick('-', true)"
              >
                <img src="../../assets/images/ProductCenter/left.png" />
              </div>
              <div
                class="product-recommends-content-list-next"
                @click.stop="onProductRecommendClick('+', true)"
              >
                <img src="../../assets/images/ProductCenter/right.png" />
              </div>
              <div class="product-recommends-content-list-subline">
                <ul>
                  <span
                    v-for="(item, index) in localResults['recommends']"
                    :key="index"
                  >
                    <li
                      v-if="index < 4"
                      @click.stop="onProductRecommendClick(index, true)"
                    >
                      <div
                        v-if="recommendIndex != index"
                        style="
                          background-color: #666666;
                          width: 100%;
                          height: 100%;
                        "
                      ></div>
                      <div
                        v-else
                        style="
                          background-color: #474747;
                          width: 100%;
                          height: 100%;
                        "
                      ></div>
                    </li>
                  </span>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div v-if="productCount > 4" class="product-recommends-more" @click.stop="onProductMoreClick">浏览更多产品</div>
        <div v-else :style="$global.isMobile ? 'height: 20px' : 'height: 50px'"></div>
      </div>
      <!-- 软件服务 -->
      <div id="scrollInto_services"></div>
      <div id="services"
        :class="$global.isMobile ? 'product-services-mobile' : 'product-services-pc'"
        v-if="localResults['services'] && localResults['services'].length > 0"
      >
        <div class="product-services-content">
          <div class="product-services-content-title">软件服务</div>
          <div class="product-services-content-list">
            <ul>
              <li v-for="(item, index) in localResults['services']" :key="index">
                <div :style="((localResults['services'].length > 1) && (index < localResults['services'].length - 1)) ? 'border-right: 1px solid #DCDCDC' : ''">
                  <div class="product-services-content-list-thumb">
                    <img :src="item.thumb" />
                  </div>
                  <div class="product-services-content-list-name">
                    {{ item.name }}
                  </div>
                  <div class="product-services-content-list-brief">
                    {{ item.brief }}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 应用场景 -->
      <div id="scrollInto_applications"></div>
      <div id="applications"
        :class="$global.isMobile ? 'product-applications-mobile' : 'product-applications-pc'"
        v-if=" localResults['applications'] && localResults['applications'].length > 0"
      >
        <div class="product-applications-content">
          <div class="product-applications-content-title">应用场景</div>
          <div class="product-applications-content-list">
            <ul>
              <li
                v-for="(item, index) in localResults['applications']"
                :key="index"
              >
                <div class="product-applications-content-list-thumb">
                  <img :src="item.thumb" />
                </div>
                <div class="product-applications-content-list-name">
                  {{ item.name }}
                </div>
                <div class="product-applications-content-list-brief">
                  {{ item.brief }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 品牌合作 -->
      <div id="scrollInto_cooperations"></div>
      <div id="cooperations"
        :class="$global.isMobile ? 'product-partners-mobile' : 'product-partners-pc'"
        v-if=" localResults['cooperations'] && localResults['cooperations'].length > 0"
      >
        <div class="product-partners-title">品牌合作</div>
        <div class="product-partners-list">
          <div class="product-partners-list-bg">
            <img src="../../assets/images/ProductCenter/bg1.png" />
          </div>
          <ul>
            <span v-for="(item, index) in localResults.cooperations" :key="index" :style="$global.isMobile ? '' : 'display: inline-block'">
              <li>
                <img :src="item.logo" :title="item.name" />
              </li>
            </span>
          </ul>
        </div>
      </div>
    </div>
    <div v-else>
      <NullPage />
    </div>
  </div>
</template>

<script>
import TopControl from '@/components/TopControl'
import NullPage from '@/components/NullPage'
export default {
  /* 产品中心 */
  name: 'Product',
  components: {
    TopControl,
    NullPage
  },
  metaInfo () {
    return {
      title: this.headMetaInfo.title,
      meta: [
        { name: 'keywords', content: this.headMetaInfo.keywords },
        { name: 'discription', content: this.headMetaInfo.content }
      ]
    }
  },
  data () {
    return {
      headMetaInfo: {
        title: '广州帕克西软件开发有限公司',
        keywords: '',
        content: ''
      },
      onScrollHeight: 0,
      nav_id: -1,
      productCount: 0,
      localResults: null,
      videoSize: {
        width: this.$global.isMobile ? document.documentElement.style.getPropertyValue('--v-width') : '100%',
        height: this.$global.isMobile ? '' : 342
      },
      productnavIndex: 0,
      productnavResults: [],
      productIntroductionIndex: 0,
      superioritiesIndex: 0,
      recommendIndex: 0,
      productServiceIndex: 0,
      productApplicationIndex: 0,
      recommendInterval: null
    }
  },
  watch: {
    $route () {
      this.reqlocalResults()
    }
  },
  created () { },
  mounted () {
    this.headMetaInfo.title = this.$global.head_title
    this.headMetaInfo.keywords = this.$global.head_keywords
    this.headMetaInfo.content = this.$global.head_description
    this.reqlocalResults()
    window.addEventListener('scroll', this.onScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll () {
      this.onScrollHeight = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const productNav = document.getElementById('product-nav')
      if (this.onScrollHeight >= 500 || (this.$global.isMobile && this.onScrollHeight > 300)) {
        productNav.style.position = 'fixed'
        // productNav.style.top = '20px'
        if (this.$global.isMobile) {
          productNav.style.top = '50px'
        } else {
          productNav.style.top = '20px'
        }
        productNav.style.zIndex = '999'
      } else {
        productNav.style.position = 'static'
      }
    },
    reqlocalResults: function () {
      if (this.recommendInterval) {
        clearInterval(this.recommendInterval)
      }
      /* 'api/product-center/{nav_id}' */
      /* {nav_id}:45-46-47-48-49 */
      if (this.$route.params.id) {
        this.nav_id = this.$route.params.id
      } else if (this.$route.query.id) {
        this.nav_id = this.$route.query.id
      } else {
        this.nav_id = -1
      }
      if (this.nav_id !== -1) {
        this.$axios({
          url: 'api/product-center/' + this.nav_id,
          method: 'get'
        }).then((res) => {
          /* console.log(this.nav_id, res.data) */
          this.productCount = 0
          this.localResults = null
          this.productnavIndex = 0
          this.productnavResults = []
          this.productIntroductionIndex = 0
          this.superioritiesIndex = 0
          this.recommendIndex = 0
          this.productServiceIndex = 0
          this.productApplicationIndex = 0
          if (res.data) {
            if (res.data.result && res.data.result.length > 0) {
              this.productCount = res.data.product_count
              this.localResults = res.data.result[0]
              // console.log(this.localResults)
              if (this.localResults) {
                this.recommendInterval = setInterval(this.onRecommendInterval, 5000)
                this.productnavResults.push({ id: 'introduction', text: '产品简介' })
                if (
                  Object.prototype.hasOwnProperty.call(
                    this.localResults,
                    'superiorities'
                  ) && this.localResults.superiorities && this.localResults.superiorities.length > 0
                ) {
                  this.productnavResults.push({ id: 'superiorities', text: '核心优势' })
                }
                if (
                  Object.prototype.hasOwnProperty.call(
                    this.localResults,
                    'recommends'
                  ) && this.localResults.recommends && this.localResults.recommends.length > 0
                ) {
                  this.productnavResults.push({ id: 'recommends', text: '产品推荐' })
                }
                if (
                  Object.prototype.hasOwnProperty.call(
                    this.localResults,
                    'services'
                  ) && this.localResults.services && this.localResults.services.length > 0
                ) {
                  this.productnavResults.push({ id: 'services', text: '软件服务' })
                }
                if (
                  Object.prototype.hasOwnProperty.call(
                    this.localResults,
                    'applications'
                  ) && this.localResults.applications && this.localResults.applications.length > 0
                ) {
                  this.productnavResults.push({ id: 'applications', text: '应用场景' })
                }
                if (
                  Object.prototype.hasOwnProperty.call(
                    this.localResults,
                    'cooperations'
                  ) && this.localResults.cooperations && this.localResults.cooperations.length > 0
                ) {
                  this.productnavResults.push({ id: 'cooperations', text: '品牌合作' })
                }
                this.$global.onUpdateHeadTitle(this.localResults.name + '-' + this.localResults.title + '-广州帕克西软件开发有限公司')
                this.headMetaInfo.title = this.localResults.name + '-' + this.localResults.title + '-广州帕克西软件开发有限公司'
                var headKeywords = [this.localResults.name, this.localResults.title.replace('智慧', '').replace('新零售', '').replace('解决方案', '')]
                if (this.localResults.title.indexOf('新零售') !== -1) {
                  headKeywords.push('新零售')
                }
                if (this.localResults.title.indexOf('解决方案') !== -1) {
                  headKeywords.push('解决方案')
                }
                if (this.localResults.superiorities && this.localResults.superiorities.length > 0) {
                  for (var n = 0; n < this.localResults.superiorities.length; n++) {
                    if (this.localResults.superiorities[n] && this.localResults.superiorities[n].name) {
                      headKeywords.push(this.localResults.superiorities[n].name)
                    }
                  }
                }
                this.$global.onUpdateHeadKeywords(headKeywords)
                this.headMetaInfo.keywords = this.$global.getHeadKeywords(headKeywords)
                if (this.localResults.brief) {
                  const content = this.localResults.brief.trim().replace(/\s+|&nbsp;/ig, '')
                  this.$global.onUpdateHeadDescription(content)
                  this.headMetaInfo.content = content
                }
              }
              /* 设置Header的banner图 */
              if (this.localResults.banner) {
                this.$emit('commandEvent', 'header-banner', [
                  { thumb: this.localResults.banner }
                ])
              } else {
                this.$emit('commandEvent', 'header-banner', null)
              }
            } else {
              this.$emit('commandEvent', 'header-banner', null)
            }
          } else {
            this.$emit('commandEvent', 'header-banner', null)
          }
        })
      } else {
        console.log('跳转首页')
        if (window.location.href.indexOf('localhost') === -1) { // 要加上这句判断，否则打包有问题，缺少按照路由文件夹打包
          this.$router.push({ path: '/' })
        } else {
          this.productCount = 0
          this.localResults = null
          this.productnavIndex = 0
          this.productnavResults = null
          this.productIntroductionIndex = 0
          this.superioritiesIndex = 0
          this.recommendIndex = 0
          this.productServiceIndex = 0
          this.productApplicationIndex = 0
          this.$emit('commandEvent', 'header-banner', null)
        }
      }
    },
    onProductNavClick: function (index, id) {
      const oldproductnavIndex = this.productnavIndex
      this.productnavIndex = index
      try {
        // document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        // document.getElementById('scrollInto_' + id).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        const scrollintoid = document.getElementById('scrollInto_' + id)
        const y = scrollintoid.getBoundingClientRect().top + window.pageYOffset
        var yOffset = -60
        if (this.$global.isMobile) {
          if (oldproductnavIndex === 0 && this.productnavIndex === 0) {
            yOffset = -116
          } else if (oldproductnavIndex !== 0) {
            if (this.productnavIndex !== 0) {
              yOffset = -110
            } else {
              yOffset = -50
            }
          } else {
            yOffset = -170
          }
        } else {
          if (this.productnavIndex === 0) {
            yOffset = -20
          } else if (oldproductnavIndex !== 0) {
            yOffset = -120
          } else {
            yOffset = -230
          }
        }
        window.scrollTo({ top: y + yOffset, behavior: 'smooth' })
      } catch (e) {
        // TODO handle the exception
      }
    },
    onVideoPlayClick: function () {
      const video = document.querySelector('video')
      if (video.paused) {
        video.play()
      } else {
        video.pause()
      }
    },
    onMouseenterSuperiorities: function (index) {
      this.superioritiesIndex = index
    },
    onRecommendInterval: function () {
      this.onProductRecommendClick('+', false)
    },
    onProductRecommendClick: function (index, clickEvent) {
      if (clickEvent) {
        if (this.recommendInterval) {
          clearInterval(this.recommendInterval)
        }
        this.recommendInterval = setInterval(this.onRecommendInterval, 5000)
      }
      if (index === '-') {
        if (this.localResults.recommends.length > 1) {
          if (this.recommendIndex > 0) {
            this.recommendIndex -= 1
          } else {
            if (this.localResults.recommends.length <= 4) {
              this.recommendIndex = this.localResults.recommends.length - 1
            } else {
              this.recommendIndex = 3
            }
          }
        } else {
          this.recommendIndex = 0
        }
      } else if (index === '+') {
        if (this.localResults.recommends.length > 1) {
          if ((this.recommendIndex < this.localResults.recommends.length - 1) && (this.recommendIndex + 1 < 4)) {
            this.recommendIndex += 1
          } else {
            this.recommendIndex = 0
          }
        } else {
          this.recommendIndex = 0
        }
      } else {
        this.recommendIndex = index
      }
    },
    onProductMoreClick () {
      this.$router.push('/product-list')
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="less" scoped>
@padding:80px 0px;
@title: 32px;
@fontWeight: 700;
@superiorities_width: calc(330px - 60px);
@superiorities_height: 200px;
@superiorities_float: left;
@superiorities_padding: 0 30px;
ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}
img {
  vertical-align: top;
  max-width: 100%;
}
.Product {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center;
  color: #333;
  .product-nav-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 3px 6px 0px rgba(123, 123, 123, 0.16);
    margin-bottom: 6px;
    .product-nav-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 50px 0px 0px 0px;
      .product-nav-list {
        display: inline-block;
        margin: 0 auto;
        ul {
          li {
            float: left;
            margin: 0 20px;
            text-align: center;
            position: relative;
            cursor: pointer;
            .product-nav-list-title {
              font-weight: bold;
              width: 100px;
              height: 50px;
              line-height: 50px;
              font-size: 20px;
            }
            .product-nav-list-line {
              width: 43px;
              height: 4px;
              background: #ea6634;
              position: absolute;
              left: calc(50% - 21.5px);
            }
          }
        }
      }
    }
  }
  .product-nav-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 3px 6px 0px rgba(123, 123, 123, 0.16);
    margin-bottom: 6px;
    .product-nav-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 10px 0px 0px 0px;
      .product-nav-list {
        // display: inline-block;
        margin: 0 auto;
        overflow: auto;
        text-align:center;
        ul {
          overflow: auto;
          white-space:nowrap;
          width: auto;
          width: -moz-fit-content;
          width: -webkit-fit-content;
          width: fit-content;
          float: left;
          display: inline-block;
          margin-left: 8px;
          margin-right: 8px;
          li {
            // float: left;
            margin: 0px 6px;
            padding: 5px 0px;
            text-align: center;
            position: relative;
            display: inline-block;
            cursor: pointer;
            .product-nav-list-title {
              font-weight: bold;
              // width: 100px;
              height: 40px;
              line-height: 40px;
              font-size: var(--v-font-size-20);
            }
            .product-nav-list-line {
              width: 100%;
              height: 3px;
              background: #ea6634;
              position: absolute;
            }
          }
        }
      }
    }
  }
  .product-introduction-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .product-introduction-content {
      width: var(--v-width);
      overflow: hidden;
      margin: 0 auto;
      padding: @padding;
      .product-introduction-content-left {
        float: left;
        width: 606px;
        height: 342px;
        position: relative;
        cursor: pointer;
        .product-introduction-content-left-placeholder {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 19;
        }
        .product-introduction-content-left-play {
          z-index: 99;
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100px;
          height: 100px;
          margin: -50px 0 0 -50px;
          background: url(../../assets/images/ProductCenter/bigplay.svg)
            no-repeat;
        }
        .product-introduction-content-left-play:hover {
          background-position-y: -100px;
        }
      }
      .product-introduction-content-left:hover {
        .product-introduction-content-left-play {
          display: block;
        }
      }
      .product-introduction-content-right {
        float: left;
        width: calc(1160px - 646px);
        padding-left: 40px;
        .product-introduction-content-right-content {
          position: relative;
          margin-top: 20px;
          height: 76px;
          text-align: left;
          .product-introduction-content-right-content-name {
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .product-introduction-content-right-content-title {
            font-size: 20px;
            font-weight: bold;
          }
          .product-introduction-content-right-content-line {
            position: absolute;
            width: 52px;
            height: 6px;
            bottom: -10px;
            left: 0px;
            background-color: #ea6634;
          }
        }
        .product-introduction-content-right-text {
          font-size: 16px;
          font-weight: 500;
          text-align: left;
          color: #4d4d4d;
          line-height: 30px;
          margin-top: 50px;
        }
      }
    }
  }
  .product-introduction-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .product-introduction-content {
      width: var(--v-width);
      overflow: hidden;
      margin: 0 auto;
      padding: 0px 0px 20px 0px;
      .product-introduction-content-left {
        //float: left;
        //width: 606px;
        //height: 342px;
        width: 100%;
        position: relative;
        cursor: pointer;
        .product-introduction-content-left-placeholder {
          width: 100%;
          height: 100%;
          position: absolute;
          z-index: 19;
        }
        .product-introduction-content-left-play {
          z-index: 99;
          display: none;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100px;
          height: 100px;
          margin: -50px 0 0 -50px;
          background: url(../../assets/images/ProductCenter/bigplay.svg)
            no-repeat;
        }
        .product-introduction-content-left-play:hover {
          background-position-y: -100px;
        }
      }
      .product-introduction-content-left:hover {
        .product-introduction-content-left-play {
          //display: block;
          display: none;
        }
      }
      .product-introduction-content-right {
        float: left;
        width: calc(var(--v-width) - 30px);
        //padding-left: 40px;
        padding-left: 15px;
        .product-introduction-content-right-content {
          position: relative;
          margin-top: 10px;
          //height: 76px;
          text-align: left;
          .product-introduction-content-right-content-name {
            font-size: var(--v-font-size-32);
            font-weight: bold;
            margin-bottom: 10px;
          }
          .product-introduction-content-right-content-title {
            font-size: var(--v-font-size-20);
            font-weight: bold;
          }
          .product-introduction-content-right-content-line {
            position: absolute;
            width: 100px;
            height: 4px;
            bottom: -10px;
            left: 0px;
            background-color: #ea6634;
          }
        }
        .product-introduction-content-right-text {
          font-size: var(--v-font-size-16);
          font-weight: 500;
          text-align: left;
          color: #4d4d4d;
          line-height: 20px;
          margin-top: 20px;
        }
      }
    }
  }
  .product-superiorities-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    position: relative;
    height: 741px;
    .product-superiorities-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      min-width: 1200px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .product-superiorities-content {
      width: var(--v-width);
      overflow: hidden;
      margin: 0 auto;
      padding: @padding;
      position: relative;
      .product-superiorities-content-title {
        font-size: @title;
        font-weight: @fontWeight;
        width: var(--v-width);
        color: #fff;
      }
      .product-superiorities-content-list {
        width: 100%;
        height: 500px;
        overflow: hidden;
        margin-top: 50px;
        color: #6f6d6a;
        .product-superiorities-content-list-left {
          width: @superiorities_width;
          height: @superiorities_height;
          float: @superiorities_float;
          padding: @superiorities_padding;
          .product-superiorities-content-list-left-content {
            width: 260px;
            height: 300px;
            .product-superiorities-content-list-left-name {
              font-weight: bold;
              font-size: 22px;
              text-align: left;
            }
            .product-superiorities-content-list-left-brief {
              font-size: 16px;
              line-height: 25px;
              text-align: left;
              margin-top: 10px;
            }
          }
        }
        .product-superiorities-content-list-center {
          width: 486px;
          height: 486px;
          float: left;
          position: relative;
          .product-superiorities-content-list-center-bg {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            img {
              width: 100%;
            }
          }
          .product-superiorities-content-list-center-upper-left-corner {
            position: absolute;
            left: -10px;
            top: 80px;
            width: 94px;
            height: 94px;
            cursor: pointer;
            img {
              width: 100%;
            }
          }
          .product-superiorities-content-list-center-lower-left-corner {
            position: absolute;
            left: -10px;
            bottom: 80px;
            width: 94px;
            height: 94px;
            cursor: pointer;
            img {
              width: 100%;
            }
          }
          .product-superiorities-content-list-center-upper-right-corner {
            position: absolute;
            right: -10px;
            top: 80px;
            width: 94px;
            height: 94px;
            cursor: pointer;
            img {
              width: 100%;
            }
          }
          .product-superiorities-content-list-center-lower-right-corner {
            position: absolute;
            right: -10px;
            bottom: 80px;
            width: 94px;
            height: 94px;
            cursor: pointer;
            img {
              width: 100%;
            }
          }
          .product-superiorities-content-list-center-img {
            width: 302px;
            height: 302px;
            background-color: #ffffff;
            padding: 10px;
            border-radius: 200px;
            position: absolute;
            //left: calc(50% - 151px);
            //top: calc(50% - 151px);
            // 居中
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img {
              width: 100%;
            }
          }
        }
        .product-superiorities-content-list-right {
          width: @superiorities_width;
          height: @superiorities_height;
          float: @superiorities_float;
          padding: @superiorities_padding;
          margin-left: 20px;
          .product-superiorities-content-list-right-content {
            width: 260px;
            height: 300px;
            .product-superiorities-content-list-right-name {
              font-weight: bold;
              font-size: 22px;
              text-align: left;
            }
            .product-superiorities-content-list-right-brief {
              font-size: 16px;
              line-height: 25px;
              text-align: left;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  .product-superiorities-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    position: relative;
    // height: 741px;
    .product-superiorities-bg {
      position: absolute;
      height: 100%;
      //top: 0px;
      //left: 0px;
      min-width: 1200px;
      //居中
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 100%;
        height: 100%;
      }
    }
    .product-superiorities-content {
      width: var(--v-width);
      overflow: hidden;
      margin: 0 auto;
      padding: 30px 0px;
      position: relative;
      .product-superiorities-content-title {
        font-size: var(--v-font-size-32);
        font-weight: @fontWeight;
        width: var(--v-width);
        color: #fff;
      }
      .product-superiorities-content-list {
        width: var(--v-width);
        //height: 500px;
        //overflow: hidden;
        margin: 0px auto;
        text-align: center;
        color: #6f6d6a;
        .product-superiorities-content-list-left {
          //width: calc(var(--v-width) / 2 - 20px);
          //height: @superiorities_height;
          //float: left;
          position: absolute;
          padding: 0 10px;
          top: 170px;
          left: 10px;
          width: calc(var(--v-width) / 1.5);
          height: calc(var(--v-width) / 1.5);
          .product-superiorities-content-list-left-content {
            //width: 260px;
            //height: 300px;
            width: calc(var(--v-width) / 2 - 20px);
            //margin: 20px auto;
            position: absolute;
            float: left;
            left: 10px;
            .product-superiorities-content-list-left-name {
              font-weight: bold;
              font-size: var(--v-font-size-20);
              text-align: left;
            }
            .product-superiorities-content-list-left-brief {
              font-size: var(--v-font-size-14);
              line-height: 18px;
              text-align: left;
              margin-top: 5px;
            }
          }
          div:nth-child(1) {
            top: -95px;
          }
          div:nth-child(2) {
            bottom: -70px;
          }
        }
        .product-superiorities-content-list-center {
          //width: 486px;
          //height: 486px;
          width: calc(var(--v-width) / 1.5);
          height: calc(var(--v-width) / 1.5);
          //float: left;
          position: relative;
          margin: 110px auto 110px auto;
          text-align: center;
          z-index: 10;
          .product-superiorities-content-list-center-bg {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            img {
              width: 100%;
            }
          }
          .product-superiorities-content-list-center-upper-left-corner {
            position: absolute;
            left: 20px;
            top: 5px;
            width: 48px;
            height: 48px;
            cursor: pointer;
            img {
              width: 100%;
            }
          }
          .product-superiorities-content-list-center-lower-left-corner {
            position: absolute;
            left: 20px;
            bottom: 5px;
            width: 48px;
            height: 48px;
            cursor: pointer;
            img {
              width: 100%;
            }
          }
          .product-superiorities-content-list-center-upper-right-corner {
            position: absolute;
            right: 20px;
            top: 5px;
            width: 48px;
            height: 48px;
            cursor: pointer;
            img {
              width: 100%;
            }
          }
          .product-superiorities-content-list-center-lower-right-corner {
            position: absolute;
            right: 20px;
            bottom: 5px;
            width: 48px;
            height: 48px;
            cursor: pointer;
            img {
              width: 100%;
            }
          }
          .product-superiorities-content-list-center-img {
            //width: 302px;
            //height: 302px;
            width: 72%;
            height: 72%;
            background-color: #ffffff;
            padding: 5px;
            border-radius: 100px;
            position: absolute;
            //居中
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img {
              width: 100%;
            }
          }
        }
        .product-superiorities-content-list-right {
          //width: calc(var(--v-width) / 2 - 20px);
          //height: @superiorities_height;
          //float: left;
          position: absolute;
          padding: 0 10px;
          top: 170px;
          right: 10px;
          width: calc(var(--v-width) / 1.5);
          height: calc(var(--v-width) / 1.5);
          .product-superiorities-content-list-right-content {
            //width: 260px;
            //height: 300px;
            width: calc(var(--v-width) / 2 - 20px);
            //margin: 20px auto;
            position: absolute;
            float: right;
            right: 10px;
            .product-superiorities-content-list-right-name {
              font-weight: bold;
              font-size: var(--v-font-size-20);
              text-align: right;
            }
            .product-superiorities-content-list-right-brief {
              font-size: var(--v-font-size-14);
              line-height: 18px;
              text-align: right;
              margin-top: 5px;
            }
          }
          div:nth-child(1) {
            top: -95px;
          }
          div:nth-child(2) {
            bottom: -70px;
          }
        }
      }
    }
  }
  .product-recommends-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .product-recommends-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: @padding;
      .product-recommends-content-title {
        font-size: @title;
        font-weight: @fontWeight;
        width: var(--v-width);
        padding-bottom: 20px;
      }
      .product-recommends-content-subtitle {
        font-size: 14px;
        text-align: center;
        font-weight: bold;
      }
      .product-recommends-content-list {
        width: 984px;
        height: 342px;
        margin: 60px auto 0px auto;
        position: relative;
        box-shadow: 0px 0px 6px 3px rgba(123, 123, 123, 0.16);
        .product-recommends-content-list-left {
          float: left;
          width: 458px;
          height: 342px;
          img {
            width: 100%;
          }
        }
        .product-recommends-content-list-right {
          float: left;
          width: calc(984px - 458px - 80px);
          height: 342px;
          padding-left: 50px;
          padding-right: 10px;
          .product-recommends-content-list-right-name {
            margin-top: 60px;
            height: 36px;
            font-size: 30px;
            font-weight: bold;
            text-align: left;
            position: relative;
            .product-recommends-content-list-right-line {
              position: absolute;
              width: 52px;
              height: 3px;
              bottom: -10px;
              left: 0px;
              background-color: #ea6634;
            }
          }
          .product-recommends-content-list-right-brief {
            font-size: 16px;
            font-weight: 500;
            text-align: left;
            color: #4d4d4d;
            line-height: 40px;
            margin-top: 50px;
            position: relative;
          }
        }
        .product-recommends-content-list-prev {
          position: absolute;
          width: 65px;
          height: 65px;
          left: -100px;
          top: calc(50% - 32.5px);
          cursor: pointer;
        }
        .product-recommends-content-list-next {
          position: absolute;
          width: 65px;
          height: 65px;
          right: -100px;
          top: calc(50% - 32.5px);
          cursor: pointer;
        }
        .product-recommends-content-list-subline {
          text-align: center;
          overflow: hidden;
          height: 80px;
          width: 100%;
          padding-top: 30px;
          ul {
            display: inline-block;
            li {
              width: 41px;
              height: 8px;
              float: left;
              margin: 0 5px;
            }
          }
        }
      }
    }
    .product-recommends-more {
      width: 173px;
      height: 46px;
      background: #ea6634;
      text-align: center;
      line-height: 46px;
      font-size: 16px;
      color: #fff;
      margin: 0 auto 100px auto;
      cursor: pointer;
    }
  }
  .product-recommends-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .product-recommends-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 30px 0px;
      .product-recommends-content-title {
        font-size: var(--v-font-size-32);
        font-weight: @fontWeight;
        width: var(--v-width);
        padding-bottom: 10px;
      }
      .product-recommends-content-subtitle {
        font-size: var(--v-font-size-14);
        text-align: center;
        font-weight: bold;
      }
      .product-recommends-content-list {
        width: calc(100% - 100px);
        //height: 342px;
        margin: 20px auto 0px auto;
        position: relative;
        box-shadow: 0px 0px 6px 3px rgba(123, 123, 123, 0.16);
        .product-recommends-content-list-left {
          //float: left;
          width: 100%;
          //height: 342px;
          img {
            width: 100%;
          }
        }
        .product-recommends-content-list-right {
          //float: left;
          width: calc(100% - 20px);
          //height: 342px;
          padding-left: 10px;
          padding-right: 10px;
          .product-recommends-content-list-right-name {
            margin-top: 10px;
            height: 24px;
            font-size: var(--v-font-size-27);
            font-weight: bold;
            text-align: left;
            position: relative;
            .product-recommends-content-list-right-line {
              position: absolute;
              width: 52px;
              height: 2px;
              bottom: -5px;
              left: 0px;
              background-color: #ea6634;
            }
          }
          .product-recommends-content-list-right-brief {
            font-size: var(--v-font-size-16);
            font-weight: 500;
            text-align: left;
            color: #4d4d4d;
            line-height: 20px;
            margin-top: 10px;
            position: relative;
          }
        }
        .product-recommends-content-list-prev {
          position: absolute;
          width: 36px;
          height: 36px;
          left: -45px;
          top: calc(50% - 18px);
          cursor: pointer;
        }
        .product-recommends-content-list-next {
          position: absolute;
          width: 36px;
          height: 36px;
          right: -45px;
          top: calc(50% - 18px);
          cursor: pointer;
        }
        .product-recommends-content-list-subline {
          text-align: center;
          overflow: hidden;
          //height: 40px;
          width: 100%;
          padding-bottom: 10px;
          ul {
            display: inline-block;
            li {
              width: 32px;
              height: 5px;
              float: left;
              margin: 0 5px;
            }
          }
        }
      }
    }
    .product-recommends-more {
      width: 120px;
      height: 36px;
      background: #ea6634;
      text-align: center;
      line-height: 36px;
      font-size: var(--v-font-size-16);
      color: #fff;
      margin: 0 auto 30px auto;
      cursor: pointer;
    }
  }
  .product-services-pc {
    width: 100%;
    overflow: hidden;
    background-color: #f6f6f6;
    .product-services-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 60px 0;
      .product-services-content-title {
        font-size: @title;
        font-weight: @fontWeight;
      }
      .product-services-content-list {
        width: 100%;
        overflow: hidden;
        text-align: center;
        padding: 30px 0;
        ul {
          overflow: hidden;
          display: flex;
          li {
            flex: 1;
            width: 300px;
            height: 214px;
            .product-services-content-list-thumb {
              width: 84px;
              height: 84px;
              text-align: center;
              margin: 0 auto;
              img {
                width: 100%;
              }
            }
            .product-services-content-list-name {
              font-size: 20px;
              font-weight: bold;
              text-align: center;
              margin: 20px 0px;
              color: #333333;
            }
            .product-services-content-list-brief {
              width: 211px;
              height: 50px;
              margin: 0 auto;
              font-size: 14px;
              line-height: 50px;
              text-align: left;
              font-weight: 500;
              color: #666666;
            }
          }
        }
      }
    }
  }
  .product-services-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #f6f6f6;
    .product-services-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 30px 0;
      .product-services-content-title {
        font-size: var(--v-font-size-32);
        font-weight: @fontWeight;
      }
      .product-services-content-list {
        width: 100%;
        overflow: hidden;
        text-align: center;
        padding: 20px 0;
        ul {
          overflow: hidden;
          display: flex;
          li {
            flex: 1;
            max-width: 300px;
            width: calc(100%/4 - 3px);
            //height: 214px;
            .product-services-content-list-thumb {
              width: 48px;
              height: 48px;
              text-align: center;
              margin: 0 auto;
              img {
                width: 100%;
              }
            }
            .product-services-content-list-name {
              font-size: var(--v-font-size-16);
              font-weight: bold;
              text-align: center;
              margin: 5px 0px;
              color: #333333;
            }
            .product-services-content-list-brief {
              width: 80%;
              //height: 50px;
              margin: 0 auto;
              font-size: var(--v-font-size-14);
              line-height: 20px;
              text-align: left;
              font-weight: 500;
              color: #666666;
              // 自动换行
              word-wrap: break-word;
            }
          }
        }
      }
    }
  }
  .product-applications-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .product-applications-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 54px 0px;
      .product-applications-content-title {
        font-size: @title;
        font-weight: 700;
      }
      .product-applications-content-list {
        width: 100%;
        overflow: hidden;
        margin: 50px auto 0px auto;
        text-align: center;
        ul {
          overflow: hidden;
          display: inline-block;
          li {
            width: 373px;
            height: 300px;
            float: left;
            cursor: pointer;
            background-color: #fff;
            margin: 0 10px;
            .product-applications-content-list-thumb {
              width: 100%;
              height: 216px;
              padding: 0px 10px;
              overflow: hidden;
              img {
                width: 100%;
                transition: all 0.5s ease 0s;
              }
            }
            .product-applications-content-list-name {
              font-size: 16px;
              font-weight: bold;
              text-align: center;
              margin: 20px 0px 10px 0px;
            }
            .product-applications-content-list-brief {
              font-size: 14px;
              font-weight: 500;
              text-align: center;
              color: #666666;
            }
            &:hover {
              .product-applications-content-list-thumb img {
                transform: scale(1.1);
                -ms-transform: scale(1.1); /* IE 9 */
                -moz-transform: scale(1.1); /* Firefox */
                -webkit-transform: scale(1.1); /* Safari 和 Chrome */
                -o-transform: scale(1.1);
              }
            }
          }
        }
      }
    }
  }
  .product-applications-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .product-applications-content {
      width: calc(var(--v-width) - 20px);
      margin: 0 auto;
      overflow: hidden;
      padding: 30px 0px;
      .product-applications-content-title {
        font-size: var(--v-font-size-32);
        font-weight: 700;
      }
      .product-applications-content-list {
        width: 100%;
        overflow: hidden;
        margin: 20px auto 0px auto;
        text-align: center;
        ul {
          overflow: hidden;
          display: inline-block;
          li {
            width: 100%;
            //height: 300px;
            float: left;
            cursor: pointer;
            background-color: #fff;
            border: 1px dashed rgba(123, 123, 123, 0.2);
            margin: 2px auto;
            .product-applications-content-list-thumb {
              width: 100%;
              //height: 216px;
              //padding: 0px 10px;
              img {
                width: 100%;
              }
            }
            .product-applications-content-list-name {
              font-size: var(--v-font-size-16);
              font-weight: bold;
              text-align: center;
              margin: 10px 0px 10px 0px;
            }
            .product-applications-content-list-brief {
              font-size: var(--v-font-size-14);
              font-weight: 500;
              text-align: center;
              color: #666666;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
  .product-partners-pc {
    width: 100%;
    overflow: hidden;
    background-color: #f2f2f2;
    text-align: center;
    .product-partners-title {
      font-size: @title;
      font-weight: @fontWeight;
      text-align: center;
      padding: 56px 0px 20px 0px;
    }
    .product-partners-list {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      text-align: center;
      margin-bottom: 50px;
      .product-partners-list-bg {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
      }
      ul {
        margin-top: 30px;
        overflow: hidden;
        display: inline-block;
        li {
          float: left;
          width: 252px;
          height: 95px;
          background-color: #fff;
          margin: 10px 15px;
          img {
            width: 100%;
          }
        }
        /* li:nth-child(1) {
          margin-right: 50px;
        }
        li:nth-child(n + 2):nth-child(-n + 3) {
          margin-right: 50px;
        } */
      }
    }
    .product-partners-more {
      width: 123px;
      height: 36px;
      background: #ea6634;
      text-align: center;
      margin: 50px auto;
      color: #fff;
      line-height: 36px;
      font-size: 12px;
      cursor: pointer;
    }
  }
  .product-partners-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #f2f2f2;
    text-align: center;
    .product-partners-title {
      font-size: var(--v-font-size-32);
      font-weight: @fontWeight;
      text-align: center;
      padding: 30px 0px 0px 0px;
    }
    .product-partners-list {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      text-align: center;
      margin-bottom: 20px;
      .product-partners-list-bg {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 200%;
        height: 100%;
      }
      ul {
        margin: 20px auto 0px auto;
        overflow: hidden;
        display: inline-block;
        li {
          float: left;
          flex: 1;
          max-width: 252px;
          width: calc(100% / 2 - 20px);
          //height: 95px;
          background-color: #fff;
          margin: 5px 10px;
          img {
            width: 100%;
          }
        }
      }
    }
    .product-partners-more {
      width: 123px;
      height: 36px;
      background: #ea6634;
      text-align: center;
      margin: 50px auto;
      color: #fff;
      line-height: 36px;
      font-size: 12px;
      cursor: pointer;
    }
  }
}
</style>
