<template>
  <div class="Solution">
    <div v-if="localResults">
      <!-- 介绍 -->
      <div :class="$global.isMobile ? 'solution-superiorities-mobile' : 'solution-superiorities-pc'">
        <div class="solution-superiorities-content">
          <div class="solution-superiorities-content-title"> {{ localResults['name'] }} </div>
          <div class="solution-superiorities-content-subtitle"> {{ localResults['brief'] }} </div>
          <div class="solution-superiorities-content-list" v-if="localResults['superiorities'] && (localResults['superiorities'].length > 0)">
            <ul>
              <li v-for="(item, index) in localResults['superiorities']" :key="index">
                <div class="solution-superiorities-content-list-thumb"> <img :src="item.thumb" /> </div>
                <div class="solution-superiorities-content-list-name"> {{ item.name }} </div>
                <div class="solution-superiorities-content-list-brief"> {{ item.brief }} </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 线上 -->
      <div
        :class="$global.isMobile ? 'solution-maketingsOnline-mobile' : 'solution-maketingsOnline-pc'"
        v-if="localResults['online_marketing'] && (localResults['online_marketing'].length > 0)"
      >
        <div class="solution-maketingsOnline-content">
          <div class="solution-maketingsOnline-content-title"> {{ localResults['online_marketing'][0]['title'] }} </div>
          <div class="solution-maketingsOnline-content-subtitle"> {{ localResults['online_marketing'][0]['brief'] }} </div>
          <div v-if="localResults['online_marketing'][0]['online_platforms'] && (localResults['online_marketing'][0]['online_platforms'].length > 0)">
            <div class="solution-maketingsOnline-content-list">
              <ul>
                <li
                   v-for="(item, index) in localResults['online_marketing'][0]['online_platforms']"
                   :key="index"
                   @mouseenter="onlineMarketingMouseenter(index)">
                  <!-- ../../assets/images/Solution/marketing1.png -->
                  <!-- ../../assets/images/Solution/marketing2.png -->
                  <!-- ../../assets/images/Solution/marketing3.png -->
                  <div class="solution-maketingsOnline-content-list-thumb">
                    <div v-if="index == onlineMarketingIndex">
                      <img src="../../assets/images/Solution/marketing22.png" />
                    </div>
                    <div v-else>
                      <img src="../../assets/images/Solution/marketing1.png" />
                    </div>
                    <div class="solution-maketingsOnline-content-list-name" :style="index == onlineMarketingIndex ? 'color:#fff' : 'color:#666'"> {{ item.name }} </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="solution-maketingsOnline-content-serve">
              <div class="solution-maketingsOnline-content-serve-left">
                 <img :src="localResults['online_marketing'][0]['online_platforms'][onlineMarketingIndex]['thumb']" />
              </div>
              <div class="solution-maketingsOnline-content-serve-right">
                <div class="solution-maketingsOnline-content-serve-title">
                    {{ localResults['online_marketing'][0]['online_platforms'][onlineMarketingIndex]['name'] }}
                </div>
                <div class="solution-maketingsOnline-content-serve-list"
                   v-if="localResults['online_marketing'][0]['online_platforms'][onlineMarketingIndex]['texts']">
                  <ul>
                    <li
                       v-for="(item, index) in localResults['online_marketing'][0]['online_platforms'][onlineMarketingIndex]['texts']"
                       :key="index">
                      <div class="solution-maketingsOnline-content-serve-list-circle"></div>
                      <div class="solution-maketingsOnline-content-serve-list-text"> {{ item['description'] }} </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 线下 -->
      <div
        :class="$global.isMobile ? 'solution-maketingsOffline-mobile' : 'solution-maketingsOffline-pc'"
        v-if="localResults['offline_marketing'] && (localResults['offline_marketing'].length > 0)"
      >
        <div class="solution-maketingsOffline-content">
          <div class="solution-maketingsOffline-content-title"> {{ localResults['offline_marketing'][0]['title'] }} </div>
          <div class="solution-maketingsOffline-content-subtitle"> {{ localResults['offline_marketing'][0]['brief'] }} </div>
          <div class="solution-maketingsOffline-content-list"
            v-if="localResults['offline_marketing'][0]['maketings'] && (localResults['offline_marketing'][0]['maketings'].length > 0)">
            <ul>
              <li
                :class="'solution-maketingsOffline-content-list-li ' + (offlineMarketingIndex === index ? 'solution-maketingsOffline-content-list-li-mouseover' : 'solution-maketingsOffline-content-list-li-mouseout')"
                v-for="(item, index) in localResults['offline_marketing'][0]['maketings']"
                :key="index"
                @mouseenter="offlineMarketingMouseenter(index)"
              >
                <div class="solution-maketingsOffline-content-list-thumb"> <img :src="item.thumb" /> </div>
                <div id="solution-maketingsOffline-content-list-bg" class="solution-maketingsOffline-content-list-bg" v-if="offlineMarketingIndex !== index">
                  <img src="../../assets/images/Common/mask.png" />
                </div>
                <div class="solution-maketingsOffline-content-list-namebrief" v-if="offlineMarketingIndex !== index">
                  <div class="solution-maketingsOffline-content-list-name"> {{ item.name }} </div>
                  <div class="solution-maketingsOffline-content-list-brief"> {{ item.brief }} </div>
                </div>
              </li>
              <li v-if="$global.isMobile" style="opacity: 0;">占位符|占位符|占位符|占位符</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 相关产品 -->
      <div
        :class="$global.isMobile ? 'solution-related_products-mobile' : 'solution-related_products-pc'"
        v-if="localResults['related_products'] && (localResults['related_products'].length > 0)"
      >
        <div class="solution-related_products-content">
          <div class="solution-related_products-content-title">相关产品</div>
          <div class="solution-related_products-content-list">
            <ul>
              <div class="solution-related_products-content-list-data" v-for="(item, index) in localResults['related_products']" :key="index">
                <li class="relatedItem">
                  <div class="solution-related_products-content-list-logoname">
                    <div class="solution-related_products-content-list-logo"> <img :src="item.logo" /> </div>
                    <div class="solution-related_products-content-list-name"> {{ item.name }} </div>
                  </div>
                  <div class="cli"></div>
                  <div class="solution-related_products-content-list-brief"> {{ item.brief }} </div>
                  <div class="solution-related_products-content-list-goto">
                    <div class="solution-related_products-content-list-do" v-if="item.qr_code" @mouseenter.stop="relatedIndex = index" @mouseover.stop="relatedIndex = -1">立即体验</div>
                    <div class="solution-related_products-content-list-do" v-else-if="item.url" @click.stop="relatedDetailClick(item)"> 了解详情 </div>
                  </div>
                </li>
                <div v-if="item.qr_code && relatedIndex == index" class="solution-related_products-content-qr_code" @mouseleave.stop="relatedIndex = -1">
                  <img class="solution-related_products-content-qr_code-img" :src="item.qr_code" />
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
      <!-- 多样化定制方案 -->
      <div
        :class="$global.isMobile ? 'solution-customize-mobile' : 'solution-customize-pc'"
        v-if="localResults['customize'] && localResults['customize'].length > 0"
      >
        <div class="solution-customize-content">
          <div class="solution-customize-content-title">多样化定制方案</div>
          <div class="solution-customize-content-list">
            <ul>
              <li
                v-for="(item, index) in localResults['customize']"
                :key="index"
              >
                <div class="solution-customize-content-list-thumb">
                  <img :src="item.thumb" />
                </div>
                <div class="solution-customize-content-list-title">
                  {{ item.title }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 应用场景 -->
      <div
        :class="$global.isMobile ? 'solution-applications-mobile' : 'solution-applications-pc'"
        v-if="localResults['applications'] && (localResults['applications'].length > 0)"
      >
        <div class="solution-applications-content">
          <div class="solution-applications-content-title">应用场景</div>
          <div class="solution-applications-content-list">
            <ul>
              <li class="applicationItem" v-for="(item, index) in localResults['applications']" :key="index">
                <div class="solution-applications-content-list-thumb"> <img :src="item.thumb" /> </div>
                <div class="solution-applications-content-list-name"> {{ item.name }} </div>
                <div class="solution-applications-content-list-brief"> {{ item.brief }} </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <NullPage />
    </div>
  </div>
</template>

<script>
import NullPage from '@/components/NullPage'
export default {
  /* 解决方案 */
  name: 'Solution',
  components: {
    NullPage
  },
  metaInfo () {
    return {
      title: this.headMetaInfo.title,
      meta: [
        { name: 'keywords', content: this.headMetaInfo.keywords },
        { name: 'discription', content: this.headMetaInfo.content }
      ]
    }
  },
  data () {
    return {
      headMetaInfo: {
        title: '广州帕克西软件开发有限公司',
        keywords: '',
        content: ''
      },
      nav_id: -1,
      localResults: [],
      onlineMarketingIndex: 0,
      offlineMarketingIndex: 0,
      relatedIndex: -1
    }
  },
  watch: {
    $route () { this.reqlocalResults() }
  },
  created () { },
  mounted () {
    this.headMetaInfo.title = this.$global.head_title
    this.headMetaInfo.keywords = this.$global.head_keywords
    this.headMetaInfo.content = this.$global.head_description
    this.reqlocalResults()
  },
  computed: { },
  methods: {
    reqlocalResults: function () {
      this.onlineMarketingIndex = 0
      this.offlineMarketingIndex = 0
      if (this.$route.params.id) {
        this.nav_id = this.$route.params.id
      } else if (this.$route.query.id) {
        this.nav_id = this.$route.query.id
      } else {
        this.nav_id = -1
      }
      if (this.nav_id !== -1) {
        this.$axios({
          url: 'api/solution/' + this.nav_id,
          method: 'get'
        }).then(res => {
          /* console.log(this.nav_id, res.data) */
          this.localResults = null
          if (res.data) {
            if (res.data.result && res.data.result.length > 0) {
              this.localResults = res.data.result[0]
              // console.log(this.localResults)
              /* 设置Header的banner图 */
              if (this.localResults.banner) {
                this.$emit('commandEvent', 'header-banner', [{ thumb: this.localResults.banner }])
              } else {
                this.$emit('commandEvent', 'header-banner', null)
              }
              if (!this.$global.isMobile) {
                if (this.localResults.offline_marketing && this.localResults.offline_marketing.length > 0) {
                  if (this.localResults.offline_marketing[0]) {
                    if (this.localResults.offline_marketing[0].maketings) {
                      this.offlineMarketingIndex = Math.ceil(this.localResults.offline_marketing[0].maketings.length / 2)
                      if (this.offlineMarketingIndex > 0) {
                        this.offlineMarketingIndex = this.offlineMarketingIndex - 1
                      }
                    }
                  }
                }
              }
              if (this.localResults) {
                var headKeywords = []
                if (this.localResults.title) {
                  this.$global.onUpdateHeadTitle(this.localResults.title + '-广州帕克西软件开发有限公司')
                  this.headMetaInfo.title = this.localResults.title + '-广州帕克西软件开发有限公司'
                  headKeywords.push(this.localResults.title.replace('解决方案', ''))
                  if (this.localResults.title.indexOf('解决方案') !== -1) {
                    headKeywords.push('解决方案')
                  }
                }
                if (this.localResults.offline_marketing && this.localResults.offline_marketing.length > 0) {
                  for (var o = 0; o < this.localResults.offline_marketing.length; o++) {
                    if (this.localResults.offline_marketing[o] && this.localResults.offline_marketing[o].maketings && this.localResults.offline_marketing[o].maketings.length > 0) {
                      for (var m = 0; m < this.localResults.offline_marketing[o].maketings.length; m++) {
                        if (this.localResults.offline_marketing[o].maketings[m] && this.localResults.offline_marketing[o].maketings[m].name) {
                          headKeywords.push(this.localResults.offline_marketing[o].maketings[m].name)
                        }
                      }
                    }
                  }
                }
                if (headKeywords.length > 0) {
                  this.$global.onUpdateHeadKeywords(headKeywords)
                  this.headMetaInfo.keywords = this.$global.getHeadKeywords(headKeywords)
                }
                if (this.localResults.brief) {
                  const content = this.localResults.brief.trim().replace(/\s+|&nbsp;/ig, '')
                  this.$global.onUpdateHeadDescription(content)
                  this.headMetaInfo.content = content
                }
              }
            } else {
              this.$emit('commandEvent', 'header-banner', null)
            }
          } else {
            this.$emit('commandEvent', 'header-banner', null)
          }
        })
      } else {
        console.log('跳转首页')
        if (window.location.href.indexOf('localhost') === -1) { // 要加上这句判断，否则打包有问题，缺少按照路由文件夹打包
          this.$router.push({ path: '/' })
        } else {
          this.localResults = null
          this.$emit('commandEvent', 'header-banner', null)
        }
      }
    },
    onlineMarketingMouseenter: function (index) {
      this.onlineMarketingIndex = index
    },
    offlineMarketingMouseenter: function (index) {
      this.offlineMarketingIndex = index
    },
    relatedDetailClick: function (item) {
      if (item && item.url) {
        var itemURL = item.url
        if (itemURL.indexOf('?id=') !== -1) {
          const paramsId = new RegExp('\\?id=', 'g') // g代表全部
          itemURL = itemURL.replace(paramsId, '/')
        }
        this.$router.push(itemURL)
        window.scrollTo(0, 0)
      }
    }
  }
}
</script>

<style lang="less" scoped>
@padding:80px 0px;
@title: 32px;
@fontWeight: 700;
@title_sub: 14px;
@fontWeight_sub: 400;
@color_sub: #666;
@textAlign_sub: center;
@width_sub: 826px;
@lineHeight_sub: 30px;
@margin_sub: 30px auto;
ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}
img {
  vertical-align: top;
  max-width: 100%;
}
.Solution {
  width: 100%;
  overflow: hidden;
  text-align: center;
  color: #333;
  .solution-superiorities-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .solution-superiorities-content {
      width: var(--v-width);
      overflow: hidden;
      margin: 0 auto;
      padding: 80px 0 10px 0;
      .solution-superiorities-content-title {
        font-size: @title;
        font-weight: @fontWeight;
      }
      .solution-superiorities-content-subtitle {
        font-size: @title_sub;
        font-weight: @fontWeight_sub;
        color: @color_sub;
        text-align: @textAlign_sub;
        width: @width_sub;
        line-height: @lineHeight_sub;
        margin: @margin_sub;
      }
      .solution-superiorities-content-list {
        width: 100%;
        overflow: hidden;
        margin: 48px auto 0px auto;
        text-align:center;
        ul {
          overflow: hidden;
          display:inline-block;
          li {
            width: 300px;
            height: 270px;
            float: left;
            .solution-superiorities-content-list-thumb {
              width: 74px;
              height: 74px;
              text-align: center;
              margin: 0 auto;
              img {
                width: 100%;
              }
            }
            .solution-superiorities-content-list-name {
              font-size: 20px;
              font-weight: bold;
              text-align: center;
              margin: 20px 0px;
            }
            .solution-superiorities-content-list-brief {
              width: 80%;
              height: 50px;
              margin: 0 auto;
              font-size: @title_sub;
              font-weight: @fontWeight_sub;
              line-height: @lineHeight_sub;
              text-align: center;
              color: #666666;
            }
          }
        }
      }
    }
  }
  .solution-superiorities-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .solution-superiorities-content {
      width: calc(var(--v-width) - 20px);
      overflow: hidden;
      margin: 0 auto;
      padding: 30px 0px 10px 0px;
      text-align:center;
      .solution-superiorities-content-title {
        font-size: var(--v-font-size-32);
        font-weight: @fontWeight;
      }
      .solution-superiorities-content-subtitle {
        width: 100%;
        font-size:var(--v-font-size-14);
        font-weight: @fontWeight_sub;
        color: @color_sub;
        text-align: @textAlign_sub;
        line-height: 20px;
        margin: 10px auto;
      }
      .solution-superiorities-content-list {
        overflow: hidden;
        margin: 10px auto;
        text-align:center;
        ul {
          display:inline-block;
          li {
            width: calc(100% / 2 - 20px);
            //height: 270px;
            float: left;
            margin: 8px 10px;
            .solution-superiorities-content-list-thumb {
              width: 32px;
              height: 32px;
              text-align: center;
              margin: 0 auto;
              img {
                width: 100%;
              }
            }
            .solution-superiorities-content-list-name {
              font-size: var(--v-font-size-18);
              font-weight: bold;
              text-align: center;
              margin: 10px 0px;
            }
            .solution-superiorities-content-list-brief {
              width: 94%;
              //height: 50px;
              margin: 10px auto;
              font-size: var(--v-font-size-14);
              font-weight:  @fontWeight_sub;
              line-height: 20px;
              text-align: center;
              color: #666666;
            }
          }
        }
      }
    }
  }
  .solution-maketingsOnline-pc {
    width: 100%;
    overflow: hidden;
    background-color: #f2f2f2;
    .solution-maketingsOnline-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: @padding;
      .solution-maketingsOnline-content-title {
        font-size: @title;
        font-weight: @fontWeight;
      }
      .solution-maketingsOnline-content-subtitle {
        font-size: @title_sub;
        font-weight: @fontWeight_sub;
        color: @color_sub;
        text-align: @textAlign_sub;
        width: @width_sub;
        line-height: @lineHeight_sub;
        margin: @margin_sub;
      }
      .solution-maketingsOnline-content-list {
        width: calc(100% - 200px);
        // overflow: hidden;
        padding: 20px 100px;
        text-align:center;
        ul {
          display:inline-block;
          li {
            width: 255px;
            height: 83px;
            position: relative;
            float: left;
            cursor: pointer;
            .solution-maketingsOnline-content-list-thumb {
              width: 255px;
              height: 83px;
              //position: absolute;
              left: 0px;
              top: 0px;
              img {
                width: 100%;
                cursor: pointer;
              }
              .solution-maketingsOnline-content-list-name {
                line-height: 83px;
                font-size: 20px;
                font-weight: 500;
                text-align: center;
                margin: 0 auto;
                position: relative;
                top: -83px;
              }
            }
          }
          li:nth-child(2) {
            margin: 0px 117px;
          }
        }
      }
      .solution-maketingsOnline-content-serve {
        width: calc(100% - 200px);
        overflow: hidden;
        padding: 20px 100px;
        .solution-maketingsOnline-content-serve-left {
          width: 540px;
          height: 306px;
          float: left;
          img {
            width: 100%;
          }
        }
        .solution-maketingsOnline-content-serve-right {
          float: left;
          width: calc(100% - 540px - 85px);
          float: left;
          padding-left: 80px;
          margin-top: 30px;
          .solution-maketingsOnline-content-serve-title {
            font-size: 26px;
            font-weight: bold;
            text-align: left;
            color: #4d4d4d;
            margin-bottom: 32px;
          }
          .solution-maketingsOnline-content-serve-list {
            ul {
              li {
                margin: 20px 0px;
                text-align: left;
                font-size: 16px;
                color: #4d4d4d;
                text-indent: 25px;
                position: relative;
                .solution-maketingsOnline-content-serve-list-circle {
                  position: absolute;
                  width: 12px;
                  height: 12px;
                  border-radius: 12px;
                  background-color: #ea6634;
                  top: 8px;
                }
                .solution-maketingsOnline-content-serve-list-text {
                  line-height: 25px;
                }
              }
            }
          }
        }
      }
    }
  }
  .solution-maketingsOnline-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #f2f2f2;
    .solution-maketingsOnline-content {
      width: calc(var(--v-width) - 20px);
      margin: 0 auto;
      overflow: hidden;
      padding: 30px 0px 10px 0px;
      .solution-maketingsOnline-content-title {
        font-size: var(--v-font-size-32);
        font-weight: @fontWeight;
      }
      .solution-maketingsOnline-content-subtitle {
        width: 100%;
        font-size: var(--v-font-size-14);
        font-weight: @fontWeight_sub;
        color: @color_sub;
        text-align: @textAlign_sub;
        line-height: 20px;
        margin: 10px auto;
      }
      .solution-maketingsOnline-content-list {
        overflow: hidden;
        margin: 5px auto;
        text-align:center;
        ul {
          display: table;
          margin: 0 auto;
          li {
            width: calc(100% / 3 - 10px);
            //height: 83px;
            float: left;
            text-align:center;
            margin: 5px;
            .solution-maketingsOnline-content-list-thumb {
              width: 100%;
              //height: 83px;
              position: relative;
              text-align: center;
              margin: 0 auto;
              img {
                width: 100%;
                height: 100%;
                cursor: pointer;
              }
              .solution-maketingsOnline-content-list-name {
                font-size: var(--v-font-size-18);
                font-weight: 500;
                text-align: center;
                margin: 0 auto;
                position: absolute;
                width: 100%;
                top: 48%;
                left: 50%;
                transform: translate(-50%, -55%);
              }
            }
          }
        }
      }
      .solution-maketingsOnline-content-serve {
        width: 100%;
        overflow: hidden;
        .solution-maketingsOnline-content-serve-left {
          width: 100%;
          //height: 306px;
          float: left;
          img {
            width: 100%;
          }
        }
        .solution-maketingsOnline-content-serve-right {
          float: left;
          width: 100%;
          float: left;
          padding: 10px 10px 20px 10px;
          .solution-maketingsOnline-content-serve-title {
            font-size: var(--v-font-size-18);
            font-weight: bold;
            text-align: left;
            color: #4d4d4d;
          }
          .solution-maketingsOnline-content-serve-list {
            ul {
              li {
                margin: 5px 0px;
                text-align: left;
                font-size: var(--v-font-size-14);
                color: #4d4d4d;
                text-indent: 10px;
                position: relative;
                .solution-maketingsOnline-content-serve-list-circle {
                  position: absolute;
                  width: 5px;
                  height: 5px;
                  border-radius: 5px;
                  background-color: #ea6634;
                  top: 8px;
                }
                .solution-maketingsOnline-content-serve-list-text {
                  line-height: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
  .solution-maketingsOffline-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .solution-maketingsOffline-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 80px 0 0 0;
      .solution-maketingsOffline-content-title {
        font-size: @title;
        font-weight: @fontWeight;
      }
      .solution-maketingsOffline-content-subtitle {
        font-size: @title_sub;
        font-weight: @fontWeight_sub;
        color: @color_sub;
        text-align: @textAlign_sub;
        width: @width_sub;
        line-height: @lineHeight_sub;
        margin: @margin_sub;
      }
      .solution-maketingsOffline-content-list {
        margin: 0 auto;
        text-align:center;
        margin-bottom: 50px;
        ul {
          display:inline-block;
          li {
            width: 230px;
            height: 440px;
            float: left;
            background-color: #fff;
            position: relative;
            cursor: pointer;
            .solution-maketingsOffline-content-list-thumb,.solution-maketingsOffline-content-list-bg {
              width: 100%;
              height: 100%;
              position: absolute;
              img {
                width: 100%;
                height: 100%;
                transition: all 0.5s ease-in-out;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
              }
            }
            .solution-maketingsOffline-content-list-namebrief {
              position: absolute;
              left: 0px;
              bottom: 18px;
              width: 100%;
              .solution-maketingsOffline-content-list-name {
                font-size: 20px;
                font-weight: 500;
                color: #ffffff;
              }
              .solution-maketingsOffline-content-list-brief {
                font-size: 12px;
                font-weight: 400;
                text-align: center;
                color: #ffffff;
                line-height: 20px;
                width: 193px;
                margin: 20px auto 15px auto;
                transition: all 0.5s;
              }
            }
          }
          li:nth-child(2) {
            margin-left: 11.9px;
          }
          li:nth-child(3) {
            margin-left: 11.9px;
          }
          li:nth-child(4) {
            margin-left: 11.9px;
          }
          li:nth-child(5) {
            margin-left: 11.9px;
          }
        }
      }
      .solution-maketingsOffline-content-list-li:hover {
        transform: scale(1.12);
        -ms-transform: scale(1.12); /* IE 9 */
        -moz-transform: scale(1.12); /* Firefox */
        -webkit-transform: scale(1.12); /* Safari 和 Chrome */
        -o-transform: scale(1.12);
        z-index: 9;
        transition: all 0.5s ease-in-out;
      }
      .solution-maketingsOffline-content-list-li-mouseover {
        transform: scale(1.12);
        -ms-transform: scale(1.12); /* IE 9 */
        -moz-transform: scale(1.12); /* Firefox */
        -webkit-transform: scale(1.12); /* Safari 和 Chrome */
        -o-transform: scale(1.12);
        z-index: 2;
        transition: all 0.5s ease-in-out;
      }
      .solution-maketingsOffline-content-list-li-mouseout {
        transform: scale(1);
        -ms-transform: scale(1); /* IE 9 */
        -moz-transform: scale(1); /* Firefox */
        -webkit-transform: scale(1); /* Safari 和 Chrome */
        -o-transform: scale(1);
        z-index: 1;
        transition: all 0.5s ease-in-out;
      }
    }
  }
  .solution-maketingsOffline-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .solution-maketingsOffline-content {
      width: calc(var(--v-width) - 10px);
      margin: 0 auto;
      overflow: hidden;
      padding: 30px 0px 10px 0px;
      .solution-maketingsOffline-content-title {
        font-size: var(--v-font-size-32);
        font-weight: @fontWeight;
      }
      .solution-maketingsOffline-content-subtitle {
        width: calc(var(--v-width) - 20px);
        font-size: var(--v-font-size-14);
        font-weight: @fontWeight_sub;
        color: @color_sub;
        text-align: @textAlign_sub;
        line-height: 20px;
        margin: 10px auto;
      }
      .solution-maketingsOffline-content-list {
        margin: 0 auto;
        text-align:center;
        ul {
          // https://www.jianshu.com/p/8e27b663b70f
          overflow: auto;
          white-space:nowrap;
          width: auto;
          width: -moz-fit-content;
          width: -webkit-fit-content;
          width: fit-content;
          float: left;
          display: inline-block;
          padding: 20px 0px;
          li {
            width: calc(var(--v-width) / 3);
            //注意这里不能用float:left 因为设置float后里超过一屏后会自动换行
            //float: left;
            position: relative;
            display: inline-block;
            margin: 0px 8px;
            .solution-maketingsOffline-content-list-thumb,.solution-maketingsOffline-content-list-bg {
              position: relative;
              width: 100%;
              height: 100%;
              img {
                width: 100%;
                height: 100%;
                transition: all 0.5s ease-in-out;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
              }
            }
            .solution-maketingsOffline-content-list-bg{
              position: absolute;
              top: 0px;
            }
            .solution-maketingsOffline-content-list-namebrief {
              width: 100%;
              //padding: 0 10px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              .solution-maketingsOffline-content-list-name {
                font-size: var(--v-font-size-20);
                font-weight: 500;
                color: #ffffff;
              }
              .solution-maketingsOffline-content-list-brief{
                font-size: var(--v-font-size-14);
                font-weight: 400;
                text-align: center;
                color: #ffffff;
                line-height: 20px;
                width: calc(100% - 10px);
                margin: 20px auto 10px auto;
                overflow: hidden;
                // 自动换行
                // https://www.cnblogs.com/500m/p/11611015.html
                word-break: break-all; // 只对英文起作用，以字母作为换行依据。
                word-wrap: break-word; // 只对英文起作用，以单词作为换行依据。
                white-space: pre-wrap; // 只对中文起作用，强制换行。
              }
            }
          }
        }
      }
      .solution-maketingsOffline-content-list-li:hover {
        transform: scale(1.12);
        -ms-transform: scale(1.12); /* IE 9 */
        -moz-transform: scale(1.12); /* Firefox */
        -webkit-transform: scale(1.12); /* Safari 和 Chrome */
        -o-transform: scale(1.12);
        z-index: 9;
        transition: all 0.5s ease-in-out;
      }
      .solution-maketingsOffline-content-list-li-mouseover {
        transform: scale(1.15);
        -ms-transform: scale(1.15); /* IE 9 */
        -moz-transform: scale(1.15); /* Firefox */
        -webkit-transform: scale(1.15); /* Safari 和 Chrome */
        -o-transform: scale(1.15);
        z-index: 2;
        transition: all 0.5s ease-in-out;
      }
      .solution-maketingsOffline-content-list-li-mouseout {
        transform: scale(1);
        -ms-transform: scale(1); /* IE 9 */
        -moz-transform: scale(1); /* Firefox */
        -webkit-transform: scale(1); /* Safari 和 Chrome */
        -o-transform: scale(1);
        z-index: 1;
        transition: all 0.5s ease-in-out;
      }
    }
  }
  .solution-related_products-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .solution-related_products-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 80px 0 30px 0;
      .solution-related_products-content-title {
        font-size: @title;
        font-weight: @fontWeight;
      }
      .solution-related_products-content-list {
        margin: 50px auto 0px auto;
        text-align:center;
        ul {
          display:inline-block;
          .solution-related_products-content-list-data {
            position: relative;
            display: inline-block;
            padding: 10px;
            width: 250px;
            height: 210px;
          }
          .solution-related_products-content-list-data:hover {
            background-color: #f2f2f2;
          }
          li {
            float: left;
            .solution-related_products-content-list-logoname {
              .solution-related_products-content-list-logo {
                float: left;
                width: 80px;
                height: 80px;
                img {
                  width: 100%;
                }
              }
              .solution-related_products-content-list-name {
                float: left;
                width: 164px;
                height: 80px;
                text-align: center;
                font-size: 20px;
                font-weight: bold;
                line-height: 80px;
              }
            }
            .cli {
              clear: both;
              font-size: 0;
              height: 0;
              overflow: hidden;
              display: block;
            }
            .solution-related_products-content-list-brief {
              width: 244px;
              height: 57px;
              font-size: 14px;
              line-height: 20px;
              text-align: left;
              font-weight: 500;
              margin: 20px 0px;
            }
            .solution-related_products-content-list-goto {
              color: #ea6634;
              font-size: 16px;
              width: 100%;
              position: relative;
              .solution-related_products-content-list-do {
                width: 80px;
                text-align: left;
                font-weight: bold;
                cursor: pointer;
              }
            }
          }
          .solution-related_products-content-qr_code {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
            background-color: #f2f2f2;
            // display: none;
            .solution-related_products-content-qr_code-img {
              width: 210px;
              height: 210px;
              position: relative;
              top: 10px;
            }
          }
          .solution-related_products-content-qr_code:hover {
            display: block;
          }
        }
      }
    }
  }
  .solution-related_products-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .solution-related_products-content {
      width: calc(var(--v-width) - 20px);
      margin: 0 auto;
      overflow: hidden;
      padding: 30px 0px 10px 0px;
      .solution-related_products-content-title {
        font-size: var(--v-font-size-32);
        font-weight: @fontWeight;
      }
      .solution-related_products-content-list {
        margin: 20px auto;
        text-align:center;
        ul {
          display:inline-block;
          .solution-related_products-content-list-data {
            position: relative;
            display: inline-block;
            padding: 10px;
            width: calc(100% / 2 - 20px);
            //height: 210px;
          }
          .solution-related_products-content-list-data:hover {
            background-color: #f2f2f2;
          }
          li {
            float: left;
            .solution-related_products-content-list-logoname {
              .solution-related_products-content-list-logo {
                float: left;
                width: 48px;
                height: 48px;
                img {
                  width: 100%;
                }
              }
              .solution-related_products-content-list-name {
                float: left;
                //width: 164px;
                height: 48px;
                text-align: center;
                font-size: var(--v-font-size-20);
                font-weight: bold;
                line-height: 48px;
                margin-left: 5px;
              }
            }
            .cli {
              clear: both;
              font-size: 0;
              height: 0;
              overflow: hidden;
              display: block;
            }
            .solution-related_products-content-list-brief {
              width: 100%;
              height: 80px;
              font-size: var(--v-font-size-14);
              line-height: 20px;
              text-align: left;
              font-weight: 500;
              margin: 5px 0px;
            }
            .solution-related_products-content-list-goto {
              color: #ea6634;
              font-size: var(--v-font-size-16);
              width: 100%;
              position: relative;
              .solution-related_products-content-list-do {
                width: 80px;
                text-align: left;
                font-weight: bold;
                cursor: pointer;
              }
            }
          }
          .solution-related_products-content-qr_code {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0px;
            top: 0px;
            background-color: #f2f2f2;
            // display: none;
            .solution-related_products-content-qr_code-img {
              width: calc(100% - 10px);
              //height: 100%;
              position: relative;
              top: 9px;
              img{
                width: 100%;
                height: 100%;
              }
            }
          }
          .solution-related_products-content-qr_code:hover {
            display: block;
          }
        }
      }
    }
  }
  .solution-customize-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .solution-customize-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 60px 0;
      .solution-customize-content-title {
        font-size: @title;
        font-weight: @fontWeight;
      }
      .solution-customize-content-list {
        width: 100%;
        overflow: hidden;
        text-align: center;
        padding: 30px 0;
        ul {
          overflow: hidden;
          display: flex;
          li {
            flex: 1;
            width: 300px;
            height: 150px;
            .solution-customize-content-list-thumb {
              width: 84px;
              height: 84px;
              text-align: center;
              margin: 0 auto;
              img {
                width: 100%;
              }
            }
            .solution-customize-content-list-title {
              font-size: 20px;
              font-weight: bold;
              text-align: center;
              margin: 20px 0px;
              color: #333333;
            }
          }
        }
      }
    }
  }
  .solution-customize-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .solution-customize-content {
      width: calc(var(--v-width) - 20px);
      margin: 0 auto;
      overflow: hidden;
      padding: 30px 0px 10px 0px;
      .solution-customize-content-title {
        font-size:var(--v-font-size-32);
        font-weight: @fontWeight;
      }
      .solution-customize-content-list {
        width: 100%;
        overflow: hidden;
        text-align: center;
        padding: 10px 0;
        ul {
          overflow: hidden;
          display: flex;
          li {
            flex: 1;
            //width: 300px;
            //height: 150px;
            .solution-customize-content-list-thumb {
              width: 48px;
              height: 48px;
              text-align: center;
              margin: 0 auto;
              img {
                width: 100%;
              }
            }
            .solution-customize-content-list-title {
              font-size: var(--v-font-size-14);
              //font-weight: bold;
              text-align: center;
              color: #333333;
              margin: 10px auto;
            }
          }
        }
      }
    }
  }
  .solution-applications-pc {
    width: 100%;
    overflow: hidden;
    background-color: #f2f2f2;
    .solution-applications-content {
      width: var(--v-width);
      overflow: hidden;
      margin: 0 auto;
      padding: @padding;
      .solution-applications-content-title {
        font-size: @title;
        font-weight: @fontWeight;
      }
      .solution-applications-content-list {
        margin: 60px auto;
        text-align:center;
        ul {
          display:inline-block;
          li {
            width: 373px;
            height: 300px;
            float: left;
            cursor: pointer;
            background-color: #fff;
            .solution-applications-content-list-thumb {
              width: 100%;
              //width: 373px;
              height: 216px;
              overflow: hidden;
              img {
                width: 100%;
                transition: all 0.5s ease 0s;
              }
            }
            .solution-applications-content-list-name {
              font-size: 16px;
              font-weight: bold;
              text-align: center;
              margin: 20px 0px 10px 0px;
            }
            .solution-applications-content-list-brief {
              font-size: 14px;
              font-weight: 500;
              text-align: center;
              color: #666666;
            }
            &:hover {
              .solution-applications-content-list-thumb {
                img {
                  transform: scale(1.1);
                  -ms-transform: scale(1.1); /* IE 9 */
                  -moz-transform: scale(1.1); /* Firefox */
                  -webkit-transform: scale(1.1); /* Safari 和 Chrome */
                  -o-transform: scale(1.1);
                }
              }
            }
          }
          li:nth-child(2) {
            margin: 0px 40px;
          }
        }
      }
    }
  }
  .solution-applications-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #f2f2f2;
    .solution-applications-content {
      width: calc(var(--v-width) - 20px);
      overflow: hidden;
      margin: 0 auto;
      padding: 30px 0px 10px 0px;
      .solution-applications-content-title {
        font-size: var(--v-font-size-32);
        font-weight: @fontWeight;
      }
      .solution-applications-content-list {
        margin: 10px auto;
        text-align:center;
        ul {
          display:inline-block;
          li {
            width: 100%;
            //height: 300px;
            float: left;
            cursor: pointer;
            background-color: #fff;
            border: 1px dashed rgba(123, 123, 123, 0.2);
            margin: 2px auto;
            .solution-applications-content-list-thumb {
              width: 100%;
              //height: 216px;
              img {
                width: 100%;
              }
            }
            .solution-applications-content-list-name {
              font-size: var(--v-font-size-16);
              font-weight: bold;
              text-align: center;
              margin: 10px 0px;
            }
            .solution-applications-content-list-brief {
              font-size: var(--v-font-size-14);
              font-weight: 500;
              text-align: center;
              color: #666666;
              margin: 10px 0px;
            }
          }
        }
      }
    }
  }
}
</style>
