<template>
  <div class="HomeIndex">
    <TopControl />
    <div :class="$global.isMobile ? 'home-index-top-mobile' : 'home-index-top-pc'">
      <div class="home-index-title"> 丰富的产品体系，为各行各业数字化营销服务 </div>
      <div class="home-index-productlist" v-if="productData && productData.length > 0">
        <ul>
          <span v-for="(item, index) in productData" :key="index">
            <li :class="productIndex == index ? 'home-index-productlist-li-hover' : ''" @mouseover.stop="productMouseover(index),showProductQrcode = false">
              <img :src="item.logo" alt="" />
            </li>
          </span>
        </ul>
      </div>
      <div class="home-index-product-content" v-if="productData && productIndex < productData.length">
        <div v-if="productIndex !== -1 ">
          <div class="home-index-product-content-left">
            <div class="home-index-product-content-left-image">
              <img :src="productData[productIndex].thumb" />
            </div>
          </div>
          <div class="home-index-product-content-right">
            <div class="home-index-product-content-right-title">
              <div class="home-index-product-content-right-title-text"> {{ productData[productIndex].name }} </div>
              <span class="home-index-product-content-right-title-line"></span>
            </div>
            <div class="home-index-product-content-right-subtitle">
              <div class="home-index-product-content-right-subtitle-text">
                {{ productData[productIndex].title }}
              </div>
            </div>
            <div class="home-index-product-content-right-content">
              <div class="home-index-product-content-right-content-text">
                {{ productData[productIndex].brief }}
              </div>
            </div>
            <div v-if="productData[productIndex]['url']"
              :class="$global.isMobile ? 'home-index-product-content-right-more-mobile' : 'home-index-product-content-right-more-pc'"
            >
              <input
                class="home-index-product-content-right-more-input"
                type="button"
                value="了解产品详情"
                @click.stop="productMoreClick(productData[productIndex])"
              />
            </div>
            <div v-else-if="productData[productIndex]['qr_code']"
              :class="$global.isMobile ? 'home-index-product-content-right-more-mobile' : 'home-index-product-content-right-more-pc'"
            >
              <input class="home-index-product-content-right-more-input"
                type="button"
                value="立即体验"
                @click.stop="$global.isMobile ? (showProductQrcode = !showProductQrcode) : (() => {})"
              />
              <div class="home-index-product-content-right-qr_code" :style="$global.isMobile ? ( showProductQrcode ? 'display:block' : 'display:none') : ''">
                <img class="home-index-product-content-right-qr_code-bgimg" src="../../assets/images/Home/qrcode-bgimg.png" />
                <img class="home-index-product-content-right-qr_code-img" :src="productData[productIndex]['qr_code']" />
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <img src="../../assets/images/Home/product-all.png">
        </div>
      </div>
    </div>
    <!-- 解决方案 -->
    <div :class="$global.isMobile ? 'home-index-solution-mobile' : 'home-index-solution-pc'">
      <div class="home-index-solution-title">解决方案</div>
      <div class="home-index-solution-subtitle">
        专业的解决方案，全方位覆盖企业的各种业务场景
      </div>
      <div class="home-index-solution-content" v-if="solutionData && solutionData.length > 0">
        <div class="home-index-solution-content-list">
          <div class="home-index-solution-content-list-left">
            <ul>
              <span v-for="(item, index) in solutionData" :key="index" >
                <!-- 最多仅显示7项内容-->
                <li v-if="index < 7" :class="solutionIndex == index ? 'pitch-on' : ''"  @mouseover="solutionMouseover(index)">
                  <span class="home-index-solution-content-list-left-text">{{ item.title }}</span>
                  <img v-if="solutionIndex == index" class="home-index-solution-content-list-left-image" src="../../assets/images/Home/zuo.png" />
                </li>
              </span>
            </ul>
          </div>
          <div class="home-index-solution-content-list-right">
            <ul>
              <li v-if="solutionIndex < solutionData.length" @click="solutionClick(solutionData[solutionIndex])">
                <img class="home-index-solution-content-list-right-image" :src="solutionData[solutionIndex].thumb" />
                <div class="solutionDesc"> {{ solutionData[solutionIndex].description }} </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作伙伴 -->
    <div :class="$global.isMobile ? 'home-index-partner-mobile' : 'home-index-partner-pc'">
      <div class="home-index-partner-title">合作伙伴</div>
      <div class="home-index-partner-subtitle">携手并进，探索AR/AI无限可能</div>
      <div class="home-index-partner-list" v-if="cooperationData && cooperationData.length > 0">
        <ul>
          <span v-for="(item, index) in cooperationData" :key="index" :style="$global.isMobile ? '' : 'display: inline-block'">
            <li v-if="index < cooperationLength">
              <img :src="item.logo" :title="item.name" />
            </li>
          </span>
        </ul>
      </div>
      <div v-if="cooperationData && cooperationData.length > 0" class="home-index-partner-more" @click="cooperationMoreClick">查看更多</div>
    </div>
    <!-- 新闻动态 -->
    <div :class="$global.isMobile ? 'home-index-news-mobile' : 'home-index-news-pc'">
      <div class="home-index-news-top">
        <div class="home-index-news-top-title">新闻动态</div>
        <div class="home-index-news-top-subtitle">
          企业动态，行业资讯实时更新
        </div>
      </div>
      <div class="home-index-news-list" v-if="newData && newData.length > 0">
        <ul>
          <span v-for="(item, index) in newData" :key="index">
            <li v-if="index < 3" @click.stop="toNewsDetails(item)">
              <div class="home-index-news-list-image">
                <img :src="item.thumb" />
              </div>
              <div class="home-index-news-list-text"> {{ item.title }} </div>
              <div class="home-index-news-list-updated_at"> {{ item.updated_at | formatDate }} </div>
            </li>
          </span>
        </ul>
      </div>
      <div class="home-index-news-more">
        <div class="home-index-news-more-content">
          <div class="home-index-news-more-content-image">
            <img src="../../assets/images/Home/bg1.png" />
          </div>
        </div>
        <div class="home-index-news-more-button" @click="newMoreClick">了解更多</div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div :class="$global.isMobile ? 'home-index-contactus-mobile' : 'home-index-contactus-pc'">
      <div class="home-index-contactus-title">联系我们</div>
      <!--
      <div class="home-index-contactus-subtitle">
        企业动态，行业资讯实时更新
      </div>
      -->
      <div class="home-index-contactus-list" v-if="contactusData">
        <ul>
          <li v-for="(item ,index) in contactusData" :key="index" :style="(contactusData.length > 1 && index < (contactusData.length - 1))? 'border-right: 1px solid #dcdcdc' : ''">
            <div class="home-index-contactus-list-image">
              <img :src="item.thumb" />
            </div>
            <div class="home-index-contactus-list-site"> {{ item.title }}</div>
            <div class="home-index-contactus-list-address">
              {{ item.description }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import TopControl from '@/components/TopControl'
import { reqHomeContactUsResults } from '@/api/apireq'
export default {
  name: 'HomeIndex',
  components: {
    TopControl
  },
  metaInfo () {
    return {
      title: this.headMetaInfo.title,
      meta: [
        { name: 'keywords', content: this.headMetaInfo.keywords },
        { name: 'discription', content: this.headMetaInfo.content }
      ]
    }
  },
  data () {
    return {
      headMetaInfo: {
        title: '广州帕克西软件开发有限公司',
        keywords: '',
        content: ''
      },
      productIndex: -1,
      productData: [],
      solutionIndex: 0,
      solutionData: [],
      cooperationLength: this.$global.isMobile ? 8 : 12,
      cooperationData: [],
      newData: [],
      contactusData: [],
      showProductQrcode: false
    }
  },
  created () { },
  mounted () {
    this.headMetaInfo.title = this.$global.head_title
    this.headMetaInfo.keywords = this.$global.head_keywords
    this.headMetaInfo.content = this.$global.head_description
    this.$emit('commandEvent', 'header-banner', null)
    // 产品服务
    this.productResults()
    // 解决方案
    this.solutionResults()
    // 品牌合作
    this.cooperationResults()
    // 新闻动态
    this.newResults()
    // 联系我们
    this.contactusResults()
    // 初始化
    this.onInited()
  },
  methods: {
    animateDom (ev, left, opacity, time, Timeout) {
      setTimeout(function () {
        // if ($(ev + ':animated').length === 0) {
        //   $(ev).animate({
        //     left: '0px',
        //     opacity: '1'
        //   }, time)
        // }
        var animatedElements = document.getElementsByClassName(ev + ':animated')
        if (animatedElements === null || animatedElements.length === 0) {
          var elements = document.getElementsByClassName(ev)
          if (elements && elements.length > 0) {
            Array.prototype.forEach.call(elements, function (element) {
              if (element) {
                element.animate([{ left: left, opacity: opacity }, { left: '0px', opacity: 1 }], { duration: time })
              }
            })
          }
        }
      }, Timeout)
    },
    onInited () {
      this.animateDom('home-index-product-content-right-title-text', '50px', '0.2', 300, 50)
      this.animateDom('home-index-product-content-right-title-line', '60px', '0.2', 350, 100)
      this.animateDom('home-index-product-content-right-subtitle-text', '70px', '0.2', 400, 150)
      this.animateDom('home-index-product-content-right-content-text', '80px', '0.2', 450, 200)
      this.animateDom('home-index-product-content-right-more-input', '90px', '0.2', 500, 150)
    },
    productResults () {
      this.productIndex = -1
      this.productData = []
      this.$axios({
        url: 'api/home/product-service',
        method: 'GET'
      }).then((res) => {
        /* console.log(res.data) */
        if (res.data && res.data.res) {
          this.productData = res.data.res
          var headKeywords = []
          if (this.productData !== undefined && this.productData !== null && this.productData.length > 0) {
            for (var p = 0; p < this.productData.length; p++) {
              if (this.productData[p].name) {
                headKeywords.push(this.productData[p].name)
              }
            }
          }
          if (this.solutionData !== undefined && this.solutionData !== null && this.solutionData.length > 0) {
            for (var s = 0; s < this.solutionData.length; s++) {
              if (this.solutionData[s].title) {
                headKeywords.push(this.solutionData[s].title.replace('解决方案', ''))
              }
            }
            headKeywords.push('解决方案')
          }
          if (headKeywords !== null && headKeywords.length > 0) {
            this.$global.onUpdateHeadKeywords(headKeywords)
            this.headMetaInfo.keywords = this.$global.getHeadKeywords(headKeywords)
          }
        }
      })
    },
    productMouseover (index) {
      this.productIndex = index
      this.animateDom('home-index-product-content-right-title-text', '50px', '0.2', 300, 50)
      this.animateDom('home-index-product-content-right-title-line', '60px', '0.2', 350, 100)
      this.animateDom('home-index-product-content-right-subtitle-text', '70px', '0.2', 400, 150)
      this.animateDom('home-index-product-content-right-content-text', '80px', '0.2', 450, 200)
      this.animateDom('home-index-product-content-right-more-input', '90px', '0.2', 500, 150)
    },
    productMoreClick (item) {
      if (item && item.url) {
        var itemURL = item.url
        if (itemURL.indexOf('?id=') !== -1) {
          const paramsId = new RegExp('\\?id=', 'g') // g代表全部
          itemURL = itemURL.replace(paramsId, '/')
        }
        this.$router.push(itemURL)
        window.scrollTo(0, 0)
      }
    },
    solutionResults () {
      this.solutionIndex = 0
      this.solutionData = []
      this.$axios({
        url: 'api/home/solution',
        method: 'GET'
      }).then((res) => {
        /* console.log(res.data) */
        if (res.data && res.data.res) {
          this.solutionData = res.data.res
          var headKeywords = []
          if (this.productData !== undefined && this.productData !== null && this.productData.length > 0) {
            for (var p = 0; p < this.productData.length; p++) {
              if (this.productData[p].name) {
                headKeywords.push(this.productData[p].name)
              }
            }
          }
          if (this.solutionData !== undefined && this.solutionData !== null && this.solutionData.length > 0) {
            for (var s = 0; s < this.solutionData.length; s++) {
              if (this.solutionData[s].title) {
                headKeywords.push(this.solutionData[s].title.replace('解决方案', ''))
              }
            }
            headKeywords.push('解决方案')
          }
          if (headKeywords !== null && headKeywords.length > 0) {
            this.$global.onUpdateHeadKeywords(headKeywords)
            this.headMetaInfo.keywords = this.$global.getHeadKeywords(headKeywords)
          }
        }
      })
    },
    solutionMouseover (index) {
      this.solutionIndex = index
    },
    solutionClick (item) {
      if (item && item.nav_id) {
        // this.$router.push('/solution?id=' + item.nav_id)
        this.$router.push('/solution/' + item.nav_id)
        window.scrollTo(0, 0)
      }
    },
    cooperationResults () {
      this.cooperationLength = this.$global.isMobile ? 8 : 12
      this.cooperationData = []
      this.$axios({
        url: 'api/cooperation',
        method: 'GET'
      }).then((res) => {
        /* console.log(res.data) */
        if (res.data && res.data.res) {
          this.cooperationData = res.data.res
        }
      })
    },
    cooperationMoreClick () {
      this.$router.push('/collaborate-case')
      window.scrollTo(0, 0)
    },
    newResults () {
      this.newData = []
      this.$axios({
        url: 'api/home/news',
        method: 'GET'
      }).then((res) => {
        /* console.log(res.data) */
        if (res.data && res.data.res) {
          this.newData = res.data.res
        }
      })
    },
    newMoreClick () {
      this.$router.push('/news')
      window.scrollTo(0, 0)
    },
    /* 跳转到详情页面 */
    toNewsDetails (item) {
      // this.$router.push('/news-details?id=' + item.id)
      this.$router.push('/news-details/' + item.id)
    },
    contactusResults () {
      this.contactusData = []
      reqHomeContactUsResults().then((res) => {
        try {
          // console.log('contactusResults', res.data)
          if (res.data) {
            if (res.data.code === 200) {
              this.contactusData = res.data.res
            }
          }
        } catch (e) {
          // TODO handle the exception
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@padding: 80px 0px 20px 0px;
@fontSize: 32px;
@fontWeight: 700;
@textAlign: center;
@fontSize_sub: 18px;
@textAlign_sub: center;
@fontWeight_sub: 500;
@color_sub: #666666;
.HomeIndex{
  font-size: var(--v-font-size-14);
}
.home-index-top-pc {
  color: #333333;
  width: var(--v-width);
  overflow: hidden;
  margin: 0 auto;
  .home-index-title {
    font-weight: bold;
    text-align: center;
    margin: 80px auto 40px auto;
    font-size: var(--v-font-size-32);
  }
  .home-index-productlist {
    width: 100%;
    margin: 0 auto 20px auto;
    text-align: center;
    ul {
      margin: 0 auto;
      overflow: hidden;
      display: inline-block;
      li {
        float: left;
        width: 75px;
        height: 75px;
        margin: 20px 30px;
        cursor: pointer;
        transition: all 0.6s;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    ul li:hover,.home-index-productlist-li-hover {
      transform: scale(1.5);
      -ms-transform: scale(1.5); /* IE 9 */
      -moz-transform: scale(1.5); /* Firefox */
      -webkit-transform: scale(1.5); /* Safari 和 Chrome */
      -o-transform: scale(1.5);
    }
  }
  .home-index-product-content {
    overflow: hidden;
    margin: 0 auto;
    .home-index-product-content-left {
      float: left;
      .home-index-product-content-left-image {
        width: 447px;
        height: 576px;
        img {
          height: 100%;
        }
      }
    }
    .home-index-product-content-right {
      width: 600px;
      float: left;
      padding: 0 10px 0 50px;
      .home-index-product-content-right-title {
        margin-top: 100px;
        height: 36px;
        font-size: 30px;
        font-weight: bold;
        text-align: left;
        position: relative;
        .home-index-product-content-right-title-text {
          position: absolute;
          // left: 50px;
          left: 0px;
          // opacity: 0;
          opacity: 1;
        }
        .home-index-product-content-right-title-line {
          position: absolute;
          width: 52px;
          height: 3px;
          bottom: -10px;
          background-color: #ea6634;
          // left: 50px;
          left: 0px;
          // opacity: 0;
          opacity: 1;
        }
      }
      .home-index-product-content-right-subtitle {
        margin-top: 20px;
        font-size: 22px;
        height: 28px;
        position: relative;
        width: 100%;
        .home-index-product-content-right-subtitle-text {
          position: absolute;
          width: 100%;
          // left: 60px;
          left: 0px;
          // opacity: 0;
          opacity: 1;
        }
      }
      .home-index-product-content-right-content {
        height: 150px;
        margin-top: 60px;
        font-size: 18px;
        word-spacing: 30px;
        line-height: 30px;
        position: relative;
        width: 100%;
        .home-index-product-content-right-content-text {
          position: absolute;
          // left: 80px;
          left: 0px;
          // opacity: 0;
          opacity: 1;
          width: 100%;
        }
      }
    }
  }
}
.home-index-top-mobile {
  color: #333333;
  width: calc(var(--v-width) - 20px);
  overflow: hidden;
  margin: 0 auto;
  .home-index-title {
    font-weight: bold;
    text-align: center;
    margin: 20px auto 10px auto;
    font-size: var(--v-font-size-32);
    line-height: 30px;
  }
  .home-index-productlist {
    width: 100%;
    margin: 0 auto 10px auto;
    text-align: center;
    ul {
      margin: 0 auto;
      overflow: hidden;
      display: inline-block;
      li {
        float: left;
        width: 45px;
        height: 45px;
        margin: 5px;
        cursor: pointer;
        transition: all 0.6s;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    ul li:hover,.home-index-productlist-li-hover {
      transform: scale(1.25);
      -ms-transform: scale(1.25); /* IE 9 */
      -moz-transform: scale(1.25); /* Firefox */
      -webkit-transform: scale(1.25); /* Safari 和 Chrome */
      -o-transform: scale(1.25);
    }
  }
  .home-index-product-content {
    overflow: hidden;
    margin: 0 auto;
    .home-index-product-content-left {
      //display: none;
      float: left;
      .home-index-product-content-left-image {
        width: 50%;
        //height: 576px;
        margin: 0px 25%;
        img {
          width: 100%;
        }
      }
      margin-bottom: 10px;
    }
    .home-index-product-content-right {
      width: calc(100% - 30px);
      float: left;
      margin: 0px 15px;
      .home-index-product-content-right-title {
        height: 20px;
        font-size: var(--v-font-size-32);
        font-weight: bold;
        text-align: left;
        position: relative;
        .home-index-product-content-right-title-text {
          position: absolute;
          // left: 50px;
          left: 0px;
          // opacity: 0;
          opacity: 1;
        }
        .home-index-product-content-right-title-line {
          position: absolute;
          width: 52px;
          height: 3px;
          bottom: -10px;
          background-color: #ea6634;
          // left: 50px;
          left: 0px;
          // opacity: 0;
          opacity: 1;
        }
      }
      .home-index-product-content-right-subtitle {
        margin-top: 20px;
        font-size: 16px;
        height: 28px;
        position: relative;
        width: 100%;
        .home-index-product-content-right-subtitle-text {
          position: absolute;
          width: 100%;
          // left: 60px;
          left: 0px;
          // opacity: 0;
          opacity: 1;
        }
      }
      .home-index-product-content-right-content {
        //height: 150px;
        margin-top: 15px;
        font-size: 12px;
        word-spacing: 30px;
        line-height: 30px;
        position: relative;
        width: 100%;
        .home-index-product-content-right-content-text {
          position: relative;
          // left: 80px;
          left: 0px;
          // opacity: 0;
          opacity: 1;
          width: 100%;
        }
      }
    }
  }
}
.home-index-product-content-right-more-pc {
  width: 165px;
  height: 36px;
  // position: absolute;
  text-align: center;
  margin-top: 72px;
  position: relative;
  .home-index-product-content-right-more-input {
    width: 100%;
    height: 100%;
    background: #ea6634;
    border: none;
    color: #fff;
    position: absolute;
    // left: 80px;
    left: 0px;
    // opacity: 0;
    opacity: 1;
    width: 100%;
    cursor: pointer;
  }
  .home-index-product-content-right-qr_code {
    width: 178px;
    height: 160px;
    position: absolute;
    left: 165px;
    top: -60px;
    display: none;
    .home-index-product-content-right-qr_code-bgimg {
      position: absolute;
      left: 5px;
      top: 0px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .home-index-product-content-right-qr_code-img {
      position: absolute;
      right: 0px;
      top: 5px;
      width: 150px;
      height: 150px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .home-index-product-content-right-more-input:hover + .home-index-product-content-right-qr_code {
    display: block;
  }
}
.home-index-product-content-right-more-mobile {
  width: 120px;
  height: 27px;
  // position: absolute;
  text-align: center;
  margin-top: 20px;
  position: relative;
  .home-index-product-content-right-more-input {
    width: 100%;
    height: 100%;
    background: #ea6634;
    border: none;
    color: #fff;
    position: absolute;
    // left: 80px;
    left: 0px;
    // opacity: 0;
    opacity: 1;
    width: 100%;
    cursor: pointer;
  }
  .home-index-product-content-right-qr_code {
    width: calc(178px * 0.8);
    height: calc(160px * 0.8);
    position: absolute;
    //left: 165px;
    //top: -60px;
    //margin-top: -50px;
    //margin-left: 120px;
    left: 0px;
    top: calc(178px * 0.8 * -1);
    display: none;
    z-index: 900;
    .home-index-product-content-right-qr_code-bgimg {
      position: absolute;
      //left: 5px;
      //top: 0px;
      left: 0px;
      top: 0px;
      img {
        width: 100%;
        height: 100%;
      }
      transform: rotate(270deg);
      /* IE 9 */
      -ms-transform:rotate(270deg);
       /* Firefox */
      -moz-transform:rotate(270deg);
      /* Safari 和 Chrome */
      -webkit-transform: rotate(270deg);
      /* Opera */
      -o-transform:rotate(270deg);
    }
    .home-index-product-content-right-qr_code-img {
      position: absolute;
      //right: 0px;
      //top: 5px;
      left: 11px;
      top: -2px;
      width: calc(150px * 0.8);
      height: calc(150px * 0.8);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.home-index-solution-pc {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  .home-index-solution-title {
    font-size: var(--v-font-size-32);
    font-weight: @fontWeight;
    text-align: @textAlign;
    margin: 100px auto 20px auto;
  }
  .home-index-solution-subtitle {
    margin-bottom: 50px;
    font-size: var(--v-font-size-18);
    font-weight: @fontWeight_sub;
    text-align: @textAlign_sub;
    color: @color_sub;
  }
  .home-index-solution-content {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    background: url(../../assets/images/Home/solution_bg.jpg) no-repeat 0px 0px;
    padding: 60px 0 60px 0;
    .home-index-solution-content-list {
      width: var(--v-width);
      overflow: hidden;
      margin: 0 auto;
      text-align: center;
      .home-index-solution-content-list-left {
        float: left;
        color: #59739b;
        font-size: 30px;
        font-weight: 500;
        color: #59739b;
        text-align: left;
        margin-left: 20px;
        ul {
          li {
            cursor: pointer;
            margin-bottom: 55px;
            .home-index-solution-content-list-left-image {
              width: 40px;
              height: 40px;
              margin-left: 20px;
              // display: none;
            }
          }
        }
      }
      .home-index-solution-content-list-right {
        position: relative;
        float: right;
        .home-index-solution-content-list-right-image {
          width: 801px;
          height: 569px;
          cursor: pointer;
        }
      }
    }
  }
  .solutionDesc {
    position: absolute;
    left: 100px;
    bottom: 60px;
    width: 600px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    line-height: 1.8;
    text-align: center;
    z-index: 2;
    cursor: pointer;
  }
}
.home-index-solution-mobile {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  .home-index-solution-title {
    font-size: var(--v-font-size-32);
    font-weight: @fontWeight;
    text-align: @textAlign;
    margin: 50px auto 10px auto;
  }
  .home-index-solution-subtitle {
    margin-bottom: 10px;
    font-size: var(--v-font-size-18);
    font-weight: @fontWeight_sub;
    text-align: @textAlign_sub;
    color: @color_sub;
  }
  .home-index-solution-content {
    width: 100%;
    text-align: center;
    margin: 0 auto;
    background: url(../../assets/images/Home/solution_bg.jpg) no-repeat 0px 0px;
    padding: 20px 0 0 0;
    .home-index-solution-content-list {
      width: var(--v-width);
      overflow: hidden;
      margin: 0 auto;
      text-align: center;
      .home-index-solution-content-list-left {
        /*
        // 横着显示
        float: left;
        color: #59739b;
        font-size: var(--v-font-size-32);
        font-weight: 500;
        color: #59739b;
        text-align: left;
        margin-left: 20px;
        ul {
          li {
            cursor: pointer;
            margin-bottom: 20px;
            .home-index-solution-content-list-left-image {
              width: 20px;
              height: 20px;
              margin-top: 2px;
              margin-left: 5px;
              // display: none;
            }
          }
        }
        */
       /*
        // 竖着显示
        color: #59739b;
        font-size: var(--v-font-size-32);
        font-weight: 500;
        color: #59739b;
        text-align: center;
        ul {
          display: inline-block;
          text-align: center;
          margin: 0 auto;
          li {
            float: left;
            width: 32px;
            cursor: pointer;
            margin:0px 2px 2px 10px;
            .home-index-solution-content-list-left-image {
              width: 20px;
              height: 20px;
              margin-top: 2px;
              margin-left: 0px;
              // display: none;
              transform: rotate(90deg);
              // IE 9
              -ms-transform:rotate(90deg);
              // Firefox
              -moz-transform:rotate(90deg);
              // Safari 和 Chrome
              -webkit-transform: rotate(90deg);
              // Opera
              -o-transform:rotate(90deg);
            }
          }
        }
        */
        float: left;
        color: #59739b;
        font-size: var(--v-font-size-27);
        font-weight: 500;
        color: #59739b;
        text-align: center;
        ul {
          margin: 0px auto;
          overflow: hidden;
          display: inline-block;
          li {
            float: left;
            flex: 1;
            width: calc(100% / 2);
            height: 30px;
            cursor: pointer;
            margin-bottom: 20px;
            .home-index-solution-content-list-left-image {
              width: 20px;
              height: 20px;
              margin-top: 2px;
              margin-left: 5px;
              display: none;
            }
          }
        }
      }
      .home-index-solution-content-list-right {
        position: relative;
        float: right;
        margin: 0 auto;
        .home-index-solution-content-list-right-image {
          width: 100%;
          cursor: pointer;
        }
      }
    }
  }
  .solutionDesc {
    position: absolute;
    width: calc(100% - 40px);
    padding: 0 20px;
    bottom: 20px;
    font-size: var(--v-font-size-14);
    font-weight: 500;
    color: #fff;
    line-height: 1.35;
    text-align: center;
    z-index: 2;
    cursor: pointer;
  }
}
.home-index-partner-pc {
  width: 100%;
  background-color: #f2f2f2;
  overflow: hidden;
  text-align: center;
  .home-index-partner-title {
    font-size: var(--v-font-size-32);
    font-weight: @fontWeight;
    text-align: @textAlign;
    padding: @padding;
  }
  .home-index-partner-subtitle {
    font-size: var(--v-font-size-18);
    font-weight: @fontWeight_sub;
    text-align: @textAlign_sub;
    color: @color_sub;
  }
  .home-index-partner-list {
    width: var(--v-width);
    margin: 0 auto;
    overflow: hidden;
    text-align: center;
    ul {
      margin: 50px 0 auto;
      overflow: hidden;
      display: inline-block;
      li {
        float: left;
        flex: 1;
        width: 252px;
        height: 95px;
        background-color: #fff;
        margin: 10px 15px;
        img {
          width: 100%;
        }
      }
    }
  }
  .home-index-partner-more {
    width: 123px;
    height: 36px;
    background: #ea6634;
    text-align: center;
    margin: 50px auto;
    color: #fff;
    line-height: 36px;
    font-size: 12px;
    cursor: pointer;
  }
}
.home-index-partner-mobile {
  width: 100%;
  background-color: #f2f2f2;
  overflow: hidden;
  text-align: center;
  .home-index-partner-title {
    font-size: var(--v-font-size-32);
    font-weight: @fontWeight;
    text-align: @textAlign;
    padding: 40px 0px 10px 0px;
  }
  .home-index-partner-subtitle {
    font-size: var(--v-font-size-18);
    font-weight: @fontWeight_sub;
    text-align: @textAlign_sub;
    color: @color_sub;
  }
  .home-index-partner-list {
    width: var(--v-width);
    margin: 0 auto;
    overflow: hidden;
    text-align: center;
    ul {
      margin: 20px auto 0px auto;
      overflow: hidden;
      display: inline-block;
      li {
        float: left;
        flex: 1;
        max-width: 252px;
        width: calc(100% / 2 - 20px);
        //height: 95px;
        background-color: #fff;
        margin: 5px 10px;
        img {
          width: 100%;
        }
      }
    }
  }
  .home-index-partner-more {
    width: 100px;
    height: 36px;
    background: #ea6634;
    text-align: center;
    margin: 20px auto;
    color: #fff;
    line-height: 36px;
    font-size: 12px;
    cursor: pointer;
  }
}
.home-index-news-pc {
  width: 100%;
  //height: 688px;
  position: relative;
  text-align: center;
  .home-index-news-top {
    width: 100%;
    height: 344px;
    .home-index-news-top-title {
      font-size: var(--v-font-size-32);
      font-weight: @fontWeight;
      text-align: @textAlign;
      padding: @padding;
    }
    .home-index-news-top-subtitle {
      font-size: var(--v-font-size-18);
      font-weight: @fontWeight_sub;
      text-align: @textAlign_sub;
      color: @color_sub;
    }
  }
  .home-index-news-list {
    width: var(--v-width);
    margin: 0 auto;
    overflow: hidden;
    position: absolute;
    top: 230px;
    left: calc(50% - 600px);
    text-align: center;
    z-index: 99;
    ul {
      display: inline-block;
      li {
        width: 370px;
        height: 330px;
        float: left;
        cursor: pointer;
        margin: 0px 12px;
        .home-index-news-list-image {
          width: 100%;
          height: 190px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.5s ease 0s;
          }
        }
        .home-index-news-list-text {
          width: calc(100% - 40px);
          height: 30px;
          background-color: #fff;
          font-size: 16px;
          font-weight: bold;
          color: #000000;
          padding: 10px 20px;
          line-height: 40px;
          // https://www.cnblogs.com/nangezi/p/9091158.html
          /* 对超出容器的部分强制截取，高度不确定则换行 */
          overflow: hidden;
          /* 显示省略符号来代表被修剪的文本 */
          text-overflow: ellipsis;
          /* 禁止换行 */
          white-space: nowrap;
        }
        .home-index-news-list-updated_at {
          width: calc(100% - 40px);
          height: 30px;
          background-color: #fff;
          font-size: 16px;
          font-weight: 500;
          color: #666666;
          padding: 10px 20px;
          line-height: 10px;
        }
        &:hover {
          .home-index-news-list-image {
            img {
              transform: scale(1.1);
              -ms-transform: scale(1.1); /* IE 9 */
              -moz-transform: scale(1.1); /* Firefox */
              -webkit-transform: scale(1.1); /* Safari 和 Chrome */
              -o-transform: scale(1.1);
              // height: 167px;
            }
          }
        }
      }
    }
  }
  .home-index-news-more {
    width: 100%;
    height: 324px;
    background-color: #000;
    position: relative;
    .home-index-news-more-content {
      width: var(--v-width);
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      .home-index-news-more-content-image {
        margin: 0 auto;
        position: absolute;
        bottom: 0px;
        left: 0px;
        overflow: hidden;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .home-index-news-more-button {
      position: absolute;
      bottom: 50px;
      left: calc(50% - 61px);
      z-index: 99;
      width: 122px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      color: #fff;
      background: #ea6634;
      cursor: pointer;
    }
  }
}
.home-index-news-mobile {
  width: calc(var(--v-width) - 20px);
  //height: 688px;
  position: relative;
  text-align: center;
  margin: 0px auto;
  .home-index-news-top {
    width: 100%;
    .home-index-news-top-title {
      font-size: var(--v-font-size-32);
      font-weight: @fontWeight;
      text-align: @textAlign;
      padding: 40px 0px 10px 0px;
    }
    .home-index-news-top-subtitle {
      font-size: var(--v-font-size-18);
      font-weight: @fontWeight_sub;
      text-align: @textAlign_sub;
      color: @color_sub;
    }
  }
  .home-index-news-list {
    width: 100%;
    margin: 10px auto;
    overflow: hidden;
    position: relative;
    z-index: 99;
    ul {
      li {
        width: 100%;
        cursor: pointer;
        margin: 5px 0px;
        .home-index-news-list-image {
          width: 100%;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            transition: all 0.5s ease 0s;
          }
        }
        .home-index-news-list-text {
          width: 100%;
          background-color: #fff;
          font-size: var(--v-font-size-18);
          font-weight: bold;
          color: #000000;
          padding: 10px 5px;
          line-height: 10px;
          // https://www.cnblogs.com/nangezi/p/9091158.html
          /* 对超出容器的部分强制截取，高度不确定则换行 */
          overflow: hidden;
          /* 显示省略符号来代表被修剪的文本 */
          text-overflow: ellipsis;
          /* 禁止换行 */
          white-space: nowrap;
        }
        .home-index-news-list-updated_at {
          width: 100%;
          background-color: #fff;
          font-size: var(--v-font-size-18);
          font-weight: 500;
          color: #666666;
          padding: 10px 10px;
          line-height: 8px;
        }
        &:hover {
          .home-index-news-list-image {
            img {
              transform: scale(1.1);
              -ms-transform: scale(1.1); /* IE 9 */
              -moz-transform: scale(1.1); /* Firefox */
              -webkit-transform: scale(1.1); /* Safari 和 Chrome */
              -o-transform: scale(1.1);
              // height: 167px;
            }
          }
        }
      }
      li:nth-child(1) {
        // box-shadow: 3px 3px 6px 6px rgba(123, 123, 123, 0.2);
        border: 1px dashed rgba(123, 123, 123, 0.2);
      }
    }
  }
  .home-index-news-more {
    width: 100%;
    height: 54px;
    position: relative;
    .home-index-news-more-content {
      display: none;
    }
    .home-index-news-more-button {
      z-index: 99;
      width: 100px;
      height: 36px;
      line-height: 36px;
      margin: 0 auto;
      text-align: center;
      color: #fff;
      background: #ea6634;
      cursor: pointer;
    }
  }
}
.home-index-contactus-pc {
  color: #333333;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  margin: 0 auto;
  .home-index-contactus-title {
    font-size: var(--v-font-size-32);
    font-weight: @fontWeight;
    text-align: @textAlign;
    padding: @padding;
  }
  .home-index-contactus-subtitle {
    font-size: var(--v-font-size-18);
    font-weight: @fontWeight_sub;
    text-align: @textAlign_sub;
    color: @color_sub;
  }
  .home-index-contactus-list {
    width: var(--v-width);
    margin: 50px auto 0 auto;
    overflow: hidden;
    padding-bottom: 100px;
    ul {
      li {
        float: left;
        width: calc(100%/4 - 3px);
        max-width: 297px;
        //width: 297px;
        height: 162px;
        text-align: center;
        margin: 0 auto;
        .home-index-contactus-list-image {
          width: 24px;
          height: 24px;
          text-align: center;
          margin: 0 auto;
          img {
            height: 100%;
          }
        }
        .home-index-contactus-list-site {
          font-size: 20px;
          font-weight: 600;
          text-align: center;
          margin: 5px auto 30px auto;
        }
        .home-index-contactus-list-address {
          width: 80%;
          margin: 0 auto;
          font-size: var(--v-font-size-14);
          font-weight: 400;
          text-align: center;
          color: #666666;
          line-height: 20px;
          // 自动换行
          word-wrap: break-word;
        }
      }
    }
  }
}
.home-index-contactus-mobile {
  color: #333333;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  margin: 0 auto;
  .home-index-contactus-title {
    font-size: var(--v-font-size-32);
    font-weight: @fontWeight;
    text-align: @textAlign;
    padding: 40px 0px 10px 0px;
  }
  .home-index-contactus-subtitle {
    font-size: var(--v-font-size-14);
    font-weight: @fontWeight_sub;
    text-align: @textAlign_sub;
    color: @color_sub;
  }
  .home-index-contactus-list {
    width: var(--v-width);
    margin: 20px auto 0 auto;
    overflow: hidden;
    padding-bottom: 40px;
    ul {
      li {
        float: left;
        width: calc(100%/4 - 3px);
        max-width: 297px;
        //width: 297px;
        height: 125px;
        text-align: center;
        margin: 0 auto;
        .home-index-contactus-list-image {
          width: 24px;
          height: 24px;
          text-align: center;
          margin: 0 auto;
          img {
            height: 100%;
          }
        }
        .home-index-contactus-list-site {
          font-size: var(--v-font-size-16);
          font-weight: 600;
          text-align: center;
          margin: 5px auto;
        }
        .home-index-contactus-list-address {
          width: 80%;
          margin: 0 auto;
          font-size: var(--v-font-size-14);
          // font-weight: 400;
          text-align: center;
          color: #666666;
          line-height: 18px;
          // 自动换行
          word-wrap: break-word;
        }
      }
    }
  }
}
.pitch-on {
  font-size: var(--v-font-size-32);
  color: #fff;
}
.home-index-solution-content-list-left-text:hover {
  color: #fff;
  cursor: pointer;
}
</style>
