// axios请求模块
import axios from 'axios'
const service = axios.create({
  baseURL: 'https://web-api.panxsoft.com',
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000 // request timeout
})
service.interceptors.request.use() // 请求拦截器
service.interceptors.response.use() // 响应拦截器
export default service
