<template>
  <div class="SearchListings">
    <div class="search">
      <div class="search-box">
        <div class="search-box-input">
          <input id="searchInput" type="text" placeholder="请输入要搜索的关键字" v-model="searchValue" />
        </div>
        <div class="search-box-button" @click="onSearchClick"> <img src="../../assets/images/Home/search.png" /> </div>
      </div>
    </div>
    <div class="search-list">
      <div class="search-list-box">
        <ul>
          <li @click="onSwitchSearchTypeClick(1)" :style="searchSwitchCase == 1 ? 'color:#EA6634' : 'color:#000'">
            新闻（<span v-if="newSearchData">{{ newSearchData.total }}</span><span v-else>0</span>）
          </li>
          <li @click="onSwitchSearchTypeClick(2)" :style="searchSwitchCase == 2 ? 'color:#EA6634' : 'color:#000'">
            产品（<span v-if="productSearchData">{{ productSearchData.total }}</span><span v-else>0</span>）
          </li>
          <li @click="onSwitchSearchTypeClick(3)" :style="searchSwitchCase == 3 ? 'color:#EA6634' : 'color:#000'">
            案例（<span v-if="caseSearchData">{{ caseSearchData.total }}</span><span v-else>0</span>）
          </li>
          <li @click="onSwitchSearchTypeClick(4)" :style="searchSwitchCase == 4 ? 'color:#EA6634' : 'color:#000'">
            视频（<span v-if="videoSearchData">{{ videoSearchData.total }}</span><span v-else>0</span>）
          </li>
        </ul>
      </div>
    </div>
    <div class="search-center">
      <div class="search-center-box">
        <div class="search-center-box-list">
          <ul>
            <li v-if="searchSwitchCase==1">
              <div v-if="newSearchData">
                <div class="NewsTrends-listView-box">
                  <ul>
                    <li v-for="(item, index) in newSearchData.data" :key="index" @click.stop="onNewsDetailClick(item)">
                      <div class="NewsTrends-listView-box-left">
                        <img :src="item.thumb" />
                      </div>
                      <div class="NewsTrends-listView-box-right">
                        <div class="NewsTrends-listView-box-right-title">{{ item.title }}</div>
                        <div class="NewsTrends-listView-box-right-center">{{ item.description }}</div>
                        <div class="NewsTrends-listView-box-right-antistop">
                          <div v-for="(keyword_item, keyword_index) in item.keywords" :key="keyword_index" class="antistop-keyword">
                            {{ keyword_item.name }}
                          </div>
                          <div class="antistop-right">{{ item.updated_at | formatDate }}</div>
                        </div>
                      </div>
                      <div class="NewsTrends-listView-box-hr"></div>
                    </li>
                  </ul>
                </div>
                <div v-if="newSearchData.total > 0" class="search-content-pagelist">
                  <template>
                    <PageControl
                      :pageIndex="localForm.page"
                      :pageSize="localForm.per_page"
                      :pageCount="newSearchData.total"
                      @change="onSearchPageChanged"
                    >
                    </PageControl>
                  </template>
                </div>
              </div>
              <div v-else>
                <NullPage />
              </div>
            </li>
            <li v-else-if="searchSwitchCase==2">
              <div v-if="productSearchData">
                <div class="productCenterList-box">
                  <ul>
                    <li v-for="(item, index) in productSearchData.data" :key="index">
                      <div class="productCenterList-box-img">
                        <img :src="item.thumb" />
                      </div>
                      <div class="productCenterList-box-botton">
                        <div class="productCenterList-box-title">
                          {{ item.name }}
                          <div class="productCenterList-box-title-left"></div>
                          <div class="productCenterList-box-title-right"></div>
                        </div>
                        <div class="productCenterList-box-botton-content">
                          {{ item.brief }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div v-if="productSearchData.total > 0" class="search-content-pagelist">
                  <template>
                    <PageControl
                      :pageIndex="localForm.page"
                      :pageSize="localForm.per_page"
                      :pageCount="productSearchData.total"
                      @change="onSearchPageChanged"
                    >
                    </PageControl>
                  </template>
                </div>
              </div>
              <div v-else>
                <NullPage />
              </div>
            </li>
            <li v-else-if="searchSwitchCase==3">
              <div v-if="caseSearchData">
                <div class="collaborateCase-video-box">
                  <ul>
                    <li v-for="(item, index) in caseSearchData.data" :key="index">
                      <div class="collaborateCase-video-box-img">
                        <img :src="item.thumb" />
                      </div>
                      <div class="collaborateCase-video-box-botton">
                        <div class="collaborateCase-video-box-botton-left">
                          <img :src="item.logo" />
                        </div>
                        <div class="collaborateCase-video-box-botton-right">
                          <div class="collaborateCase-video-box-botton-title">
                            {{ item.title }}
                          </div>
                          <div class="collaborateCase-video-box-botton-content">
                            {{ item.description }}
                          </div>
                          <div v-if="item.keywords && item.keywords.length > 0" class="collaborateCase-video-box-botton-list">
                            <div class="collaborateCase-video-box-botton-list-ul">
                              <div v-for="(itemValue, index) in item.keywords" :key="index" :class="'collaborateCase-video-box-botton-list-li '  + (index < item.keywords.length - 1 ? 'collaborateCase-video-box-botton-list-li-line' : '')">
                                {{ itemValue.name }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div v-if="caseSearchData.total > 0" class="search-content-pagelist">
                  <template>
                    <PageControl
                      :pageIndex="localForm.page"
                      :pageSize="localForm.per_page"
                      :pageCount="caseSearchData.total"
                      @change="onSearchPageChanged"
                    >
                    </PageControl>
                  </template>
                </div>
              </div>
              <div v-else>
                <NullPage />
              </div>
            </li>
            <li v-else-if="searchSwitchCase==4" >
              <div v-if="videoSearchData">
                <div class="VideoPrefectureComponent-video-box">
                  <ul>
                    <li v-for="(item, index) in videoSearchData.data" :key="index">
                      <div class="VideoPrefectureComponent-video-box-img">
                        <div :id="'videoMask-' + item['id']" class="video-content-videolist-upper-placeholder" @click.stop="onVideoPlayClick(item)"></div>
                        <video :id="'videoId-' + item['id']"
                          width="373"
                          height="216"
                          :src="item['url']"
                          controls
                          controlslist="nodownload noremoteplayback"
                          :disablePictureInPicture="true"
                          :poster="item.videoCover"
                          @click.stop="onVideoPlayClick(item)"
                        >
                        </video>
                        <div :id="'videoPreview-' + item['id']" class="video-content-videolist-upper-preview"><img :src="item['preview_img']" /> </div>
                        <!-- <div class="video-content-videolist-upper-play" @click.stop="onVideoPlayClick(item)"></div> -->
                      </div>
                      <div class="VideoPrefectureComponent-video-box-botton">
                        <div class="VideoPrefectureComponent-video-box-botton-title">
                          {{ item.name }}
                        </div>
                        <div class="VideoPrefectureComponent-video-box-botton-content">
                          {{ item.updated_at | formatDate }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div v-if="videoSearchData.total > 0" class="search-content-pagelist">
                  <template>
                    <PageControl
                      :pageIndex="localForm.page"
                      :pageSize="localForm.per_page"
                      :pageCount="videoSearchData.total"
                      @change="onSearchPageChanged"
                    >
                    </PageControl>
                  </template>
                </div>
              </div>
              <div v-else>
                <NullPage />
              </div>
            </li>
            <li v-else>
              <NullPage />
            </li>
         </ul>
       </div>
     </div>
   </div>
  </div>
</template>

<script>
import { reqHomeSearchResults } from '@/api/apireq'
import NullPage from '@/components/NullPage'
import PageControl from '@/components/PageControl'
export default {
  /* 搜索 */
  name: 'Search',
  components: {
    NullPage,
    PageControl
  },
  metaInfo () {
    return {
      title: this.headMetaInfo.title,
      meta: [
        { name: 'keywords', content: this.headMetaInfo.keywords },
        { name: 'discription', content: this.headMetaInfo.content }
      ]
    }
  },
  data () {
    return {
      headMetaInfo: {
        title: '广州帕克西软件开发有限公司',
        keywords: '',
        content: ''
      },
      searchValue: '',
      searchSwitchCase: 1,
      localResults: null,
      newSearchData: null,
      productSearchData: null,
      caseSearchData: null,
      videoSearchData: null,
      localForm: {
        search: '',
        per_page: 9,
        page: 1
      },
      /* 当前播放的视频 */
      currentVideoPlaying: null
    }
  },
  watch: {
    $route () {
      this.onSearchValue()
    }
  },
  created () { },
  mounted () {
    this.headMetaInfo.title = this.$global.head_title
    this.headMetaInfo.keywords = this.$global.head_keywords
    this.headMetaInfo.content = this.$global.head_description
    this.onSearchValue()
  },
  methods: {
    onSearchValue: function () {
      var search = localStorage.getItem('SearchValue')
      if (search) {
        this.searchValue = search
      } else {
        // this.searchValue = '魔妆镜'
        return
      }
      this.onReqSearch()
    },
    onSearchClick: function () {
      if ((this.searchValue !== '') && (this.searchValue.replace(/\s*/g, '') !== '')) {
        localStorage.setItem('SearchValue', this.searchValue)
      }
      this.onReqSearch()
    },
    onReqSearch: function () {
      this.localResults = null
      this.newSearchData = null
      this.productSearchData = null
      this.caseSearchData = null
      this.videoSearchData = null
      this.localForm = {
        search: '',
        per_page: 9,
        page: 1
      }
      this.onSearchService(this.searchValue)
    },
    onSearchService: function (val) {
      /* 检查当前视频是否正在播放，如果正在播放，则停止播放 */
      if (this.currentVideoPlaying) {
        const videoId = document.getElementById('videoId-' + this.currentVideoPlaying.id)
        const videoMask = document.getElementById('videoMask-' + this.currentVideoPlaying.id)
        const videoPreview = document.getElementById('videoPreview-' + this.currentVideoPlaying.id)
        if (videoId) {
          videoId.pause()
          videoId.removeEventListener('ended', this.onVideoPlayEnded)
        }
        if (videoMask) { videoMask.style.display = 'block' }
        if (videoPreview) { videoPreview.style.display = 'block' }
      }
      this.currentVideoPlaying = null
      /* 请求服务器搜索数据 */
      if ((val !== '') && (val.replace(/\s*/g, '') !== '')) {
        this.localForm.search = val
        reqHomeSearchResults(this.localForm).then((res) => {
          try {
            // console.log('onSearchService', res.data)
            if (res.data) {
              if (res.data.code === 200) {
                // TODO handle the exception
                this.localResults = res.data
                var headKeywords = []
                var i = 0
                var j = 0
                if (this.localResults.news) {
                  this.newSearchData = this.localResults.news
                  // 处理 meta[name="keywords"]
                  if (this.newSearchData.data && this.newSearchData.data.length > 0) {
                    for (i = 0; i < this.newSearchData.data.length; i++) {
                      // console.log(this.newSearchData.data[i].keywords)
                      const newSearchDataKeywords = this.newSearchData.data[i].keywords
                      if (newSearchDataKeywords && newSearchDataKeywords.length > 0) {
                        for (j = 0; j < newSearchDataKeywords.length; j++) {
                          if (newSearchDataKeywords[j] && newSearchDataKeywords[j].name) {
                            if (headKeywords.indexOf(newSearchDataKeywords[j].name) === -1) {
                              headKeywords.push(newSearchDataKeywords[j].name)
                            }
                          }
                        }
                      }
                    }
                    if (headKeywords !== null && headKeywords.length > 0) {
                      this.$global.onUpdateHeadKeywords(headKeywords)
                      this.headMetaInfo.keywords = this.$global.getHeadKeywords(headKeywords)
                    }
                  }
                }
                if (this.localResults.products) {
                  this.productSearchData = this.localResults.products
                }
                if (this.localResults.cases) {
                  this.caseSearchData = this.localResults.cases
                  // 处理 meta[name="keywords"]
                  if (this.caseSearchData.data && this.caseSearchData.data.length > 0) {
                    for (i = 0; i < this.caseSearchData.data.length; i++) {
                      // console.log(this.caseSearchData.data[i].keywords)
                      const caseSearchDataKeywords = this.caseSearchData.data[i].keywords
                      if (caseSearchDataKeywords && caseSearchDataKeywords.length > 0) {
                        for (j = 0; j < caseSearchDataKeywords.length; j++) {
                          if (caseSearchDataKeywords[j] && caseSearchDataKeywords[j].name) {
                            if (headKeywords.indexOf(caseSearchDataKeywords[j].name) === -1) {
                              headKeywords.push(caseSearchDataKeywords[j].name)
                            }
                          }
                        }
                      }
                    }
                    if (headKeywords !== null && headKeywords.length > 0) {
                      this.$global.onUpdateHeadKeywords(headKeywords)
                      this.headMetaInfo.keywords = this.$global.getHeadKeywords(headKeywords)
                    }
                  }
                }
                if (this.localResults.videos) {
                  this.videoSearchData = this.localResults.videos
                }
                // console.log('newSearchData', this.newSearchData)
                // console.log('productSearchData', this.productSearchData)
                // console.log('caseSearchData', this.caseSearchData)
                // console.log('videoSearchData', this.videoSearchData)
              }
            }
          } catch (e) {
            // TODO handle the exception
          }
        })
      }
    },
    onSwitchSearchTypeClick: function (id) {
      this.searchSwitchCase = id
      this.localForm.page = 1
      // console.log(this.localForm)
      this.onSearchService(this.localForm.search)
    },
    /* 请求加载切换页面的内容 */
    onSearchPageChanged: function (page) {
      this.localForm.page = page
      this.onSearchService(this.localForm.search)
    },
    onNewsDetailClick: function (item) {
      // console.log(item)
      // this.$router.push('/news-details?id=' + item.id)
      this.$router.push('/news-details/' + item.id)
    },
    onVideoPlayClick: function (item) {
      /* if (video.paused) { */
      if (this.currentVideoPlaying) {
        const currentVideo = document.getElementById('videoId-' + this.currentVideoPlaying.id)
        if (currentVideo) { currentVideo.pause() }
        if (this.currentVideoPlaying.id === item.id) {
          this.onVideoPlayEnded()
          return
        }
      }
      this.onVideoPlayEnded()
      this.currentVideoPlaying = item
      const videoId = document.getElementById('videoId-' + this.currentVideoPlaying.id)
      const videoMask = document.getElementById('videoMask-' + this.currentVideoPlaying.id)
      const videoPreview = document.getElementById('videoPreview-' + this.currentVideoPlaying.id)
      if (videoId) {
        videoId.addEventListener('ended', this.onVideoPlayEnded)
        videoId.play()
      }
      if (videoMask) { videoMask.style.display = 'none' }
      if (videoPreview) { videoPreview.style.display = 'none' }
    },
    /* 当前视频播放结束 */
    onVideoPlayEnded: function () {
      if (this.currentVideoPlaying) {
        const videoId = document.getElementById('videoId-' + this.currentVideoPlaying.id)
        const videoMask = document.getElementById('videoMask-' + this.currentVideoPlaying.id)
        const videoPreview = document.getElementById('videoPreview-' + this.currentVideoPlaying.id)
        if (videoId) { videoId.removeEventListener('ended', this.onVideoPlayEnded) }
        if (videoMask) { videoMask.style.display = 'block' }
        if (videoPreview) { videoPreview.style.display = 'block' }
      }
      this.currentVideoPlaying = null
    }
  }
}
</script>

<style lang="less" scoped>
@padding:80px 0px;
@title: 32px;
@fontWeight: 700;
@listpad: 45px;
.SearchListings {
  width: 100%;
  overflow: hidden;
  text-align: center;
  color: #333;
  background-color: #F8F8F8;
  .search {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .search-box {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 80px 0 50px 0;
      .search-box-input {
        width: 1080px;
        height: 53px;
        float: left;
        input {
          width: 100%;
          height: 38px;
          background: #ffffff;
          border: 1px solid #d1d1d1;
          text-indent: 20px;
        }
      }
      .search-box-button {
        float: left;
        width: 40px;
        height: 40px;
        background-color: #EA6634;
        line-height: 40px;
        text-align: center;
        margin: 0 auto;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
          line-height: 40px;
          text-align: center;
          margin-top: 10px;
        }
      }
    }
  }
  .search-list {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .search-list-box {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 0 0 20px 0;
      ul {
        li {
          cursor: pointer;
          float: left;
          margin-right: 30px;
          font-size: 14px;
          font-weight: 700;
          text-align: left;
        }
      }
    }
  }
  .search-center {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .search-center-box {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 0 0 80px 0;
      .search-center-box-list {
        width: 100%;
        ul {
          width: 100%;
          display: inline-block;
          li {
            .NewsTrends-listView-box {
              padding-top: @listpad;
              ul {
                display: inline-block;
                li:nth-child(1) {
                  padding: 0 0 45px 0 !important;
                }
                li {
                  overflow: hidden;
                  padding: 45px 0;
                  position: relative;
                  cursor: pointer;
                  .NewsTrends-listView-box-left {
                    float: left;
                    width: 370px;
                    height: 171px;
                    img {
                      height: 100%;
                    }
                  }
                  .NewsTrends-listView-box-right {
                    width: calc(100% - 370px - 50px);
                    height: 171px;
                    float: left;
                    padding-left: 25px;
                    .NewsTrends-listView-box-right-title {
                      font-size: 20px;
                      font-weight: bold;
                      text-align: left;
                      margin-top: 20px;
                    }
                    .NewsTrends-listView-box-right-center {
                      font-size: 14px;
                      text-align: left;
                      line-height: 20px;
                      margin: 20px 0;
                      height: 72px;
                      // https://www.cnblogs.com/nangezi/p/9091158.html
                      /* 对超出容器的部分强制截取，高度不确定则换行 */
                      overflow: hidden;
                      /* 显示省略符号来代表被修剪的文本 */
                      text-overflow: ellipsis;
                      /* 禁止换行 */
                      // white-space: nowrap;
                    }
                    .NewsTrends-listView-box-right-antistop {
                      .antistop-keyword {
                        float: left;
                        width: 57px;
                        height: 24px;
                        font-size: 14px;
                        text-align: center;
                        color: #707070;
                        background: #ebebeb;
                        border-radius: 2px;
                        line-height: 24px;
                        font-weight: bold;
                        margin-right: 20px;
                      }
                      .antistop-right {
                        float: right;
                        font-size: 14px;
                        color: #666666;
                        text-align: right;
                      }
                    }
                  }
                  .NewsTrends-listView-box-hr {
                    position: absolute;
                    width: 95%;
                    height: 1px;
                    background-color: #d1d1d1;
                    bottom: 0px;
                    left: 2.5%;
                  }
                }
              }
            }
            .productCenterList-box {
              padding-top: @listpad;
              ul {
                display: inline-block;
                li:nth-child(3n+2) {
                  margin: 0px 40px;
                }
                li {
                  float: left;
                  text-align: center;
                  padding-bottom: 30px;
                  .productCenterList-box-img {
                    width: 346px;
                    height: 216px;
                    img {
                      height: 100%;
                    }
                  }
                  .productCenterList-box-botton {
                    width: 346px;
                    height: 76px;
                    .productCenterList-box-title {
                      height: 17px;
                      font-size: 16px;
                      font-weight: bold;
                      margin: 15px auto;
                      display: inline-block;
                      position: relative;
                      .productCenterList-box-title-left {
                        width: 10px;
                        height: 4px;
                        background-color: #EA6634;
                        position: absolute;
                        top: 6.5px;
                        left: -20px;
                      }
                      .productCenterList-box-title-right {
                        width: 10px;
                        height: 4px;
                        background-color: #EA6634;
                        position: absolute;
                        top: 6.5px;
                        right: -20px;
                      }
                    }
                    .productCenterList-box-botton-content {
                      color: #666666;
                      font-size: 14px;
                    }
                  }
                }
              }
            }
            .collaborateCase-video-box {
              padding-top: @listpad;
              ul {
                height: auto;
                display: inline-block;
                  li:nth-child(3n+2) {
                  margin: 0px 40px;
                 }
                li {
                  float: left;
                  margin-top: 50px !important;
                  text-align: center;
                  padding-bottom: 16px;
                  box-shadow: 6px 13px 33px 1px rgba(0, 0, 0, 0.2);
                  .collaborateCase-video-box-img {
                    width: 373px;
                    height: 216px;
                    img {
                      width: 100%;
                      height: 100%;
                    }
                  }
                  .collaborateCase-video-box-botton {
                    width: calc(373px - 48px);
                    height: 101px;
                    padding:24px;
                    .collaborateCase-video-box-botton-left {
                      float: left;
                      width: 56px;
                      height: 100%;
                    }
                    .collaborateCase-video-box-botton-right {
                      width: calc(100% - 56px - 24px);
                      margin-left: 24px;
                      float: left;
                      .collaborateCase-video-box-botton-title {
                        height: 30px;
                        font-size: 20px;
                        font-weight: bold;
                        text-align: left;
                        // https://www.cnblogs.com/nangezi/p/9091158.html
                        /* 对超出容器的部分强制截取，高度不确定则换行 */
                        overflow: hidden;
                        /* 显示省略符号来代表被修剪的文本 */
                        text-overflow: ellipsis;
                        /* 禁止换行 */
                        white-space: nowrap;
                      }
                      .collaborateCase-video-box-botton-content {
                        height: 40px;
                        font-size: 16px;
                        text-align: left;
                        margin: 20px 0;
                        // https://www.cnblogs.com/nangezi/p/9091158.html
                        /* 对超出容器的部分强制截取，高度不确定则换行 */
                        overflow: hidden;
                        /* 显示省略符号来代表被修剪的文本 */
                        text-overflow: ellipsis;
                        /* 禁止换行 */
                        // white-space: nowrap;
                      }
                      .collaborateCase-video-box-botton-list {
                        height: 100%;
                        .collaborateCase-video-box-botton-list-ul {
                          overflow: hidden;
                          float: left;
                          .collaborateCase-video-box-botton-list-li {
                             float: left;
                             font-size: 14px;
                             text-align: left;
                             color: #666666;
                             height: 22px;
                             // padding: 0 10px;
                             line-height: 22px;
                             text-align: center;
                             padding-left: 10px;
                             padding-right: 10px;
                          }
                          .collaborateCase-video-box-botton-list-li-line {
                            border-right: 1px solid #d1d1d1;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .VideoPrefectureComponent-video-box {
              width: var(--v-width);
              margin: 0 auto;
              overflow: hidden;
              ul {
                overflow: hidden;
                padding-bottom: 80px;
                li:nth-child(3n+2) {
                  margin: 0px 40px;
                }
                li {
                  float: left;
                  margin-top: 50px !important;
                  text-align: center;
                  padding-bottom: 16px;
                  box-shadow: 6px 13px 33px 1px rgba(0, 0, 0, 0.2);
                  .VideoPrefectureComponent-video-box-img {
                    width: 373px;
                    height: 216px;
                    position: relative;
                    cursor: pointer;
                    .video-content-videolist-upper-placeholder {
                      z-index: 99;
                      // display: none;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      width: 100px;
                      height: 100px;
                      margin: -50px 0 0 -50px;
                      background: url(../../assets/images/ProductCenter/bigplay.svg) no-repeat;
                    }
                    .video-content-videolist-upper-placeholder:hover {
                      background-position-y: -100px;
                    }
                    .video-content-videolist-upper-preview{
                      width: 100%;
                      height: 100%;
                      position: absolute;
                      display: block;
                      z-index: 12;
                      top: 0px;
                      left: 0px;
                      img {
                        vertical-align: top;
                        max-width: 100%;
                        height: 100%;
                      }
                    }
                    .video-content-videolist-upper-play {
                      width: 100%;
                      height: 100%;
                      position: absolute;
                      z-index: 19;
                      top: 0px;
                      left: 0px;
                    }
                  }
                  .VideoPrefectureComponent-video-box-botton{
                    width: calc(373px - 20px);
                    height: 42px;
                    padding: 5px 10px;
                    .VideoPrefectureComponent-video-box-botton-title {
                      font-size: 16px;
                      font-weight: bold;
                      text-align: left;
                      color: #333333;
                      height: 32px;
                      line-height: 30px;
                      // https://www.cnblogs.com/nangezi/p/9091158.html
                      /* 对超出容器的部分强制截取，高度不确定则换行 */
                      overflow: hidden;
                      /* 显示省略符号来代表被修剪的文本 */
                      text-overflow: ellipsis;
                      /* 禁止换行 */
                      white-space: nowrap;
                    }
                    .VideoPrefectureComponent-video-box-botton-content {
                      font-size: 14px;
                      text-align: right;
                      color: #666666;
                      margin-top: 5px;
                    }
                   }
                 }
               }
            }
            .search-content-pagelist {
              margin: 50px auto 0 auto;
              text-align: center;
              ul {
                width: auto;
              }
            }
          }
        }
      }
    }
  }
}
/* 全屏按钮 */
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
/* 播放按钮 */
video::-webkit-media-controls-play-button {
  display: none;
}
/* 进度条 */
video::-webkit-media-controls-timeline {
  display: none;
}
/* 观看的当前时间 */
video::-webkit-media-controls-current-time-display{
  display: none;
}
/* 剩余时间 */
video::-webkit-media-controls-time-remaining-display {
  display: none;
}
/* 音量按钮 */
video::-webkit-media-controls-mute-button {
  display: none;
}
video::-webkit-media-controls-toggle-closed-captions-button {
  display: none;
}
/* 音量的控制条 */
video::-webkit-media-controls-volume-slider {
  display: none;
}
/* 所有控件 */
video::-webkit-media-controls-enclosure{
  display: none;
}
</style>
