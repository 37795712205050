import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'/* Header and Footer */
import HomeIndex from '@/views/HomeIndex'/* 首页 */
import Search from '@/views/Search'/* 搜索 */
import Technology from '@/views/Technology'/* 核心技术 */
import Solution from '@/views/Solution'/* 解决方案 */
import Product from '@/views/Product'/* 产品中心 */
import ProductList from '@/views/ProductList'
import Video from '@/views/Video'/* 视频专区 */
import CollaborateCase from '@/views/CollaborateCase'/* 合作案例 */
import News from '@/views/News'/* 新闻动态 */
import AboutUs from '@/views/AboutUs'/* 关于我们 */
import Recruitment from '@/views/Recruitment'/* 加入我们 */
import NewsDetails from '@/views/NewsDetails'
import Disclaimer from '@/views/Disclaimer'
import PrivacyPolicy from '@/views/PrivacyPolicy'

Vue.use(VueRouter)
// const originalPush = VueRouter.prototype.push
// // 修改原型对象中的push方法
// VueRouter.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err)
// }
/*
不加这几行代码的话会报错：NavigationDuplicated: Avoided redundant navigation to current location: “/“
*/
const originalPush = VueRouter.prototype.push
// 修改原型对象中的push方法
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// 传参的方式：https://zhuanlan.zhihu.com/p/435210213

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      {
        path: '/',
        name: '首页',
        component: HomeIndex,
        /* https://www.cnblogs.com/lijinwen/p/5699443.html */
        meta: {
          title: '魔妆镜-AR试妆-AI测肤染发-人脸检测-广州帕克西软件开发有限公司',
          content: {
            keywords: 'AR试妆,AI测肤,AR染发,人脸检测,AR开发公司,AR互动营销,AR眼镜试戴,智能魔镜,人脸老化',
            description: '广州帕克西软件开发有限公司是一家专注于用户形体识别,追踪,数据采集,管理与服务的高新技术企业,依托自主研发的AR试妆,AR眼镜试戴,AI测肤,AR染发,人脸老化,AR互动营销,智能魔镜等技术和产品，为各行业提供线上线下数字新零售解决方案。'
          }
        }
      },
      {
        path: '/search',
        name: '搜索',
        component: Search,
        meta: {
          title: '魔妆镜-AR试妆-AI测肤染发-人脸检测-广州帕克西软件开发有限公司',
          content: {
            keywords: 'AR试妆,AI测肤,AR染发,人脸检测,AR开发公司,AR互动营销,AR眼镜试戴,智能魔镜,人脸老化',
            description: '广州帕克西软件开发有限公司是一家专注于用户形体识别,追踪,数据采集,管理与服务的高新技术企业,依托自主研发的AR试妆,AR眼镜试戴,AI测肤,AR染发,人脸老化,AR互动营销,智能魔镜等技术和产品，为各行业提供线上线下数字新零售解决方案。'
          }
        }
      },
      {
        path: '/technology/:id',
        name: '核心技术',
        component: Technology,
        meta: {
          title: '核心技术-广州帕克西软件开发有限公司',
          content: {
            keywords: 'AR试妆,AI测肤,AR染发,人脸检测,AR开发公司,AR互动营销,AR眼镜试戴,智能魔镜,人脸老化',
            description: '广州帕克西软件开发有限公司是一家专注于用户形体识别,追踪,数据采集,管理与服务的高新技术企业,依托自主研发的AR试妆,AR眼镜试戴,AI测肤,AR染发,人脸老化,AR互动营销,智能魔镜等技术和产品，为各行业提供线上线下数字新零售解决方案。'
          }
        }
      },
      {
        path: '/solution/:id',
        name: '解决方案',
        component: Solution,
        meta: {
          title: '解决方案-广州帕克西软件开发有限公司',
          content: {
            keywords: 'AR试妆,AI测肤,AR染发,人脸检测,AR开发公司,AR互动营销,AR眼镜试戴,智能魔镜,人脸老化',
            description: '广州帕克西软件开发有限公司是一家专注于用户形体识别,追踪,数据采集,管理与服务的高新技术企业,依托自主研发的AR试妆,AR眼镜试戴,AI测肤,AR染发,人脸老化,AR互动营销,智能魔镜等技术和产品，为各行业提供线上线下数字新零售解决方案。'
          }
        }
      },
      {
        path: '/product/:id',
        name: '产品中心',
        component: Product,
        meta: {
          title: '产品中心-广州帕克西软件开发有限公司',
          content: {
            keywords: 'AR试妆,AI测肤,AR染发,人脸检测,AR开发公司,AR互动营销,AR眼镜试戴,智能魔镜,人脸老化',
            description: '广州帕克西软件开发有限公司是一家专注于用户形体识别,追踪,数据采集,管理与服务的高新技术企业,依托自主研发的AR试妆,AR眼镜试戴,AI测肤,AR染发,人脸老化,AR互动营销,智能魔镜等技术和产品，为各行业提供线上线下数字新零售解决方案。'
          }
        }
      },
      {
        path: '/product-list',
        name: '产品列表',
        component: ProductList,
        meta: {
          title: '产品详情-广州帕克西软件开发有限公司',
          content: {
            keywords: 'AR试妆,AI测肤,AR染发,人脸检测,AR开发公司,AR互动营销,AR眼镜试戴,智能魔镜,人脸老化',
            description: '广州帕克西软件开发有限公司是一家专注于用户形体识别,追踪,数据采集,管理与服务的高新技术企业,依托自主研发的AR试妆,AR眼镜试戴,AI测肤,AR染发,人脸老化,AR互动营销,智能魔镜等技术和产品，为各行业提供线上线下数字新零售解决方案。'
          }
        }
      },
      {
        path: '/video',
        name: '视频专区',
        component: Video,
        meta: {
          title: '视频专区-广州帕克西软件开发有限公司',
          content: {
            keywords: 'AR试妆,AI测肤,AR染发,人脸检测,AR开发公司,AR互动营销,AR眼镜试戴,智能魔镜,人脸老化',
            description: '广州帕克西软件开发有限公司是一家专注于用户形体识别,追踪,数据采集,管理与服务的高新技术企业,依托自主研发的AR试妆,AR眼镜试戴,AI测肤,AR染发,人脸老化,AR互动营销,智能魔镜等技术和产品，为各行业提供线上线下数字新零售解决方案。'
          }
        }
      },
      {
        path: '/collaborate-case',
        name: '合作案例',
        component: CollaborateCase,
        meta: {
          title: '合作案例-广州帕克西软件开发有限公司',
          content: {
            keywords: 'AR试妆,AI测肤,AR染发,人脸检测,AR开发公司,AR互动营销,AR眼镜试戴,智能魔镜,人脸老化',
            description: '广州帕克西软件开发有限公司是一家专注于用户形体识别,追踪,数据采集,管理与服务的高新技术企业,依托自主研发的AR试妆,AR眼镜试戴,AI测肤,AR染发,人脸老化,AR互动营销,智能魔镜等技术和产品，为各行业提供线上线下数字新零售解决方案。'
          }
        }
      },
      {
        path: '/news',
        name: '新闻动态',
        component: News,
        meta: {
          title: '新闻动态-广州帕克西软件开发有限公司',
          content: {
            keywords: 'AR试妆,AI测肤,AR染发,人脸检测,AR开发公司,AR互动营销,AR眼镜试戴,智能魔镜,人脸老化',
            description: '广州帕克西软件开发有限公司是一家专注于用户形体识别,追踪,数据采集,管理与服务的高新技术企业,依托自主研发的AR试妆,AR眼镜试戴,AI测肤,AR染发,人脸老化,AR互动营销,智能魔镜等技术和产品，为各行业提供线上线下数字新零售解决方案。'
          }
        }
      },
      {
        path: '/about-us/:id',
        name: '关于我们',
        component: AboutUs,
        meta: {
          title: '关于我们-广州帕克西软件开发有限公司',
          content: {
            keywords: 'AR试妆,AI测肤,AR染发,人脸检测,AR开发公司,AR互动营销,AR眼镜试戴,智能魔镜,人脸老化',
            description: '广州帕克西软件开发有限公司是一家专注于用户形体识别,追踪,数据采集,管理与服务的高新技术企业,依托自主研发的AR试妆,AR眼镜试戴,AI测肤,AR染发,人脸老化,AR互动营销,智能魔镜等技术和产品，为各行业提供线上线下数字新零售解决方案。'
          }
        }
      },
      {
        path: '/recruitment/:id',
        name: '加入我们',
        component: Recruitment,
        meta: {
          title: '加入我们-广州帕克西软件开发有限公司',
          content: {
            keywords: 'AR试妆,AI测肤,AR染发,人脸检测,AR开发公司,AR互动营销,AR眼镜试戴,智能魔镜,人脸老化',
            description: '广州帕克西软件开发有限公司是一家专注于用户形体识别,追踪,数据采集,管理与服务的高新技术企业,依托自主研发的AR试妆,AR眼镜试戴,AI测肤,AR染发,人脸老化,AR互动营销,智能魔镜等技术和产品，为各行业提供线上线下数字新零售解决方案。'
          }
        }
      },
      {
        path: '/news-details/:id',
        name: '新闻详情',
        component: NewsDetails,
        meta: {
          title: '新闻详情-广州帕克西软件开发有限公司',
          content: {
            keywords: 'AR试妆,AI测肤,AR染发,人脸检测,AR开发公司,AR互动营销,AR眼镜试戴,智能魔镜,人脸老化',
            description: '广州帕克西软件开发有限公司是一家专注于用户形体识别,追踪,数据采集,管理与服务的高新技术企业,依托自主研发的AR试妆,AR眼镜试戴,AI测肤,AR染发,人脸老化,AR互动营销,智能魔镜等技术和产品，为各行业提供线上线下数字新零售解决方案。'
          }
        }
      },
      {
        path: '/disclaimer',
        name: '免责声明',
        component: Disclaimer,
        meta: {
          title: '免责声明-广州帕克西软件开发有限公司',
          content: {
            keywords: '',
            description: ''
          }
        }
      },
      {
        path: '/privacy_policy',
        name: '隐私政策',
        component: PrivacyPolicy,
        meta: {
          title: '隐私政策-广州帕克西软件开发有限公司',
          content: {
            keywords: '',
            description: ''
          }
        }
      }
    ]
  }

]

const router = new VueRouter({
  // https://www.cnblogs.com/xiaonian8/p/14024922.html
  // vue路径Url带/#/去除方法
  // VUE模式改成history后刷新报错问题
  // https://blog.csdn.net/u013558749/article/details/80899442
  // https://blog.csdn.net/william_jzy/article/details/106526339
  mode: 'history',
  routes
})

export default router
