<template>
  <div class="Technology">
    <TopControl />
    <div v-if="localResults">
      <!-- 介绍 -->
      <div :class="$global.isMobile ? 'technology-introduction-mobile' : 'technology-introduction-pc'">
        <div class="technology-introduction-content">
          <div class="technology-introduction-content-left">
            <!-- <img :src="localResults['thumb']" /> -->
            <video
              v-if="onUrlType(localResults['thumb']).indexOf('video') >= 0"
              height="200"
              :src="localResults['thumb']"
              controls="controls"
              controlslist="nodownload noremoteplayback"
              :disablePictureInPicture="true"
            >
            </video>
            <img
              v-else
              :src="localResults['thumb']"
            >
          </div>
          <div class="technology-introduction-content-right">
            <div class="technology-introduction-content-right-title">
              {{localResults['name']}}
              <span class="technology-introduction-content-right-line"></span>
            </div>
            <div class="technology-introduction-content-right-text">{{localResults['brief']}} </div>
          </div>
        </div>
      </div>
      <!-- 特色优势 -->
      <div
        :class="$global.isMobile ? 'technology-superiorities-mobile' : 'technology-superiorities-pc'"
        v-if="localResults['superiorities'] && (localResults['superiorities'].length > 0)"
      >
        <div class="technology-superiorities-content">
          <div class="technology-superiorities-content-title">特色优势</div>
          <div class="technology-superiorities-content-list">
            <ul>
              <li v-for="(item, index) in localResults['superiorities']" :key="index">
                <div class="technology-superiorities-content-list-thumb"> <img :src="item.thumb" /> </div>
                <div class="technology-superiorities-content-list-name"> {{item.name}} </div>
                <div class="technology-superiorities-content-list-brief"> {{item.brief}} </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 应用场景 -->
      <div
        :class="$global.isMobile ? 'technology-applications-mobile' : 'technology-applications-pc'"
        v-if="localResults['applications'] && (localResults['applications'].length > 0)"
      >
        <div class="technology-applications-content">
          <div class="technology-applications-content-title">应用场景</div>
          <div class="technology-applications-content-list">
            <ul>
              <li v-for="(item,index) in localResults['applications']" :key="index">
                <div class="technology-applications-content-list-thumb"> <img :src="item.thumb" /> </div>
                <div class="technology-applications-content-list-name"> {{item.name}} </div>
                <div class="technology-applications-content-list-brief"> {{item.brief}} </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 相关推荐 -->
      <div
        :class="$global.isMobile ? 'technology-recommends-mobile' : 'technology-recommends-pc'"
        v-if="localResults['recommends'] && (localResults['recommends'].length > 0)"
      >
        <div class="technology-recommends-content">
          <div class="technology-recommends-content-title">相关推荐</div>
          <div class="technology-recommends-content-list">
            <ul>
              <li v-for="(item,index) in localResults['recommends']" :key="index" @click="btnJump(item)">
                <div class="technology-recommends-content-list-left"> <img :src="item.thumb" /> </div>
                <div class="technology-recommends-content-list-right">
                  <div class="technology-recommends-content-list-name"> {{item.name}} </div>
                  <div class="technology-recommends-content-list-brief"> {{item.brief}} </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <NullPage />
    </div>
  </div>
</template>

<script>
import TopControl from '@/components/TopControl'
import NullPage from '@/components/NullPage'
export default {
  /* 核心技术 */
  name: 'Technology',
  components: {
    TopControl,
    NullPage
  },
  metaInfo () {
    return {
      title: this.headMetaInfo.title,
      meta: [
        { name: 'keywords', content: this.headMetaInfo.keywords },
        { name: 'discription', content: this.headMetaInfo.content }
      ]
    }
  },
  data () {
    return {
      headMetaInfo: {
        title: '广州帕克西软件开发有限公司',
        keywords: '',
        content: ''
      },
      nav_id: -1,
      localResults: []
    }
  },
  watch: {
    $route () { this.reqlocalResults() }
  },
  created () { },
  mounted () {
    this.headMetaInfo.title = this.$global.head_title
    this.headMetaInfo.keywords = this.$global.head_keywords
    this.headMetaInfo.content = this.$global.head_description
    this.reqlocalResults()
  },
  computed: { },
  methods: {
    btnJump (item) {
      if (item.related_id) {
        window.scrollTo(0, 0)
        // this.$router.push('/technology?id=' + item.related_id)
        this.$router.push('/technology/' + item.related_id)
      }
    },
    reqlocalResults: function () {
      if (this.$route.params.id) {
        this.nav_id = this.$route.params.id
      } else if (this.$route.query.id) {
        this.nav_id = this.$route.query.id
      } else {
        this.nav_id = -1
      }
      if (this.nav_id !== -1) {
        this.$axios({
          url: 'api/core-technology/' + this.nav_id,
          method: 'get'
        }).then(res => {
          /* console.log(this.nav_id, res.data) */
          this.localResults = null
          if (res.data) {
            if (res.data.result && res.data.result.length > 0) {
              this.localResults = res.data.result[0]
              // console.log(this.localResults)
              /* 设置Header的banner图 */
              if (this.localResults.banner) {
                this.$emit('commandEvent', 'header-banner', [{ thumb: this.localResults.banner }])
              } else {
                this.$emit('commandEvent', 'header-banner', null)
              }
            } else {
              this.$emit('commandEvent', 'header-banner', null)
            }
            if (this.localResults) {
              var headKeywords = []
              if (this.localResults.name) {
                this.$global.onUpdateHeadTitle(this.localResults.name + '-广州帕克西软件开发有限公司')
                this.headMetaInfo.title = this.localResults.name + '-广州帕克西软件开发有限公司'
                headKeywords.push(this.localResults.name)
              }
              if (this.localResults.superiorities && this.localResults.superiorities.length) {
                for (var s = 0; s < this.localResults.superiorities.length; s++) {
                  if (this.localResults.superiorities[s] && this.localResults.superiorities[s].name) {
                    headKeywords.push(this.localResults.superiorities[s].name)
                  }
                }
              }
              if (headKeywords.length > 0) {
                this.$global.onUpdateHeadKeywords(headKeywords)
                this.headMetaInfo.keywords = this.$global.getHeadKeywords(headKeywords)
              }
              if (this.localResults.brief) {
                const content = this.localResults.brief.trim().replace(/\s+|&nbsp;/ig, '')
                this.$global.onUpdateHeadDescription(content)
                this.headMetaInfo.content = content
              }
            }
          } else {
            this.$emit('commandEvent', 'header-banner', null)
          }
        })
      } else {
        console.log('跳转首页')
        if (window.location.href.indexOf('localhost') === -1) { // 要加上这句判断，否则打包有问题，缺少按照路由文件夹打包
          this.$router.push({ path: '/' })
        } else {
          this.localResults = null
          this.$emit('commandEvent', 'header-banner', null)
        }
      }
    },
    // https://www.debug8.com/javascript/t_47241.html
    onFileType: function (url) {
      // 后缀获取
      let suffix = ''
      // 获取类型结果
      let result = ''
      try {
        const urlSplitArr = url.split('.')
        suffix = urlSplitArr[urlSplitArr.length - 1]
      } catch (err) {
        suffix = ''
      }
      // url无后缀返回 false
      if (!suffix) { return false }
      suffix = suffix.toLocaleLowerCase()
      // 图片格式
      const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
      // 进行图片匹配
      result = imglist.find(item => item === suffix)
      if (result) {
        return 'image'
      }
      // 匹配 视频
      const videolist = ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v']
      result = videolist.find(item => item === suffix)
      if (result) {
        return 'video'
      }
      // 匹配 音频
      const radiolist = ['mp3', 'wav', 'wmv']
      result = radiolist.find(item => item === suffix)
      if (result) {
        return 'radio'
      }
      // 匹配txt
      const txtlist = ['txt']
      result = txtlist.find(item => item === suffix)
      if (result) {
        return 'txt'
      }
      // 匹配 excel
      const excelist = ['xls', 'xlsx']
      result = excelist.find(item => item === suffix)
      if (result) {
        return 'excel'
      }
      // 匹配 word
      const wordlist = ['doc', 'docx']
      result = wordlist.find(item => item === suffix)
      if (result) {
        return 'word'
      }
      // 匹配 ppt
      const pptlist = ['ppt', 'pptx']
      result = pptlist.find(item => item === suffix)
      if (result) {
        return 'ppt'
      }
      // 匹配 pdf
      const pdflist = ['pdf']
      result = pdflist.find(item => item === suffix)
      if (result) {
        return 'pdf'
      }
      // 其他 文件类型
      return 'other'
    },
    onUrlType: function (url) {
      let thumbType = ''
      if (url) { thumbType = this.onFileType(url) }
      if (thumbType === false) { thumbType = '' }
      return thumbType
    }
  }
}
</script>

<style lang="less" scoped>
@padding: 80px 0px;
@title: 32px;
@fontWeight: 700;
.Technology {
  width: 100%;
  overflow: hidden;
  text-align: center;
  color: #333;
  .technology-introduction-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    margin: 20px 0;
    .technology-introduction-content {
      width: var(--v-width);
      overflow: hidden;
      margin: 0 auto;
      padding: @padding;
      .technology-introduction-content-left {
        float: left;
        width: 606px;
        height: 342px;
        img {
          height: 100%;
          margin: auto;
          float: right;
        }
        video {
          height: 100%;
          margin: auto;
          float: right;
        }
      }
      .technology-introduction-content-right {
        float: left;
        width: calc(1160px - 646px);
        padding-left: 40px;
        .technology-introduction-content-right-title {
          margin-top: 20px;
          height: 36px;
          font-size: var(--v-font-size-32);
          font-weight: bold;
          text-align: left;
          position: relative;
          .technology-introduction-content-right-line {
            position: absolute;
            width: 52px;
            height: 3px;
            bottom: -10px;
            left: 0px;
            background-color: #ea6634;
          }
        }
        .technology-introduction-content-right-text {
          font-size: 18px;
          font-weight: 500;
          text-align: left;
          color: #4d4d4d;
          line-height: 40px;
          margin-top: 50px;
        }
      }
    }
  }
  .technology-introduction-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    margin: 0px 0px 20px 0px;
    .technology-introduction-content {
      width: var(--v-width);
      overflow: hidden;
      margin: 0 auto;
      padding: 10px 0px 20px 0px;
      text-align: center;
      .technology-introduction-content-left {
        width: 100%;
        img {
          width: 100%;
          margin: auto;
        }
        video {
          width: 100%;
          margin: auto;
        }
      }
      .technology-introduction-content-right {
        width: calc(100% - 40px);
        text-align: center;
        margin: 0 auto;
        .technology-introduction-content-right-title {
          margin-top: 20px;
          height: 24px;
          font-size: var(--v-font-size-32);
          font-weight: bold;
          text-align: left;
          position: relative;
          .technology-introduction-content-right-line {
            position: absolute;
            width: 52px;
            height: 3px;
            bottom: -5px;
            left: 0px;
            background-color: #ea6634;
          }
        }
        .technology-introduction-content-right-text {
          font-size: var(--v-font-size-18);
          font-weight: 500;
          text-align: left;
          color: #4d4d4d;
          line-height: 24px;
          margin-top: 10px;
        }
      }
    }
  }
  .technology-superiorities-pc {
    width: 100%;
    overflow: hidden;
    background-color: #f6f6f6;
    .technology-superiorities-content {
      width: var(--v-width);
      overflow: hidden;
      margin: 0 auto;
      padding: @padding;
      padding-bottom: 50px;
      .technology-superiorities-content-title {
        font-size: var(--v-font-size-32);
        font-weight: @fontWeight;
        line-height: 60px;
      }
      .technology-superiorities-content-list {
        overflow: hidden;
        text-align:center;
        ul {
          margin-top: 28px;
          display:inline-block;
          li {
            float: left;
            width: 254px;
            height: 200px;
            padding: 0 38px;
            margin: 0 34px;
            .technology-superiorities-content-list-thumb {
              width: 68px;
              height: 68px;
              margin: 0 auto;
              text-align: center;
              img {
                width: 100%;
                transition: all 0.5s ease-in-out;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
                &:hover {
                cursor: pointer;
                transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                -ms-transform: rotate(360deg);
            }
              }
            }
            .technology-superiorities-content-list-name {
              font-size: 20px;
              font-weight: 700;
              text-align: center;
              margin: 20px 0px;
            }
            .technology-superiorities-content-list-brief {
              font-size: 14px;
              font-weight: 500;
              text-align: left;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .technology-superiorities-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #f6f6f6;
    .technology-superiorities-content {
      width: calc(100% - 24px);
      overflow: hidden;
      margin: 0 auto;
      padding: 20px 0px;
      text-align: center;
      .technology-superiorities-content-title {
        font-size: var(--v-font-size-32);
        font-weight: @fontWeight;
        line-height: 30px;
      }
      .technology-superiorities-content-list {
        overflow: hidden;
        text-align: center;
        ul {
          margin-top: 10px;
          display:inline-block;
          li {
            float: left;
            width: calc(100% / 2 - 20px);
            min-height: 148px;
            //padding: 0 38px;
            margin: 5px 10px;
            .technology-superiorities-content-list-thumb {
              width: 42px;
              height: 42px;
              margin: 0 auto;
              text-align: center;
              img {
                width: 100%;
                transition: all 0.5s ease-in-out;
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
                &:hover {
                cursor: pointer;
                transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -o-transform: rotate(360deg);
                -ms-transform: rotate(360deg);
                }
              }
            }
            .technology-superiorities-content-list-name {
              font-size: var(--v-font-size-18);
              font-weight: 700;
              text-align: center;
              margin: 10px 0px;
            }
            .technology-superiorities-content-list-brief {
              font-size: var(--v-font-size-14);
              font-weight: 500;
              text-align: left;
              line-height: 20px;
              // 自动换行
              word-wrap: break-word;
            }
          }
        }
      }
    }
  }
  .technology-applications-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .technology-applications-content {
      width: var(--v-width);
      overflow: hidden;
      margin: 0px auto;
      padding-top: 80px;
      padding-bottom: 60px;
      .technology-applications-content-title {
        font-size: @title;
        font-weight: @fontWeight;
        height: 60px;
        line-height: 60px;
      }
      .technology-applications-content-list {
        width: var(--v-width);
        margin: 0 auto;
        overflow: hidden;
        z-index: 99;
        margin-top: 40px;
        text-align:center;
        ul {
          // display:inline-block;
          display: flex;
          li {
            width: 373px;
            height: 300px;
            // float: left;
            flex: 1;
            cursor: pointer;
            margin: 0 20px;
            .technology-applications-content-list-thumb {
              width: 100%;
              height: 216px;
              overflow: hidden;
              img {
                width: 100%;
                transition: all 0.5s ease 0s;
                &:hover {
                  transform: scale(1.1);
                  -ms-transform: scale(1.1); /* IE 9 */
                  -moz-transform: scale(1.1); /* Firefox */
                  -webkit-transform: scale(1.1); /* Safari 和 Chrome */
                  -o-transform: scale(1.1);
                }
              }
            }
            .technology-applications-content-list-name {
              font-size: 16px;
              font-weight: bold;
              text-align: center;
              margin: 20px 0px 10px 0px;
            }
            .technology-applications-content-list-brief {
              font-size: 14px;
              font-weight: 500;
              text-align: center;
              color: #666666;
            }
          }
        }
      }
    }
  }
  .technology-applications-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .technology-applications-content {
      width: calc(var(--v-width) - 20px);
      overflow: hidden;
      margin: 0px auto;
      padding: 30px 0px;
      text-align: center;
      .technology-applications-content-title {
        font-size: var(--v-font-size-32);
        font-weight: @fontWeight;
        line-height: 30px;
      }
      .technology-applications-content-list {
        width: 100%;
        margin: 0 auto;
        overflow: hidden;
        z-index: 99;
        margin: 20px auto 0px auto;
        text-align:center;
        ul {
          display: inline-block;
          li {
            //width: 373px;
            //height: 300px;
            //float: left;
            width: 100%;
            float: left;
            flex: 1;
            cursor: pointer;
            border: 1px dashed rgba(123, 123, 123, 0.2);
            margin: 2px auto;
            .technology-applications-content-list-thumb {
              width: 100%;
              //height: 216px;
              overflow: hidden;
              img {
                width: 100%;
                transition: all 0.5s ease 0s;
                &:hover {
                  transform: scale(1.1);
                  -ms-transform: scale(1.1); /* IE 9 */
                  -moz-transform: scale(1.1); /* Firefox */
                  -webkit-transform: scale(1.1); /* Safari 和 Chrome */
                  -o-transform: scale(1.1);
                }
              }
            }
            .technology-applications-content-list-name {
              font-size: var(--v-font-size-16);
              font-weight: bold;
              text-align: center;
              margin: 10px 0px 10px 0px;
            }
            .technology-applications-content-list-brief {
              font-size: var(--v-font-size-14);
              font-weight: 500;
              text-align: center;
              color: #666666;
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }
  .technology-recommends-pc {
    width: 100%;
    overflow: hidden;
    background-color: #f6f6f6;
    .technology-recommends-content {
      width: var(--v-width);
      overflow: hidden;
      margin: 0 auto;
      padding: @padding;
      .technology-recommends-content-title {
        font-size: @title;
        font-weight: @fontWeight;
        height: 60px;
        line-height: 60px;
      }
      .technology-recommends-content-list {
        width: var(--v-width);
        margin: 0 auto;
        overflow: hidden;
        z-index: 99;
        margin-top: 30px;
        text-align:center;
        ul {
          display:inline-block;
          li {
            width: 333px;
            float: left;
            cursor: pointer;
            padding: 20px;
            background-color: #fff;
            .technology-recommends-content-list-left {
              float: left;
              width: 128px;
              height: 115px;
              img {
                width: 100%;
              }
            }
            .technology-recommends-content-list-right {
              float: left;
              width: calc(333px - 128px - 40px);
              padding: 20px;
              .technology-recommends-content-list-name {
                font-size: 16px;
                font-weight: 700;
                text-align: center;
              }
              .technology-recommends-content-list-brief {
                text-align: left;
                margin-top: 15px;
                font-size: 12px;
                font-weight: 500;
                color: #666666;
                line-height: 20px;
                height: 60px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
              }
            }
          }
          li:nth-child(2) {
            margin: 0px 40px;
          }
        }
      }
    }
  }
  .technology-recommends-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #f6f6f6;
    .technology-recommends-content {
      width: calc(100% - 20px);
      overflow: hidden;
      margin: 0 auto;
      padding: 20px 0px;
      text-align: center;
      .technology-recommends-content-title {
        font-size: var(--v-font-size-32);
        font-weight: @fontWeight;
        line-height: 30px;
      }
      .technology-recommends-content-list {
        width: var(--v-width);
        margin: 0 auto;
        overflow: hidden;
        z-index: 99;
        margin-top: 10px;
        text-align:center;
        ul {
          display: flex;
          li {
            //width: 333px;
            //float: left;
            flex: 1;
            cursor: pointer;
            margin: 0 5px;
            background-color: #fff;
            .technology-recommends-content-list-left {
              float: left;
              width: 128px;
              height: 115px;
              img {
                width: 100%;
              }
            }
            .technology-recommends-content-list-right {
              float: left;
              width: calc(333px - 128px - 40px);
              padding: 20px;
              .technology-recommends-content-list-name {
                font-size: 16px;
                font-weight: 700;
                text-align: center;
              }
              .technology-recommends-content-list-brief {
                text-align: left;
                margin-top: 15px;
                font-size: 12px;
                font-weight: 500;
                color: #666666;
                line-height: 20px;
                height: 60px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
              }
            }
          }
        }
      }
    }
  }
}
</style>
