<template>
  <div class="ProductList">
    <div :class="$global.isMobile ? 'product-list-content-mobile' : 'product-list-content-pc'" v-if="localResults">
      <ul>
        <li v-for="(item, index) in localResults.data" :key="index">
          <div class="productCenterList-box-img">
            <img :src="item.thumb" />
          </div>
          <div class="productCenterList-box-botton">
            <div class="productCenterList-box-title">
              {{ item.name }}
              <div class="productCenterList-box-title-left"></div>
              <div class="productCenterList-box-title-right"></div>
            </div>
            <div class="productCenterList-box-botton-content">
              {{ item.brief }}
            </div>
          </div>
        </li>
      </ul>
      <div v-if="localResults.total > 0" class="product-content-pagelist">
        <template>
          <PageControl
            :pageIndex="localForm.page"
            :pageSize="localForm.per_page"
            :pageCount="localResults.total"
            @change="onPageControlChanged"
          >
          </PageControl>
        </template>
      </div>
    </div>
    <div v-else>
      <NullPage />
    </div>
  </div>
</template>

<script>
import { reqProductListResults } from '@/api/apireq'
import NullPage from '@/components/NullPage'
import PageControl from '@/components/PageControl'
export default {
  /* 搜索 */
  name: 'Search',
  components: {
    NullPage,
    PageControl
  },
  metaInfo () {
    return {
      title: this.headMetaInfo.title,
      meta: [
        { name: 'keywords', content: this.headMetaInfo.keywords },
        { name: 'discription', content: this.headMetaInfo.content }
      ]
    }
  },
  data () {
    return {
      headMetaInfo: {
        title: '广州帕克西软件开发有限公司',
        keywords: '',
        content: ''
      },
      localForm: {
        per_page: 9,
        page: 1
      },
      localResults: null
    }
  },
  created () { },
  mounted () {
    this.headMetaInfo.title = this.$global.head_title
    this.headMetaInfo.keywords = this.$global.head_keywords
    this.headMetaInfo.content = this.$global.head_description
    this.reqlocalResults()
  },
  methods: {
    reqlocalResults () {
      // console.log('reqlocalResults')
      this.localResults = []
      reqProductListResults(3, this.localForm).then((res) => {
        try {
          // console.log('reqProductListResults', res.data)
          if (res.data) {
            if (res.data.code === 200) {
              this.localResults = res.data.products
              // console.log(this.localResults)
              if (this.localResults.data && this.localResults.data.length > 0) {
                var headKeywords = []
                var headContent = ''
                for (var l = 0; l < this.localResults.data.length; l++) {
                  if (this.localResults.data[l]) {
                    if (this.localResults.data[l].name) {
                      headKeywords.push(this.localResults.data[l].name)
                    }
                    if (this.localResults.data[l].brief) {
                      headContent = headContent + (headContent !== '' ? '；' : '') + this.localResults.data[l].brief
                    }
                  }
                }
                headKeywords.push('产品中心')
                headKeywords.push('产品详情')
                headKeywords.push('产品')
                this.$global.onUpdateHeadKeywords(headKeywords)
                this.headMetaInfo.keywords = this.$global.getHeadKeywords(headKeywords)
                if (headContent !== '') {
                  this.$global.onUpdateHeadDescription(headContent)
                  this.headMetaInfo.content = headContent
                }
              }
            }
          }
        } catch (e) {
          // TODO handle the exception
        }
      })
    },
    onPageControlChanged (page) {
      // console.log(page)
    }
  }
}
</script>

<style lang="less" scoped>
.ProductList{
  width: 100%;
  overflow: hidden;
  text-align: center;
  color: #333;
  background-color: #fff;
  .product-list-content-pc {
    width: var(--v-width);
    overflow: hidden;
    background-color: #fff;
    margin: 100px auto;
    ul{
      overflow: hidden;
      li:nth-child(3n + 2) {
        margin: 0px 40px;
      }
      li{
        float: left;
        text-align: center;
        padding-bottom: 16px;
        .productCenterList-box-img{
          width: 373px;
          height: 216px;
          overflow: hidden;
          img {
            width: 100%;
            transition: all 0.5s ease 0s;
            cursor: pointer;
            &:hover {
              transform: scale(1.2);
              -ms-transform: scale(1.2); /* IE 9 */
              -moz-transform: scale(1.2); /* Firefox */
              -webkit-transform: scale(1.2); /* Safari 和 Chrome */
              -o-transform: scale(1.2);
            }
          }
        }
        .productCenterList-box-botton{
          width: 373px;
          height: 76px;
          .productCenterList-box-title{
            height: 17px;
            font-size: var(--v-font-size-16);
            font-weight: bold;
            margin: 15px auto;
            display: inline-block;
            position: relative;
            .productCenterList-box-title-left{
               width: 10px;
               height: 4px;
               background-color: #EA6634;
               position: absolute;
               top: 6.5px;
               left: -20px;
            }
            .productCenterList-box-title-right{
               width: 10px;
               height: 4px;
               background-color: #EA6634;
               position: absolute;
               top: 6.5px;
               right: -20px;
            }
          }
          .productCenterList-box-botton-content{
            color: #666666;
            font-size: var(--v-font-size-14);
          }
        }
      }
    }
    .product-content-pagelist{
      margin-top: 30px;
    }
  }
  .product-list-content-mobile {
    width: var(--v-width);
    overflow: hidden;
    background-color: #fff;
    margin: 10px auto 30px auto;
    ul{
      overflow: hidden;
      li{
        float: left;
        text-align: center;
        padding-bottom: 10px;
        border: 1px dashed rgba(123, 123, 123, 0.2);
        margin: 2px auto;
        .productCenterList-box-img{
          width: 100%;
          //height: 216px;
        }
        .productCenterList-box-botton{
          width: 100%;
          //height: 76px;
          .productCenterList-box-title{
            //height: 17px;
            font-size: var(--v-font-size-16);
            font-weight: bold;
            margin: 8px auto;
            display: inline-block;
            position: relative;
            .productCenterList-box-title-left{
               width: 10px;
               height: 3px;
               background-color: #EA6634;
               position: absolute;
               top: 6.5px;
               left: -20px;
            }
            .productCenterList-box-title-right{
               width: 10px;
               height: 3px;
               background-color: #EA6634;
               position: absolute;
               top: 6.5px;
               right: -20px;
            }
          }
          .productCenterList-box-botton-content{
            color: #666666;
            font-size: var(--v-font-size-14);
          }
        }
      }
    }
    .product-content-pagelist{
      margin-top: 30px;
    }
  }
}
</style>
