<template>
  <div class="Video">
    <div :class="$global.isMobile ? 'video-nav-mobile' : 'video-nav-pc'">
      <div class="video-nav-list">
        <ul>
          <li v-for="(item, index) in videonavResults" :key="index" :style="videonavIndex == index ? 'background: #f6f6f6' : 'background: #fff'">
            <div @click="onVideoNavClick(item, index)">
              <div class="video-nav-list-image">
                <div v-if="videonavIndex == index"> <img :src="item.image" /> </div>
                <div v-else><img :src="item.img" /></div>
              </div>
              <div class="video-nav-list-title"> {{ item.name }} </div>
              <div class="video-nav-list-line" v-if="videonavIndex == index ? true : false"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div :class="$global.isMobile ? 'video-content-mobile' : 'video-content-pc'">
      <div v-if="localResults && localResults['data'] && (localResults['data'].length > 0)">
        <div id="video-content-videolist" class="video-content-videolist">
          <ul>
            <li v-for="(item, index) in localResults['data']" :key="index">
              <div class="video-content-videolist-upper">
                <div :id="'videoMask-' + item['id']" class="video-content-videolist-upper-placeholder" @click.stop="onVideoPlayClick(item)"></div>
                <video :id="'videoId-' + item['id']"
                  :src="item['url']"
                  :width="videoSize.width"
                  :height="videoSize.height"
                  controls="controls"
                  controlslist="nodownload noremoteplayback"
                  :disablePictureInPicture="true"
                  :poster="item.videoCover"
                  @click.stop="onVideoPlayClick(item)"
                >
                </video>
                <div :id="'videoPreview-' + item['id']" class="video-content-videolist-upper-preview">
                  <img :src="item['preview_img']" />
                </div>
                <!-- <div class="video-content-videolist-upper-play" @click.stop="onVideoPlayClick(item)"></div> -->
              </div>
              <div class="video-content-videolist-lower">
                <div class="video-content-videolist-lower-name"> {{ item['name'] }} </div>
                <div class="video-content-videolist-lower-updated_at"> {{ item['updated_at'] | formatDate }} </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="video-content-pagelist">
          <template v-if="videoDataTotal > 0">
            <PageControl
              :perPage ="$global.isMobile ? 3 : 5"
              :pageIndex="videoreq_page"
              :pageSize="videoreq_per_page"
              :pageCount="videoDataTotal"
              @change="onVideoPageChanged"
            >
            </PageControl>
          </template>
        </div>
      </div>
      <div v-else>
        <NullPage />
      </div>
    </div>
  </div>
</template>

<script>
import NullPage from '@/components/NullPage'
import PageControl from '@/components/PageControl'
export default {
  /* 视频专区 */
  name: 'Video',
  components: {
    NullPage,
    PageControl
  },
  metaInfo () {
    return {
      title: this.headMetaInfo.title,
      meta: [
        { name: 'keywords', content: this.headMetaInfo.keywords },
        { name: 'discription', content: this.headMetaInfo.content }
      ]
    }
  },
  data () {
    return {
      headMetaInfo: {
        title: '广州帕克西软件开发有限公司',
        keywords: '',
        content: ''
      },
      /* 当前导航栏选中的下标 */
      videonavIndex: 0,
      /* 导航栏菜单 */
      videonavResults: [],
      /* 页面数据 */
      localResults: [],
      /* 加载哪些type_id的内容，默认：0（全部） */
      videoreq_type_id: 0,
      /* 一页显示多少个内容，默认：9 */
      videoreq_per_page: this.$global.isMobile ? 6 : 9,
      /* 当前页数，默认：1（第一页） */
      videoreq_page: 1,
      /* 数据总页数 */
      videoPageCount: 0,
      /* 数据总计数 */
      videoDataTotal: 0,
      /* 当前播放的视频 */
      currentVideoPlaying: null,
      videoSize: {
        width: this.$global.isMobile ? document.documentElement.style.getPropertyValue('--v-width') : 373,
        height: this.$global.isMobile ? '' : 216
      }
    }
  },
  watch: { },
  created () {
    this.reqBanner()
    /* 请求导航栏数据 */
    this.reqNavData()
  },
  mounted () {
    this.headMetaInfo.title = this.$global.head_title
    this.headMetaInfo.keywords = this.$global.head_keywords
    this.headMetaInfo.content = this.$global.head_description
    /* 请求页面数据 */
    this.reqVideoData(this.videoreq_type_id, this.videoreq_per_page, this.videoreq_page)
  },
  computed: { },
  methods: {
    reqBanner: function () {
      this.$axios({
        url: '/api/other-banner?type=video',
        method: 'get'
      }).then((res) => {
        /* console.log(res.data) */
        if (res.data && res.data.result) {
          this.$emit('commandEvent', 'header-banner', [{ thumb: res.data.result.banner }])
        } else {
          this.$emit('commandEvent', 'header-banner', null)
        }
      })
    },
    /* 请求导航栏数据 */
    reqNavData: function () {
      this.videonavResults = []
      this.$axios({
        url: 'api/video-type',
        method: 'get'
      }).then(res => {
        /* console.log(res.data) */
        if (res.data) {
          var headKeywords = []
          if (res.data.types && res.data.types.length > 0) {
            for (var i = 0; i < res.data.types.length; i++) {
              this.videonavResults.push(
                {
                  id: res.data.types[i].id,
                  name: res.data.types[i].name,
                  image: res.data.types[i].bright,
                  img: res.data.types[i].dark
                })
              /* switch (this.videonavResults[i].name) {
                   case '全部':
                     this.videonavResults[i].image = require('../../assets/images/VideoCenter/ioc1.png')
                     this.videonavResults[i].img = require('../../assets/images/VideoCenter/ioc11.png')
                     break
                   case '产品视频':
                     this.videonavResults[i].image = require('../../assets/images/VideoCenter/ioc2.png')
                     this.videonavResults[i].img = require('../../assets/images/VideoCenter/ioc22.png')
                     break
                   case '技术演示':
                     this.videonavResults[i].image = require('../../assets/images/VideoCenter/ioc3.png')
                     this.videonavResults[i].img = require('../../assets/images/VideoCenter/ioc33.png')
                     break
                   case '客户案例':
                     this.videonavResults[i].image = require('../../assets/images/VideoCenter/ioc4.png')
                     this.videonavResults[i].img = require('../../assets/images/VideoCenter/ioc44.png')
                     break
                   case '企业视频':
                     this.videonavResults[i].image = require('../../assets/images/VideoCenter/ioc5.png')
                     this.videonavResults[i].img = require('../../assets/images/VideoCenter/ioc55.png')
                     break
                 } */
              if (res.data.types[i].name && res.data.types[i].name.indexOf('全部') === -1) {
                headKeywords.push(res.data.types[i].name)
              }
            }
          }
          if (headKeywords.length > 0) {
            this.$global.onUpdateHeadKeywords(headKeywords)
            this.headMetaInfo.keywords = this.$global.getHeadKeywords(headKeywords)
          }
        }
        if (this.videonavResults.length === 0) {
          this.videonavResults.push(
            {
              id: 0,
              name: '全部',
              image: require('../../assets/images/VideoCenter/ioc1.png'),
              img: require('../../assets/images/VideoCenter/ioc11.png')
            })
        }
        this.videoreq_type_id = this.videonavResults[0].id
      })
    },
    /* 请求页面数据 */
    /* typeId：type_id，数据类型 */
    /* perPage：per_page，获取多少条数据 */
    /* toPage：page，获取第几页数据 */
    reqVideoData: function (typeId, perPage, toPage) {
      this.$axios({
        url: 'api/video' + '?per_page=' + perPage + '&page=' + toPage + (typeId !== 0 ? ('&type_id=' + typeId) : ''),
        method: 'get'
      }).then(res => {
        /* console.log(res.data) */
        this.localResults = null
        this.videoPageCount = 0
        this.videoDataTotal = 0
        if (res.data) {
          this.localResults = res.data.videos
          // console.log(this.localResults)
          if (this.localResults) {
            this.videoPageCount = this.localResults.last_page
            this.videoDataTotal = this.localResults.total
            if (this.localResults.data && this.localResults.data.length > 0) {
              var headContent = ''
              for (var i = 0; i < this.localResults.data.length; i++) {
                if (this.localResults.data[i].name) {
                  headContent = headContent + (headContent !== '' ? '；' : '') + this.localResults.data[i].name
                }
              }
              if (headContent !== '') {
                this.$global.onUpdateHeadDescription(headContent)
                this.headMetaInfo.content = headContent
              }
            }
          }
        }
      })
    },
    /* 导航栏被点击，切换页面数据类型 */
    onVideoNavClick: function (item, index) {
      this.videonavIndex = index
      this.videoreq_type_id = item.id
      this.videoreq_page = 1
      /* 检查当前视频是否正在播放，如果正在播放，则停止播放 */
      if (this.currentVideoPlaying) {
        const videoId = document.getElementById('videoId-' + this.currentVideoPlaying.id)
        const videoMask = document.getElementById('videoMask-' + this.currentVideoPlaying.id)
        const videoPreview = document.getElementById('videoPreview-' + this.currentVideoPlaying.id)
        if (videoId) {
          videoId.pause()
          videoId.removeEventListener('ended', this.onVideoPlayEnded)
        }
        if (videoMask) { videoMask.style.display = 'block' }
        if (videoPreview) { videoPreview.style.display = 'block' }
      }
      this.currentVideoPlaying = null
      this.reqVideoData(this.videoreq_type_id, this.videoreq_per_page, this.videoreq_page)
    },
    /* 请求加载切换页面的内容 */
    onVideoPageChanged: function (page) {
      this.videoreq_page = page
      this.reqVideoData(this.videoreq_type_id, this.videoreq_per_page, this.videoreq_page)
    },
    /* 点击视频：当前播放的视频 */
    onVideoPlayClick: function (item) {
      /* if (video.paused) { */
      if (this.currentVideoPlaying) {
        const currentVideo = document.getElementById('videoId-' + this.currentVideoPlaying.id)
        if (currentVideo) { currentVideo.pause() }
        if (this.currentVideoPlaying.id === item.id) {
          this.onVideoPlayEnded()
          return
        }
      }
      this.onVideoPlayEnded()
      this.currentVideoPlaying = item
      const videoId = document.getElementById('videoId-' + this.currentVideoPlaying.id)
      const videoMask = document.getElementById('videoMask-' + this.currentVideoPlaying.id)
      const videoPreview = document.getElementById('videoPreview-' + this.currentVideoPlaying.id)
      if (videoId) {
        videoId.addEventListener('ended', this.onVideoPlayEnded)
        videoId.play()
      }
      if (videoMask) { videoMask.style.display = 'none' }
      if (videoPreview) { videoPreview.style.display = 'none' }
    },
    /* 当前视频播放结束 */
    onVideoPlayEnded: function () {
      if (this.currentVideoPlaying) {
        const videoId = document.getElementById('videoId-' + this.currentVideoPlaying.id)
        const videoMask = document.getElementById('videoMask-' + this.currentVideoPlaying.id)
        const videoPreview = document.getElementById('videoPreview-' + this.currentVideoPlaying.id)
        if (videoId) { videoId.removeEventListener('ended', this.onVideoPlayEnded) }
        if (videoMask) { videoMask.style.display = 'block' }
        if (videoPreview) { videoPreview.style.display = 'block' }
      }
      this.currentVideoPlaying = null
    }
  }
}
</script>

<style lang="less" scoped>
ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}
img {
  vertical-align: top;
  max-width: 100%;
}
.Video {
  width: 100%;
  overflow: hidden;
  text-align: center;
  color: #333;
  .video-nav-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 3px 6px 0px rgba(123, 123, 123, 0.16);
    .video-nav-list {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 80px 0 0 0;
      text-align:center;
      ul {
        overflow: hidden;
        display:inline-block;
        li {
          float: left;
          width: 240px;
          height: 240px;
          text-align: center;
          margin: 0 auto;
          position: relative;
          cursor: pointer;
          .video-nav-list-image {
            width: 43px;
            height: 43px;
            margin: 63px auto;
            text-align: center;
            img {
              width: 100%;
            }
          }
          .video-nav-list-title {
            color: #666;
            font-size: 20px;
            font-weight: bold;
          }
          .video-nav-list-line {
            width: 160px;
            height: 3px;
            background: #ea6634;
            border-radius: 2px;
            position: absolute;
            bottom: 0px;
            left: calc(50% - 80px);
          }
        }
      }
    }
  }
  .video-nav-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 3px 6px 0px rgba(123, 123, 123, 0.16);
    .video-nav-list {
      width: var(--v-width);
      margin: 0 auto;
      overflow: auto;
      padding: 10px 0px 0px 0px;
      text-align:center;
      ul {
        overflow: auto;
        white-space:nowrap;
        width: auto;
        width: -moz-fit-content;
        width: -webkit-fit-content;
        width: fit-content;
        float: left;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        li {
          //float: left;
          //width: 240px;
          //height: 240px;
          width: 64px;
          height: 64px;
          text-align: center;
          margin: 0px 3px;
          position: relative;
          display: inline-block;
          cursor: pointer;
          .video-nav-list-image {
            width: 32px;
            height: 32px;
            //margin: 63px auto;
            text-align: center;
            margin: 5px 16px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .video-nav-list-title {
            color: #666;
            font-size: var(--v-font-size-16);
            font-weight: bold;
          }
          .video-nav-list-line {
            width: 100%;
            height: 3px;
            background: #ea6634;
            border-radius: 2px;
            position: absolute;
            bottom: 0px;
            //left: calc(50% - 80px);
          }
        }
      }
    }
  }
  .video-content-pc {
    width: 100%;
    overflow: hidden;
    .video-content-videolist {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      text-align:center;
      ul {
        overflow: hidden;
        padding-bottom: 80px;
        // display:inline-block;
        li:nth-child(3n + 2) {
          margin: 0px 40px;
        }
        li {
          float: left;
          margin-top: 50px !important;
          text-align: center;
          padding-bottom: 16px;
          box-shadow: 6px 13px 33px 1px rgba(0, 0, 0, 0.2);
          .video-content-videolist-upper {
            width: 373px;
            height: 216px;
            position: relative;
            cursor: pointer;
            .video-content-videolist-upper-placeholder {
              z-index: 99;
              // display: none;
              position: absolute;
              top: 50%;
              left: 50%;
              width: 100px;
              height: 100px;
              margin: -50px 0 0 -50px;
              background: url(../../assets/images/ProductCenter/bigplay.svg) no-repeat;
            }
            .video-content-videolist-upper-placeholder:hover {
              background-position-y: -100px;
            }
            .video-content-videolist-upper-preview{
              width: 100%;
              height: 100%;
              position: absolute;
              display: block;
              z-index: 12;
              top: 0px;
              left: 0px;
              img {
                vertical-align: top;
                max-width: 100%;
                height: 100%;
              }
            }
            .video-content-videolist-upper-play {
              width: 100%;
              height: 100%;
              position: absolute;
              z-index: 19;
              top: 0px;
              left: 0px;
            }
          }
          .video-content-videolist-lower {
            width: calc(373px - 20px);
            height: 36px;
            padding: 5px 10px;
            .video-content-videolist-lower-name {
              font-size: 16px;
              font-weight: bold;
              text-align: left;
              color: #333333;
            }
            .video-content-videolist-lower-updated_at {
              font-size: 14px;
              text-align: right;
              color: #666666;
              margin-top: 12px;
            }
          }
        }
      }
    }
    .video-content-pagelist {
      width: var(--v-width);
      overflow: hidden;
      background-color: #fff;
      margin: 0px auto 100px auto;
      ul{
        display: inline-block;
      }
      /*
      ul {
        display: inline-block;
        margin: 0 auto;
        li {
          float: left;
          width: 48px;
          height: 38px;
          line-height: 38px;
          font-weight: bold;
          font-size: 16px;
          margin: 0 10px;
          color: #666666;
          cursor: pointer;
          .pitchOn {
            选中
            background: #ea6634;
            border-radius: 4px;
            color: #fff !important;
          }
        }
      }
      */
    }
  }
  .video-content-mobile {
    width: 100%;
    overflow: hidden;
    .video-content-videolist {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      text-align:center;
      ul {
        overflow: hidden;
        padding-bottom: 30px;
        // display:inline-block;
        li {
          float: left;
          margin-top: 8px !important;
          text-align: center;
          padding-bottom: 12px;
          box-shadow: 6px 6px 10px 1px rgba(0, 0, 0, 0.2);
          .video-content-videolist-upper {
            //width: 373px;
            //height: 216px;
            width: var(--v-width);
            position: relative;
            cursor: pointer;
            .video-content-videolist-upper-placeholder {
              z-index: 99;
              // display: none;
              position: absolute;
              top: 50%;
              left: 50%;
              width: 100px;
              height: 100px;
              margin: -50px 0 0 -50px;
              background: url(../../assets/images/ProductCenter/bigplay.svg) no-repeat;
            }
            .video-content-videolist-upper-placeholder:hover {
              background-position-y: -100px;
            }
            .video-content-videolist-upper-preview{
              width: var(--v-width);
              height: 100%;
              position: absolute;
              display: block;
              z-index: 12;
              top: 0px;
              left: 0px;
              img {
                vertical-align: top;
                max-width: 100%;
                height: 100%;
              }
            }
            .video-content-videolist-upper-play {
              width: 100%;
              height: 100%;
              position: absolute;
              z-index: 19;
              top: 0px;
              left: 0px;
            }
          }
          .video-content-videolist-lower {
            width: calc(var(--v-width) - 20px);
            height: 36px;
            padding: 5px 10px;
            .video-content-videolist-lower-name {
              font-size: var(--v-font-size-16);
              font-weight: bold;
              text-align: left;
              color: #333333;
            }
            .video-content-videolist-lower-updated_at {
              font-size: var(--v-font-size-14);
              text-align: right;
              color: #666666;
              margin-top: 12px;
            }
          }
        }
      }
    }
    .video-content-pagelist {
      width: var(--v-width);
      overflow: hidden;
      background-color: #fff;
      margin: 0px auto 30px auto;
      ul{
        display: inline-block;
      }
      /*
      ul {
        display: inline-block;
        margin: 0 auto;
        li {
          float: left;
          width: 48px;
          height: 38px;
          line-height: 38px;
          font-weight: bold;
          font-size: 16px;
          margin: 0 10px;
          color: #666666;
          cursor: pointer;
          .pitchOn {
            选中
            background: #ea6634;
            border-radius: 4px;
            color: #fff !important;
          }
        }
      }
      */
    }
  }
}
</style>
