<template>
  <div class="footer">
    <div :class="$global.isMobile ? 'footer-box-mobile' : 'footer-box-pc'">
      <ul>
        <li>
          <div class="packsee-logo">
            <img src="../../assets/images/Home/logo.png" />
          </div>
        </li>
        <li>形体智能-让您遇见更美的自己！</li>
        <li>广东省广州市天河区黄村路57号粤安工业园B栋502</li>
        <!-- <li>免责声明丨隐私政策</li> -->
        <div class="notice">
          <router-link target="_blank" to="/disclaimer">
            <div>免责声明</div>
          </router-link>
          <span>|</span>
          <router-link target="_blank" to="/privacy_policy">
            <div>隐私政策</div>
          </router-link>
        </div>
      </ul>
      <ul>
        <li>联系我们</li>
        <li>固定电话：020-82520103</li>
        <li>手机/微信：18925177752</li>
        <li>Q Q：3239966704</li>
        <li>邮箱：panx@panxsoft.com</li>
      </ul>
      <ul>
        <li>相关链接</li>
        <li><a href="https://facecloud.panxsoft.com/web-admin/#/login" target="_blank">FaceCloud平台</a></li>
        <li><a href="http://gzmofa.com/" target="_blank">魔发科技官网</a></li>
        <li><a href="http://www.xiaojingxiuxiu.com/" target="_blank">小镜秀秀官网</a></li>
        <li><a href="https://dw.panxsoft.com/" target="_blank">魔售商城</a></li>
      </ul>
      <ul>
        <li>关注我们</li>
        <li>
          <div class="MakeupMirrorService">
            <span @click.stop="$global.isMobile ? (showMakeupMirrorService = !showMakeupMirrorService, shouwXJXXSubscription = false) : (() => {})">魔妆镜服务号</span>
            <!-- <img class="MakeupMirrorService-qr_code" src="../../assets/images/Footer/makeup-mirror.jpg" /> -->
            <div class="MakeupMirrorService-qr_code" :style="$global.isMobile ? ( showMakeupMirrorService ? 'display:block' : 'display:none') : ''">
              <div class="MakeupMirrorService-qr_code-panel">
                <img class="footer-qr_code-bgimg" src="../../assets/images/Footer/qrcode-bgimg.png" />
                <div class="footer-qr_code-brief">扫码关注</div>
                <img class="footer-qr_code-img" src="../../assets/images/Footer/makeup-mirror.jpg" />
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="XJXXSubscription">
            <span @click.stop="$global.isMobile ? (shouwXJXXSubscription = !shouwXJXXSubscription, showMakeupMirrorService = false) : (() => {})">小镜秀秀订阅号</span>
            <!-- <img class="XJXXSubscription-content-qr_code" src="../../assets/images/Footer/xjxx.jpg" /> -->
            <div class="XJXXSubscription-content-qr_code" :style="$global.isMobile ? ( shouwXJXXSubscription ? 'display:block' : 'display:none') : ''">
              <div class="XJXXSubscription-content-qr_code-panel">
                <img class="footer-qr_code-bgimg" src="../../assets/images/Footer/qrcode-bgimg.png" />
                <div class="footer-qr_code-brief">扫码关注</div>
                <img class="footer-qr_code-img" src="../../assets/images/Footer/xjxx.jpg" />
              </div>
            </div>
          </div>
        </li>
        <li><a href="https://weibo.com/p/1006065698152360/home?from=page_100606&mod=TAB#place" target="_blank">小镜秀秀微博</a></li>
      </ul>
    </div>
    <div class="cli"></div>
    <div :class="$global.isMobile ? 'copyright-mobile' : 'copyright-pc'">
      Copyright ©2016-2021 版权所有 广州帕克西软件开发有限公司
      <div v-if="$global.isMobile" style="margin-top:10px">
        <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备15045073号</a>
      </div>
      <span v-else style="margin-left:50px">
        <a href="https://beian.miit.gov.cn" target="_blank">粤ICP备15045073号</a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showMakeupMirrorService: false,
      shouwXJXXSubscription: false
    }
  }
}
</script>

<style scoped lang="less">
.footer {
  width: 100%;
  background: #1c1c1c;
  //overflow: hidden;
  padding: 30px 0 50px 0;
  color: #ffffff;
  text-align: center;
  margin: 0 auto;
  font-size: var(--v-font-size-14);
  .footer-box-pc {
    width: var(--v-width);
    margin: 0 auto;
    ul {
      text-align: center;
      margin: 0 auto;
      float: left;
      // overflow: hidden;
      line-height: 40px;
      li {
        text-align: left;
        color: #ffffff;
        cursor: pointer;
      }
      li:nth-child(1) {
        font-size: 18px;
      }
      li:nth-child(n + 2) {
        &:hover {
          color: #ea6634;
        }
      }
    }
    ul:nth-child(1) {
      // width: 350px;
      width: 35%;
      li:nth-child(2) {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 40px;
      }
      li:nth-child(n + 2) {
        color: #ffffff;
      }
      li:nth-child(n + 3) {
        &:hover {
          color: #ea6634;
        }
      }
    }
    ul:nth-child(2) {
      // width: 283.33px;
      width: 21%;
    }
    ul:nth-child(3) {
      // width: 283.33px;
      width: 21%;
    }
    ul:nth-child(4) {
      // width: 283.33px;
      width: 21%;
    }
    .MakeupMirrorService,.XJXXSubscription {
      position: relative;
      display: inline-block;
      .MakeupMirrorService-qr_code,.XJXXSubscription-content-qr_code {
        width: 158px;
        height: 168px;
        position: absolute;
        float: right;
        right: -156px;
        top: -27px;
        display: none;
        .footer-qr_code-bgimg {
          position: absolute;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .footer-qr_code-brief {
          color: #333;
          position: absolute;
          left: 56px;
          top: 3px;
          font-size: 14px;
          font-weight: 1000;
          margin: auto;
        }
        .footer-qr_code-img {
          position: absolute;
          width: 118px;
          height: 118px;
          right: 15px;
          bottom: 18px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .MakeupMirrorService:hover .MakeupMirrorService-qr_code,.XJXXSubscription:hover .XJXXSubscription-content-qr_code {
      display: block;
    }
    .notice {
      display: flex;
      // text-align: left;
      div:hover {
        color: #ea6634;
        cursor: pointer;
      }
      span {
        padding: 0 20px;
      }
    }
  }
  .footer-box-mobile {
    width: var(--v-width);
    margin: 0 auto;
    ul {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      float: left;
      // overflow: hidden;
      line-height: 30px;
      li {
        color: #ffffff;
        cursor: pointer;
        text-align: center;
      }
      li:nth-child(1) {
        font-size: 14px;
      }
    }
    ul:nth-child(1) {
      li:nth-child(2) {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 10px;
      }
    }
    ul:nth-child(2),ul:nth-child(3),ul:nth-child(4){
      display: none;
      li {
        margin: 0 5px;
      }
      li:nth-child(2) {
        display: inline-table;
      }
      li:nth-child(3) {
        display: inline-table;
      }
      li:nth-child(4) {
        display: inline-table;
      }
      li:nth-child(5) {
        display: inline-table;
      }
    }
    .MakeupMirrorService,.XJXXSubscription {
      position: relative;
      display: inline-block;
      .MakeupMirrorService-qr_code,.XJXXSubscription-content-qr_code {
        width: 1px;
        height: 168px;
        position: relative;
        display: none;
        .MakeupMirrorService-qr_code-panel,.XJXXSubscription-content-qr_code-panel {
          position: relative;
          width: 158px;
          height: 168px;
          .footer-qr_code-bgimg {
            position: absolute;
            width: 158px;
            height: 168px;
            left: -65px;
            top: -10px;
            /* 水平镜像翻转 */
            // -moz-transform:scaleX(-1);
            // -webkit-transform:scaleX(-1);
            // -o-transform:scalex(-1);
            // transform:scaleX(-1);
            /*兼容IE*/
            // filter:FlipH;
            // -ms-filter: "FlipH";
            /* 垂直镜像翻转 */
            // -moz-transform:scaleY(-1);
            // -webkit-transform:scaleY(-1);
            // -o-transform:scaleY(-1);
            // transform:scaleY(-1);
            /*兼容IE*/
            // filter:FlipV;
            // -ms-filter: "FlipV";
            transform: rotate(90deg);
            /* IE 9 */
            -ms-transform:rotate(90deg);
             /* Firefox */
            -moz-transform:rotate(90deg);
            /* Safari 和 Chrome */
            -webkit-transform: rotate(90deg);
            /* Opera */
            -o-transform:rotate(90deg);
            img {
              width: 100%;
              height: 100%;
            }
          }
          .footer-qr_code-brief {
            position: absolute;
            width: 8px;
            height: 168px;
            color: #333;
            left: -46px;
            top: 32px;
            font-size: 14px;
            font-weight: 1000;
            margin: auto;
            line-height: 24px;
          }
          .footer-qr_code-img {
            position: absolute;
            width: 118px;
            height: 118px;
            left: -30px;
            bottom: 30px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .notice {
      display: inline-flex;
      text-align: center;
      margin: 0 auto;
      div:hover {
        color: #ea6634;
        cursor: pointer;
      }
      span {
        padding: 0 20px;
      }
    }
  }
  .copyright-pc {
    font-size: var(--v-font-size-14);
    font-weight: 500;
    text-align: center;
    padding: 50px 0px 30px 0px;
  }
  .copyright-mobile {
    font-size: var(--v-font-size-14);
    font-weight: 500;
    text-align: center;
    padding: 30px 0px 0px 0px;
  }
}
.packsee-logo {
  img {
    height: 50px;
  }
}
</style>
