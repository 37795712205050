<template>
  <div class="CollaborateCase">
    <div :class="$global.isMobile ? 'collaborate-case-nav-mobile' : 'collaborate-case-nav-pc'">
      <div class="collaborate-case-nav-list">
        <ul>
          <li v-for="(item, index) in casenavResults" :key="index" :style="casenavIndex == index ? 'background: #f6f6f6' : 'background: #fff'">
            <div @click.stop="onCaseNavClick(item, index)">
              <div class="collaborate-case-nav-list-image">
                <div v-if="casenavIndex == index"> <img :src="item.image" /> </div>
                <div v-else> <img :src="item.img" /></div>
              </div>
              <div class="collaborate-case-nav-list-title"> {{ item.name }} </div>
              <div class="collaborate-case-nav-list-line" v-if="casenavIndex == index ? true : false"></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div :class="$global.isMobile ? 'collaborate-case-content-mobile' : 'collaborate-case-content-pc'">
      <div v-if="localResults && localResults['data'] && (localResults['data'].length > 0)">
        <div class="collaborate-case-content-caselist">
          <ul>
            <li v-for="(item, index) in localResults['data']" :key="index" @click.stop="onClickCase(item)">
              <div class="collaborate-case-content-caselist-thumb"> <img :src="item.thumb" /> </div>
              <div class="collaborate-case-content-caselist-panel">
                <div class="collaborate-case-content-caselist-panel-left"> <img style="width: 100%" :src="item.logo" /> </div>
                <div class="collaborate-case-content-caselist-panel-right">
                  <div class="collaborate-case-content-caselist-panel-right-title"> {{ item.title }} </div>
                  <div class="collaborate-case-content-caselist-panel-right-description"> {{ item.description }} </div>
                  <div class="collaborate-case-content-caselist-panel-right-list">
                    <div class="collaborate-case-content-caselist-panel-right-list-ul"
                      v-for="(keyword_item, keyword_index) in item.keywords" :key="keyword_index">
                      <div :class="'collaborate-case-content-caselist-panel-right-list-li ' + (keyword_index < item.keywords.length - 1 ? 'collaborate-case-content-caselist-panel-right-list-li-line' : '')"> {{ keyword_item.name }} </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="collaborate-case-content-pagelist">
          <template v-if="caseDataTotal > 0">
            <PageControl
              :perPage ="$global.isMobile ? 3 : 5"
              :pageIndex="casereq_page"
              :pageSize="casereq_per_page"
              :pageCount="caseDataTotal"
              @change="onCasePageChanged"
            >
            </PageControl>
          </template>
        </div>
      </div>
      <div v-else>
        <NullPage />
      </div>
    </div>
    <!-- 遮罩层 -->
    <div class="caseMask" v-show="showMask" @click.stop="onCloseCase"></div>
    <!-- 弹出案例详情 -->
    <div v-show="isShow"  :class="$global.isMobile ? 'caseDetail-mobile' : 'caseDetail-pc'">
      <i class="el-icon-close" @click.stop="onCloseCase"></i>
      <div class="caseImg">
        <img :src="imgSrc" alt="">
      </div>
      <div class="caseDesc">{{ caseDescription }}</div>
    </div>
  </div>
</template>

<script>
import NullPage from '@/components/NullPage'
import PageControl from '@/components/PageControl'
export default {
  /* 合作案例 */
  name: 'CollaborateCase',
  components: {
    NullPage,
    PageControl
  },
  metaInfo () {
    return {
      title: this.headMetaInfo.title,
      meta: [
        { name: 'keywords', content: this.headMetaInfo.keywords },
        { name: 'discription', content: this.headMetaInfo.content }
      ]
    }
  },
  data () {
    return {
      headMetaInfo: {
        title: '广州帕克西软件开发有限公司',
        keywords: '',
        content: ''
      },
      isShow: false,
      imgSrc: '',
      caseDescription: '',
      showMask: false,
      /* 当前导航栏选中的下标 */
      casenavIndex: 0,
      /* 导航栏菜单 */
      casenavResults: [],
      /* 页面数据 */
      localResults: [],
      /* 加载哪些tag_id的内容，默认：0（全部） */
      casereq_tag_id: 0,
      /* 一页显示多少个内容，默认：9 */
      casereq_per_page: this.$global.isMobile ? 6 : 9,
      /* 当前页数，默认：1（第一页） */
      casereq_page: 1,
      /* 数据总页数 */
      casePageCount: 0,
      /* 数据总计数 */
      caseDataTotal: 0
    }
  },
  watch: { },
  created () {
    this.reqBanner()
    /* 请求导航栏数据 */
    this.reqNavData()
  },
  mounted () {
    this.headMetaInfo.title = this.$global.head_title
    this.headMetaInfo.keywords = this.$global.head_keywords
    this.headMetaInfo.content = this.$global.head_description
    /* 请求页面数据 */
    this.reqCaseData(this.casereq_tag_id, this.casereq_per_page, this.casereq_page)
  },
  computed: { },
  methods: {
    onClickCase: function (item) {
      // console.log(item)
      this.isShow = !this.isShow
      this.showMask = !this.showMask
      this.imgSrc = item.thumb
      this.caseDescription = item.description
    },
    onCloseCase: function () {
      this.showMask = false
      this.isShow = false
    },
    reqBanner: function () {
      this.$axios({
        url: '/api/other-banner?type=case',
        method: 'get'
      }).then((res) => {
        /* console.log(res.data) */
        if (res.data && res.data.result) {
          this.$emit('commandEvent', 'header-banner', [{ thumb: res.data.result.banner }])
        } else {
          this.$emit('commandEvent', 'header-banner', null)
        }
      })
    },
    /* 请求导航栏数据 */
    reqNavData: function () {
      this.casenavResults = []
      this.$axios({
        url: 'api/case-tag',
        method: 'get'
      }).then((res) => {
        /* console.log(res.data) */
        if (res.data) {
          if (res.data.tags && res.data.tags.length > 0) {
            for (var i = 0; i < res.data.tags.length; i++) {
              this.casenavResults.push({
                id: res.data.tags[i].id,
                name: res.data.tags[i].name,
                image: res.data.tags[i].bright,
                img: res.data.tags[i].dark
              })
              /* switch (this.casenavResults[i].name) {
                case '全部':
                  this.casenavResults[i].image = require('../../assets/images/CollaborateCase/ioc1.png')
                  this.casenavResults[i].img = require('../../assets/images/CollaborateCase/ioc11.png')
                  break
                case '虚拟试妆':
                  this.casenavResults[i].image = require('../../assets/images/CollaborateCase/ioc2.png')
                  this.casenavResults[i].img = require('../../assets/images/CollaborateCase/ioc22.png')
                  break
                case '眼镜试戴':
                  this.casenavResults[i].image = require('../../assets/images/CollaborateCase/ioc3.png')
                  this.casenavResults[i].img = require('../../assets/images/CollaborateCase/ioc33.png')
                  break
                case '展会活动':
                  this.casenavResults[i].image = require('../../assets/images/CollaborateCase/ioc4.png')
                  this.casenavResults[i].img = require('../../assets/images/CollaborateCase/ioc44.png')
                  break
                case '项目定制':
                  this.casenavResults[i].image = require('../../assets/images/CollaborateCase/ioc5.png')
                  this.casenavResults[i].img = require('../../assets/images/CollaborateCase/ioc55.png')
                  break
              } */
            }
          }
        }
        if (this.casenavResults.length === 0) {
          this.casenavResults.push({
            id: 0,
            name: '全部',
            image: require('../../assets/images/CollaborateCase/ioc1.png'),
            img: require('../../assets/images/CollaborateCase/ioc11.png')
          })
        }
        this.casereq_tag_id = this.casenavResults[0].id
      })
    },
    /* 请求页面数据 */
    /* tagId：tag_id，数据类型 */
    /* perPage：per_page，获取多少条数据 */
    /* toPage：page，获取第几页数据 */
    reqCaseData: function (tagId, perPage, toPage) {
      this.$axios({
        url: 'api/case' + '?per_page=' + perPage + '&page=' + toPage + (tagId !== 0 ? '&tag_id=' + tagId : ''),
        method: 'get'
      }).then((res) => {
        /* console.log(res.data) */
        this.localResults = null
        this.casePageCount = 0
        this.caseDataTotal = 0
        if (res.data) {
          this.localResults = res.data.cases
          // console.log(this.localResults)
          if (this.localResults) {
            this.casePageCount = this.localResults.last_page
            this.caseDataTotal = this.localResults.total
            // 处理 meta[name="keywords"]
            if (this.localResults.data && this.localResults.data.length > 0) {
              var headKeywords = []
              var titleKeywords = []
              for (var i = 0; i < this.localResults.data.length; i++) {
                // console.log(this.localResults.data[i].keywords)
                const localResultsKeywords = this.localResults.data[i].keywords
                if (localResultsKeywords && localResultsKeywords.length > 0) {
                  for (var j = 0; j < localResultsKeywords.length; j++) {
                    if (localResultsKeywords[j] && localResultsKeywords[j].name) {
                      if (headKeywords.indexOf(localResultsKeywords[j].name) === -1) {
                        headKeywords.push(localResultsKeywords[j].name)
                      }
                    }
                  }
                }
                if (this.localResults.data[i].title) {
                  titleKeywords.push(this.localResults.data[i].title)
                }
              }
              for (var t = 0; t < titleKeywords.length; t++) {
                headKeywords.push(titleKeywords[t])
              }
              if (headKeywords !== null && headKeywords.length > 0) {
                this.$global.onUpdateHeadKeywords(headKeywords)
                this.headMetaInfo.keywords = this.$global.getHeadKeywords(headKeywords)
              }
            }
          }
        }
      })
    },
    /* 导航栏被点击，切换页面数据类型 */
    onCaseNavClick: function (item, index) {
      this.casenavIndex = index
      this.casereq_tag_id = item.id
      this.casereq_page = 1
      this.reqCaseData(this.casereq_tag_id, this.casereq_per_page, this.casereq_page)
    },
    /* 请求加载切换页面的内容 */
    onCasePageChanged: function (page) {
      this.casereq_page = page
      this.reqCaseData(this.casereq_tag_id, this.casereq_per_page, this.casereq_page)
    }
  }
}
</script>

<style lang="less" scoped>
ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}
img {
  vertical-align: top;
  max-width: 100%;
}
.CollaborateCase {
  width: 100%;
  overflow: hidden;
  text-align: center;
  color: #333;
  .collaborate-case-nav-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 3px 6px 0px rgba(123, 123, 123, 0.16);
    .collaborate-case-nav-list {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 80px 0px 0px 0px;
      ul {
        display: inline-block;
        li {
          float: left;
          width: 240px;
          height: 240px;
          text-align: center;
          margin: 0 auto;
          position: relative;
          cursor: pointer;
          .collaborate-case-nav-list-image {
            width: 43px;
            height: 43px;
            margin: 63px auto;
            text-align: center;
          }
          .collaborate-case-nav-list-title {
            color: #666;
            font-size: 20px;
            font-weight: bold;
          }
          .collaborate-case-nav-list-line {
            width: 160px;
            height: 3px;
            background: #ea6634;
            border-radius: 2px;
            position: absolute;
            bottom: 0px;
            left: calc(50% - 80px);
          }
        }
      }
    }
  }
  .collaborate-case-nav-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 3px 6px 0px rgba(123, 123, 123, 0.16);
    .collaborate-case-nav-list {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 10px 0px 0px 0px;
      text-align:center;
      ul {
        overflow: auto;
        white-space:nowrap;
        width: auto;
        width: -moz-fit-content;
        width: -webkit-fit-content;
        width: fit-content;
        float: left;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        li {
          //float: left;
          //width: 240px;
          //height: 240px;
          width: 64px;
          height: 72px;
          text-align: center;
          margin: 0 3px;
          position: relative;
          display: inline-block;
          cursor: pointer;
          .collaborate-case-nav-list-image {
            width: 32px;
            height: 32x;
            //margin: 63px auto;
            text-align: center;
            margin: 5px 16px 0px 16px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .collaborate-case-nav-list-title {
            color: #666;
            font-size: var(--v-font-size-16);
            font-weight: bold;
          }
          .collaborate-case-nav-list-line {
            width: 100%;
            height: 3px;
            background: #ea6634;
            border-radius: 2px;
            position: absolute;
            bottom: 0px;
            //left: calc(50% - 80px);
          }
        }
      }
    }
  }
  .collaborate-case-content-pc {
    width: 100%;
    overflow: hidden;
    .collaborate-case-content-caselist {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      text-align:center;
      ul {
        display: flex;
        flex-wrap:wrap;
        // height: auto;
        // display: inline-block;
        overflow: hidden;
        padding-bottom: 80px;
        li:nth-child(3n + 2) {
          margin: 0px 40px;
        }
        li {
          width: 30%;
          margin-top: 50px !important;
          text-align: center;
          padding-bottom: 16px;
          box-shadow: 6px 13px 33px 1px rgba(0, 0, 0, 0.2);
          .collaborate-case-content-caselist-thumb {
            // width: 373px;
            height: 216px;
            cursor: pointer;
          }
          .collaborate-case-content-caselist-panel {
            width: calc(373px - 48px);
            height: 101px;
            padding: 24px;
            .collaborate-case-content-caselist-panel-left {
              float: left;
              width: 56px;
              width: 56px;
              img{
                height: 100%;
              }
            }
            .collaborate-case-content-caselist-panel-right {
              width: calc(100% - 56px - 24px);
              margin-left: 24px;
              float: left;
              .collaborate-case-content-caselist-panel-right-title {
                font-size: 20px;
                font-weight: bold;
                text-align: left;
              }
              .collaborate-case-content-caselist-panel-right-description {
                font-size: 16px;
                text-align: left;
                margin: 30px 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              .collaborate-case-content-caselist-panel-right-list {
                height: 100%;
                .collaborate-case-content-caselist-panel-right-list-ul {
                  overflow: hidden;
                  float: left;
                  .collaborate-case-content-caselist-panel-right-list-li {
                    float: left;
                    font-size: 14px;
                    text-align: left;
                    color: #666666;
                    height: 22px;
                    //padding-right: 18px;
                    line-height: 22px;
                    text-align: center;
                    padding-left: 10px;
                    padding-right: 10px;
                  }
                  .collaborate-case-content-caselist-panel-right-list-li-line {
                    border-right: 1px solid #d1d1d1;
                  }
                }
              }
            }
          }
        }
      }
    }
    .collaborate-case-content-pagelist {
      width: var(--v-width);
      overflow: hidden;
      background-color: #fff;
      margin: 0px auto 100px auto;
      ul{
        display: inline-block;
      }
      /*
      ul {
        display: inline-block;
        margin: 0 auto;
        li {
          float: left;
          width: 48px;
          height: 38px;
          line-height: 38px;
          font-weight: bold;
          font-size: 16px;
          margin: 0 10px;
          color: #666666;
          cursor: pointer;
          .pitchOn {
            background: #ea6634;
            border-radius: 4px;
            color: #fff !important;
          }
        }
      }
      */
    }
  }
  .collaborate-case-content-mobile {
    width: 100%;
    overflow: hidden;
    .collaborate-case-content-caselist {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      text-align:center;
      ul {
        display: flex;
        flex-wrap:wrap;
        // height: auto;
        // display: inline-block;
        overflow: hidden;
        padding-bottom: 30px;
        li {
          width: 100%;
          float: left;
          margin-top: 8px !important;
          text-align: center;
          padding-bottom: 12px;
          box-shadow: 6px 6px 10px 1px rgba(0, 0, 0, 0.2);
          .collaborate-case-content-caselist-thumb {
            // width: 373px;
            //height: 216px;
            width: var(--v-width);
            cursor: pointer;
          }
          .collaborate-case-content-caselist-panel {
            width: 100%;
            //height: 101px;
            padding: 10px;
            .collaborate-case-content-caselist-panel-left {
              float: left;
              width: 48px;
              width: 48px;
              img{
                height: 100%;
              }
            }
            .collaborate-case-content-caselist-panel-right {
              //width: calc(100% - 56px - 24px);
              margin-left: 12px;
              float: left;
              .collaborate-case-content-caselist-panel-right-title {
                font-size: var(--v-font-size-20);
                font-weight: bold;
                text-align: left;
              }
              .collaborate-case-content-caselist-panel-right-description {
                font-size: var(--v-font-size-16);
                text-align: left;
                margin: 10px 0;
              }
              .collaborate-case-content-caselist-panel-right-list {
                height: 100%;
                .collaborate-case-content-caselist-panel-right-list-ul {
                  overflow: hidden;
                  float: left;
                  .collaborate-case-content-caselist-panel-right-list-li {
                    float: left;
                    font-size: var(--v-font-size-14);
                    text-align: left;
                    color: #666666;
                    height: 22px;
                    //padding-right: 18px;
                    line-height: 22px;
                    text-align: center;
                    padding-left: 10px;
                    padding-right: 10px;
                  }
                  .collaborate-case-content-caselist-panel-right-list-li-line {
                    border-right: 1px solid #d1d1d1;
                  }
                }
              }
            }
          }
        }
      }
    }
    .collaborate-case-content-pagelist {
      width: var(--v-width);
      overflow: hidden;
      background-color: #fff;
      margin: 0px auto 30px auto;
      ul{
        display: inline-block;
      }
      /*
      ul {
        display: inline-block;
        margin: 0 auto;
        li {
          float: left;
          width: 48px;
          height: 38px;
          line-height: 38px;
          font-weight: bold;
          font-size: 16px;
          margin: 0 10px;
          color: #666666;
          cursor: pointer;
          .pitchOn {
            background: #ea6634;
            border-radius: 4px;
            color: #fff !important;
          }
        }
      }
      */
    }
  }
  .caseMask {
    width: 100%;
    height: 100%;
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999;
  }
  .caseDetail-pc {
    position: fixed;
    //top: 50%;
    //left: 50%;
    width: 1000px;
    // height: 600px;
    background: #fff;
    //margin-top: -300px;
    //margin-left: -530px;
    z-index: 100000;
    // 居中
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    img {
      width: 100%;
      height: 100%;
    }
    .el-icon-close {
      float: right;
      padding: 10px 10px 0 0!important;
      display: block;
      cursor: pointer;
      font-size: 40px;
      line-height: 40px;
    }
    .caseDesc {
      width: 100%;
      font-size: var(--v-font-size-20);
      margin: 20px;
      text-align: left;
    }
  }
  .caseDetail-mobile {
    position: fixed;
    //top: 50%;
    //left: 50%;
    width: var(--v-width);
    // height: 600px;
    background: #fff;
    //margin-top: -300px;
    //margin-left: -530px;
    // 居中
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100000;
    img {
      width: 100%;
      height: 100%;
    }
    .el-icon-close {
      float: right;
      padding: 10px 10px 0 0!important;
      display: block;
      cursor: pointer;
      font-size: 27px;
      line-height: 36px;
    }
    .caseDesc {
      width: 100%;
      font-size: var(--v-font-size-20);
      margin: 20px 12px;
      text-align: left;
    }
  }
}

</style>
