<template>
  <div class="privacy_policy-container">
    <div
      :class="
        $global.isMobile ? 'privacy_policy-box-mobile' : 'privacy_policy-box-pc'
      "
    >
      <div class="pc">
        <div class="item_box bgc_F9FBFD pad_t_80 pad_b_80">
          <div class="tit_box mar_b_50">
            <span class="line"></span> <span class="tit_text">隐私政策</span>
            <span class="line"></span>
          </div>
          <div class="type_page">
            <div class="text_box">
              <div class="text">
                广州帕克西软件开发有限公司（“帕克西”或“我们”）非常重视用户信息的保护，并且将以勤勉和审慎的义务保护用户信息安全。
                为方便您登录、使用相关服务，以及为您提供更个性化的用户体验和服务，您在使用我们的服务时，我们可能会收集和使用您的相关信息。本隐私政策（“本政策”）旨在向您说明，在使用我们的网站和我们的服务时，我们如何收集、存储、使用、管理和保护您的用户信息（包括个人信息）。以及我们为您提供的访问、更新、控制和保护这些信息的方式。
                <br />
                <br />
                本隐私政策与您息息相关，希望您仔细阅读。如果您不同意本隐私权政策，我们无法为您提供完整的服务，您应当立即停止使用我们的网站及相关服务。当您选择使用我们的网站及相关服务时，将视为您接受和认可我们按照本隐私权政策对您的相关信息进行处理。
                如您对本政策或任何隐私权相关问题有任何疑问，请通过panx@panxsoft.com与我们联系。
              </div>
            </div>
            <div class="text_box">
              <div class="title">一、我们收集哪些信息</div>
              <div class="text_1">
                为了向您提供我们的网站和我们的服务，我们将在法律允许的范围内和当且仅当您浏览网站、使用某些功能或者服务时，根据本政策规定收集您的个人信息和用户信息。如果您不提供这些信息，我们可能无法向您提供我们的产品或服务。
              </div>
            </div>
            <div class="text_box">
              <div class="title">二、您提供给我们的信息</div>
              <div class="text">
                在您使用我们的网站和我们的服务时，尤其是使用云服务平台服务时，我们可能会建议您注册一个帐号，以方便您充分利用我们的网站、我们的服务及其优势。当您注册帐号时，我们会收集您所提供的可识别您身份的信息，这些信息包括您的帐号名称、移动电话号码等注册帐号所必须的信息。
              </div>
              <div class="text_tit">（1）设备信息</div>
              <div class="text">
                当您在移动设备上使用我们的服务时，我们收集与您设备相关的信息。例如，国际移动电话设备识别码（“IMEI”）、广告标识符（“IDFA”与“IDFV”）、集成电路卡识别码（“ICCID”）和介质访问控制（“MAC”）地址、设备型号与设备分辨率，方便我们统计使用我们产品与服务的设备数量并进行设备机型数据分析与图形适配分析。
              </div>
              <div class="text_tit">（2）网络信息</div>
              <div class="text">
                当您在连网状态下使用我们的网站或我们的服务时，我们收集您所使用的运营商名称与网络类型，目的是为了解用户的运营商和网络分布的情况，此外，我们还收集接入WIFI名称与WIFI接入时间以了解您使用服务的用户场景。
              </div>
              <div class="text_tit">（3）位置信息</div>
              <div class="text">
                我们收集您的位置信息，如国家代码、经纬度信息、网络位置、IP地址和系统设置国家及时区信息。这些信息有助于我们了解用户分布和用户使用场景，使我们可以为其提供正确的应用程序版本及更优质的服务。除本政策另有约定外，我们不会与任何第三方分享这些信息。如果您不再希望我们跟踪或使用具体位置信息，您可以在选择在设备上关闭网络、GPS功能或者禁用我们获取您网络、GPS、设备信息权限。
              </div>
              <div class="text_tit">（4）日志信息</div>
              <div class="text">
                当您使用某些我们的服务，包括参与、生成、浏览与我们的服务相关的内容时，我们将会自动收集某些相关的日志信息，这些信息包括：(1)关于您的使用时间与使用频率的细节；(2)设备统计信息、关键操作路径、错误日志、崩溃记录、语言和时区等。这些日志信息能够帮助我们更好的了解和改善我们的服务，并非用于识别任何特定的个人。
              </div>
              <div class="text_tit">（5）图片信息</div>
              <div class="text">
                当您选择使用我们网站中的云服务时，请您理解并且同意，为实现向您提供该项服务并作持续优化之目的，我们须将您的图片上传至我们的服务器进行处理完毕后再将处理后图片返回给您。
              </div>
              <div class="text_tit">（6）元数据</div>
              <div class="text">
                元数据通常是与用户内容相关联的技术数据，简而言之，它是描述数据的数据。例如，它可以描述图片（或同类文件）中包含的地理位置信息及文件创建时间，图片中包含的粗略人物特征和图片中包含的内容。当您在使用我们的服务时，我们将利用照片元数据为您提供更精准的“人脸检测”，“关键点标记”，“区域分割”，“内容标签”以及由这些基础功能组合而成的其他定制功能。正常情况下，这些元数据无法用于识别任何特定的个人。这些元数据是为了改进服务目的，并非用于识别任何特定的个人。
              </div>
              <div class="text_tit">（7）Cookie</div>
              <div class="text">
                只在您浏览我们的网站时，帕克西或帕克西委托合作第三方使用cookie、标签和脚本等技术，收集日志文件和本地缓存信息，用于分析趋势、管理网站、追踪用户的网站活动并收集关于整个用户群的人口统计信息。日志文件信息可能包括互联网协议
                (IP) 地址、浏览器类型、互联网服务供应商
                (ISP)、引用/退出页面、操作系统、日期/时间戳和点击流数据。
              </div>
              <div class="text_tit">（8）用户体验计划收集的信息</div>
              <div class="text">
                我们可能在我们产品中发布相应的用户体验计划，旨在帮助改善产品技术与服务、提升用户体验。您可选择参与并且随时退出。当您同意参与相应产品的用户体验计划之后直至您选择退出该计划之前，我们将根据相应用户体验计划收集您的信息。这些信息为了帮助我们更好的了解和改善我们的技术和服务，并非用于识别任何特定的个人。
              </div>
            </div>
            <div class="text_box">
              <div class="title">三、我们如何使用信息</div>
              <div class="text">
                收集用户信息和个人信息的目的在于向您提供我们的产品和服务，并且保证我们遵守适用法律。在“我们收集哪些信息”章节基础上，我们将您的用户信息与个人信息用于下列目的：
              </div>
              <div class="text">
                （1）产品服务。提供、处理、维护、改善、开发我们的网站和/或提供给您的我们的服务，包括客户支持，以及通过设备或网站提供的其他服务。
              </div>
              <div class="text">
                （2）
                统计分析。分析和开发与我们产品及我们的服务的使用相关的统计信息，以更好地改进我们的产品和服务。
              </div>
              <div class="text">
                （3）
                创建您的账号。在通过我们的网站或我们的服务创建账号时收集的个人信息用于建立用户的个人账户和资料页。
              </div>
              <div class="text">
                （4）
                提供基于位置的服务。在使用我们的服务时，我们或第三方服务供应商可能会使用位置信息以为您提供正确版本的服务，并且提供准确的位置，以获得尽可能好的用户体验。
              </div>
              <div class="text">
                （5）
                改善用户体验。某些选择性加入功能，例如用户体验计划，便于我们分析用户如何使用我们产品和服务的数据，以改善用户体验。
              </div>
              <div class="text">
                （6）
                验证用户身份。帐号登录时短信验证，确保未经授权的人员无法登录。
              </div>
              <div class="text">
                （7）
                收集用户反馈。您的反馈意见对帮助帕克西改进服务而言极为珍贵。为了跟踪您的反馈，我们可能会使用您所提供的个人信息与您联系，并保留记录。
              </div>
              <div class="text">
                （8）
                其他。储存并维护与您相关的信息，用于我们运营业务或履行法律义务。
              </div>
            </div>
            <div class="text_box">
              <div class="title">四、信息分享与披露</div>
              <div class="text">
                我们不会将任何个人信息出售给第三方。除非下列情形发生时，我们无权向第三方共享和/或披露您的全部或部分个人信息：
              </div>
              <div class="text">（1）为保护他人的人身或财产安全；</div>
              <div class="text">（2）为防止或解决网络欺诈；</div>
              <div class="text">（3）维护帕克西公司的合法权利及权益；</div>
              <div class="text">
                （4）实现“我们收集哪些信息”部分所述目的，前提条件为该等披露接收方应同等接受本政策约束并可以提供信息充分保护；
              </div>
              <div class="text">
                （5）根据法律、法规的规定，或遵从政府部门、司法机关的要求；通常前述要求都是为了保护网络安全和保护我们、我们的用户和第三方的权利、财产和安全。
              </div>
            </div>
            <div class="text_box">
              <div class="title">五、信息安全保障</div>
              <div class="text">
                我们使用合理措施，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术（例如SSL）来保护您的个人信息。但请您理解（在互联网行业）即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
              </div>
            </div>
            <div class="text_box">
              <div class="title">六、信息访问与控制</div>
              <div class="text">
                我们将采取一切合理适当的技术手段，保证您可以访问、更新和更正自己的个人信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述个人信息时，我们可能会要求您进行身份验证，以保障您的帐号安全。
              </div>
            </div>
            <div class="text_box">
              <div class="title">七、数据保留与删除</div>
              <div class="text">
                受限于法律法规要求，我们将按照“我们如何使用信息”章节或者为您提供服务的时间范围内保留您的信息（包括个人信息）。如果您希望撤销同意收集、使用披露我们收集的您的个人信息或者希望我们删除您的个人信息，请发送电子邮件至panx@panxsoft.com。我们将会在收到您的电子邮件后的合理时间内处理您的请求，并且会根据您的请求，在此之后不再收集、使用、披露您的个人信息。请注意，您撤销同意或者删除您的个人信息后，可能会影响您使用某些我们的服务。
              </div>
            </div>
            <div class="text_box">
              <div class="title">八、儿童信息</div>
              <div class="text">
                我们的所有产品、网站和服务主要面向企业客户，我们通常不会自行主动收集、处理儿童个人信息。如任何客户或用户希望或意图向我们提供或要求我们处理儿童的个人信息，请严格按照相关法律法规的要求，确保已取得儿童监护人的事先同意授权。对于经父母或监护人同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。为免歧义，我们将不满14周岁的任何人均视为儿童。
              </div>
            </div>
            <div class="text_box">
              <div class="title">九、本政策更新</div>
              <div class="text">
                我们保留不时更新或修改本政策的权利。但未经您明确同意，我们不会削减您按照本政策所应享有的权利。您可通过本页面查看最新版本的本政策。
                对于重大变更，我们会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件等方式发送通知，说明具体的变更内容）。前述“重大变更”包括但不限于：
              </div>
              <div class="text">
                （1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
              </div>
              <div class="text">
                （2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；
              </div>
              <div class="text">
                （3）个人信息共享、转让或公开披露的主要对象发生变化；
              </div>
              <div class="text">
                （4）您参与个人信息处理方面的权利及其行使方式发生重大变化；
              </div>
              <div class="text">
                （5）
                我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时。
              </div>
            </div>
            <div class="text_box">
              <div class="title">十、联系我们</div>
              <div class="text">
                我公司为在中华人民共和国境内成立的【广州帕克西软件开发有限公司】。注册地址：广东省广州市天河区黄村路57号（部位：502）。
                如果您对本政策内容有任何疑问、意见、建议或进行申诉，请通过panx@panxsoft.com与我们联系。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  metaInfo () {
    return {
      title: this.headMetaInfo.title,
      meta: [
        { name: 'keywords', content: this.headMetaInfo.keywords },
        { name: 'discription', content: this.headMetaInfo.content }
      ]
    }
  },
  data () {
    return {
      headMetaInfo: {
        title: '广州帕克西软件开发有限公司',
        keywords: '',
        content: ''
      }
    }
  },
  created () {
    this.$emit('commandEvent', 'header-banner', [])
  },
  mounted () {
    this.headMetaInfo.title = this.$global.head_title
    this.headMetaInfo.keywords = this.$global.head_keywords
    this.headMetaInfo.content = this.$global.head_description
    this.$emit('commandEvent', 'header-banner', [])
  }
}
</script>

<style lang="less" scoped>
body,
html,
textarea {
  font-family: PingFangSC, FZLTXHK, arial, "Hiragino Sans GB", "Microsoft YaHei",
    "WenQuanYi Micro Hei", sans-serif;
  font-size: 14px;
}
.privacy_policy-container {
  .privacy_policy-box-pc {
    width: 100%;
    padding-top: 60px;
    .pc .item_box {
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 40px;
      padding-right: 40px;
    }
    .bgc_F9FBFD {
      background: #f9fbfd !important;
    }
    .pad_t_80 {
      padding-top: 80px;
    }
    .pad_b_80 {
      padding-bottom: 80px;
    }
    .line {
      width: 3px;
      height: 18px;
      background: #0e57a2;
    }
    .pc .item_box .tit_box {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pc .item_box .tit_box .tit_text {
      font-size: 30px;
      color: #181818;
      margin: 0 20px;
    }
    .mar_b_50 {
      margin-bottom: 50px;
    }
    .text_box .text,
    .text_box .text_1 {
      font-size: 16px;
      color: #181818;
      line-height: 26px;
    }
    .type_page {
      width: 1200px;
    }
    .title {
      font-size: 24px;
      margin-top: 40px;
      margin-bottom: 30px;
    }
    .text {
      margin-bottom: 20px;
    }
    .text_7 {
      font-size: 16px;
      color: #181818;
      line-height: 26px;
      margin-top: 20px;
    }
    .text_tit {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
  .privacy_policy-box-mobile {
    width: 100%;
    .pc .item_box {
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 40px;
      padding-right: 40px;
    }
    .bgc_F9FBFD {
      background: #f9fbfd !important;
    }
    .pad_t_80 {
      padding-top: 80px;
    }
    .pad_b_80 {
      padding-bottom: 80px;
    }
    .line {
      width: 3px;
      height: 18px;
      background: #0e57a2;
    }
    .pc .item_box .tit_box {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pc .item_box .tit_box .tit_text {
      font-size: 30px;
      color: #181818;
      margin: 0 20px;
    }
    .mar_b_50 {
      margin-bottom: 50px;
    }
    .text_box .text,
    .text_box .text_1 {
      font-size: 16px;
      color: #181818;
      line-height: 26px;
    }
    .type_page {
      width: calc(100% - 20px);
    }
    .title {
      font-size: 24px;
      margin-top: 40px;
      margin-bottom: 30px;
    }
    .text {
      margin-bottom: 20px;
    }
    .text_7 {
      font-size: 16px;
      color: #181818;
      line-height: 26px;
      margin-top: 20px;
    }
    .text_tit {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
}
</style>
