<template>
  <div :class="$global.isMobile ? 'TopControl-mobile' : 'TopControl-pc'" id="TopControl">
    <div>
      <div class="ContactUs-panel" :style="showContactUsContent ? 'display:block' : 'display:none'">
        <div class="ContactUs-panel-content">
          <ul>
            <li>
              <img src="../../assets/images/Common/contactus_1_1.png">
              <span>固定电话：020-82520103</span>
            </li>
            <li>
              <img src="../../assets/images/Common/contactus_1_2.png">
              <span>手机/微信：18925177752</span>
            </li>
            <li>
              <img src="../../assets/images/Common/contactus_1_3.png">
              <span>QQ：3239966704</span>
            </li>
            <li>
              <img src="../../assets/images/Common/contactus_1_4.png">
              <span>邮箱：panx@panxsoft.com</span>
            </li>
          </ul>
        </div>
        <img src="../../assets/images/Common/contactus_1_bgimg.png">
      </div>
      <div
        class="ContactUs-button"
        @mouseenter.stop="$global.isMobile ? (() => {}): (showContactUsContent = true)"
        @mouseout.stop="$global.isMobile ? (() => {}): (showContactUsContent = false)"
        @click.stop="$global.isMobile ? (showContactUsContent = !showContactUsContent) : (() => {})"
      >
        <img src="../../assets/images/Common/contactus_1.png">
      </div>
    </div>
    <div class="Top-button"  :style="showTopButton ? 'display:block' : 'display:none'" @click="onTopClick">
      <img src="../../assets/images/Common/top_1.png">
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      showContactUsContent: false,
      showTopButton: false
    }
  },
  created () { },
  mounted () {
    // scroll
    window.removeEventListener('scroll', this.windowScroll)
    window.addEventListener('scroll', this.windowScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.windowScroll)
  },
  methods: {
    windowScroll: function () {
      /* document.body.clientHeight:整个页面的高度 */
      /* document.documentElement.clientHeight:可见高度 */
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const offsetTop = document.getElementById('TopControl').offsetTop
      if ((offsetTop === 0 && scrollTop > 500) || ((offsetTop - 300) < scrollTop)) {
        this.showTopButton = true
      } else {
        this.showTopButton = false
      }
    },
    onTopClick: function () {
      var topInterval = setInterval(function () {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        if (scrollTop === 0) {
          clearInterval(topInterval)
        } else {
          window.scroll(0, scrollTop - 30)
        }
      }, 3)
    }
  }
}
</script>

<style lang="less" scoped>
.TopControl-pc {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 999;
  .ContactUs-button,.Top-button {
    position: relative;
    width:60px;
    height:62px;
    cursor:pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.TopControl-mobile {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 999;
  .ContactUs-button,.Top-button {
    position: relative;
    width: calc(60px * 0.8);
    height: calc(62px * 0.8);
    cursor:pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.ContactUs-panel {
  position: absolute;
  float: left;
  width: 284px;
  height: 189px;
  left: -265px;
  top: -105px;
  .ContactUs-panel-content {
    position: absolute;
    margin-top: 38px;
    margin-left: 32px;
    font-size: 14px;
    font-weight: 600;
    line-height: 27px;
    img {
      margin-top: 7px;
      margin-right: 8px;
      width: 14px;
      height: 14px;
    }
  }
}
</style>
