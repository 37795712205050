<template>
  <div class="AboutUs">
    <div v-if="localResults">
      <!-- 公司简介 -->
      <div :class="$global.isMobile ? 'about-us-companyProfile-mobile' : 'about-us-companyProfile-pc'">
        <div class="about-us-companyProfile-content">
          <div class="about-us-companyProfile-content-title"> {{ localResults["name"] }} </div>
          <div class="about-us-companyProfile-content-list">
            <div class="about-us-companyProfile-content-list-left">
              <div class="about-us-companyProfile-content-list-left-placeholder" @click.stop="onVideoPlayClick()"></div>
              <video id="about-us-companyProfile-content-list-left-video"
                :width="videoSize.width"
                :height="videoSize.height"
                :src="localResults['thumb']"
                controls="controls"
                controlslist="nodownload noremoteplayback"
              >
              </video>
              <div class="about-us-companyProfile-content-list-left-play" @click.stop="onVideoPlayClick()"></div>
            </div>
            <div class="about-us-companyProfile-content-list-right"> {{ localResults["content"] }} </div>
          </div>
        </div>
      </div>
      <!-- 企业精神、企业愿景、企业使命 -->
      <div
        :class="$global.isMobile ? 'about-us-companyForward-mobile' : 'about-us-companyForward-pc'"
        v-if="localResults['company_culture'] && (localResults['company_culture'].length > 0)"
      >
        <div class="about-us-companyProfile-content">
          <div class="about-us-companyProfile-content-list">
            <ul>
              <li v-for="(item, index) in localResults['company_culture']" :key="index">
                <div class="about-us-companyProfile-content-list-image"><img :src="item.thumb" /></div>
                <div class="about-us-companyProfile-content-list-title"> {{ item.title }} </div>
                <div class="about-us-companyProfile-content-list-content"> {{ item.brief }} </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- 技术实力 -->
      <div
        :class="$global.isMobile ? 'about-us-technicalStrength-mobile' : 'about-us-technicalStrength-pc'"
        v-if="localResults['technicals'] && (localResults['technicals'].length > 0)"
      >
        <div class="about-us-technicalStrength-content">
          <div class="about-us-technicalStrength-content-title"> {{ localResults["technicals"][0].name }} </div>
          <div class="about-us-technicalStrength-content-content"> {{ localResults["technicals"][0].content }} </div>
          <div class="about-us-technicalStrength-content-images">
            <!--
            <div v-for="(item, index) in localResults.technicals[0].images" :key="index">
              <img :src="item.thumb" />
            </div>
            -->
            <!--
            <img class="about-us-technicalStrength-content-image01" src="../../assets/images/AboutUs/technicalStrength1.png" />
            <img class="about-us-technicalStrength-content-image02" src="../../assets/images/AboutUs/technicalStrength2.png" />
            <img class="about-us-technicalStrength-content-image03" src="../../assets/images/AboutUs/technicalStrength3.png" />
            <img class="about-us-technicalStrength-content-image04" src="../../assets/images/AboutUs/technicalStrength4.png" />
            <img class="about-us-technicalStrength-content-image05" src="../../assets/images/AboutUs/technicalStrength5.png" />
            <img class="about-us-technicalStrength-content-image06" src="../../assets/images/AboutUs/technicalStrength6.png" />
            <img class="about-us-technicalStrength-content-image07" src="../../assets/images/AboutUs/technicalStrength7.png" />
            <img class="about-us-technicalStrength-content-image08" src="../../assets/images/AboutUs/technicalStrength8.png" />
            <img class="about-us-technicalStrength-content-image09" src="../../assets/images/AboutUs/technicalStrength9.png" />
            <img class="about-us-technicalStrength-content-image10" src="../../assets/images/AboutUs/technicalStrength10.png" />
            <img class="about-us-technicalStrength-content-image11" src="../../assets/images/AboutUs/technicalStrength11.png" />
            -->
            <img :src="localResults['technicals'][0]['thumb']" />
          </div>
        </div>
      </div>
      <!-- 团队风采 -->
      <div
        :class="$global.isMobile ? 'about-us-teamElegantDemeanour-mobile' : 'about-us-teamElegantDemeanour-pc'"
        v-if="localResults['team_style'] && (localResults['team_style'].length > 0)"
      >
        <div class="about-us-teamElegantDemeanour-content">
          <div class="about-us-teamElegantDemeanour-content-title">团队风采</div>
          <div class="about-us-teamElegantDemeanour-content-carousel">
            <div class="swiper-containers">
              <div class="swiper-wrapper">
                <swiper :options="swiperTeam">
                  <swiper-slide v-for="(item, index) in localResults['team_style']" :key="index">
                    <img :src="item.thumb" alt="" />
                    <div class="about-us-teamElegantDemeanour-content-carousel-title">{{ item.title }}</div>
                  </swiper-slide>
                </swiper>
              </div>
              <!-- 如果需要分页器 -->
              <!-- <div class="swiper-pagination"></div> -->
              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev swiper-button-black"></div>
              <div class="swiper-button-next swiper-button-black"></div>
              <div class="bg-left"></div>
              <div class="bg-right"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 发展历程 -->
      <div
        :class="$global.isMobile ? 'about-us-developmentHistory-mobile' : 'about-us-developmentHistory-pc'"
        v-if="localResults['courses'] && localResults['courses'].length > 0"
      >
        <div class="about-us-developmentHistory-bgimg"><img src="../../assets/images/AboutUs/bg.png" /></div>
        <div class="about-us-developmentHistory-content">
          <div class="about-us-developmentHistory-content-title">发展历程</div>
          <div class="about-us-developmentHistory-content-subscript">
            <ul>
              <li v-for="(item, index) in localResults.courses" :key="index">
                <div v-if="index!=(localResults.courses.length-1)">{{item.name.replace('年','')}}</div>
                <div v-else>至今</div>
              </li>
            </ul>
            <ul class="developmentHistory-index-list" id="developmentHistory-index-list" :value="swiperCourses.slidesPerView">
              <li v-for="(item, index) in localResults.courses" :key="index">
                <div v-if="index!=(localResults.courses.length-1)">
                  <span></span>
                  <i></i>
                </div>
                <div v-else>
                  <span></span>
                  <i class="nones"></i>
                </div>
              </li>
            </ul>
          </div>
          <div id="about-us-developmentHistory-content-certify">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide swiper-wrapper-background-color">
                  <div class="swiper-slide-content">
                    <swiper :options="swiperCourses">
                      <swiper-slide v-for="(item, index) in localResults.courses" :key="index">
                        <div class="swiper-slide-content-list-title">
                          <span>{{item.name}}</span>
                          <i></i>
                        </div>
                        <div class="swiper-slide-content-list-panel" v-for="(i_item, i_index) in item['deeds']" :key="i_index">
                          <div class="swiper-slide-content-list-panel-left">{{i_item.month}}</div>
                          <div class="swiper-slide-content-list-panel-right">{{i_item.brief}}</div>
                        </div>
                      </swiper-slide>
                    </swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 联系我们 -->
      <div :class="$global.isMobile ? 'about-us-contactUs-mobile' : 'about-us-contactUs-pc'">
        <div class="about-us-contactUs-content">
          <div class="about-us-contactUs-content-title">联系我们</div>
          <div class="about-us-contactUs-content-list">
            <div class="about-us-contactUs-content-list-left">
              <img src="../../assets/images/AboutUs/ditu.png" alt="">
            </div>
            <div class="about-us-contactUs-content-list-right">
              <ul>
                <li>公司：广州帕克西软件开发有限公司</li>
                <li>地址：广州市天河区黄村路57号粤安工业园B栋502</li>
                <li>电话：020-82520103</li>
                <li>商务：18922408289（微信同号）</li>
                <li>网址：www.panxsoft.com</li>
                <li>邮箱：panx@panxsoft.com</li>
                <li>在线QQ：3239966704</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <NullPage />
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import NullPage from '@/components/NullPage'
export default {
  /* 关于我们-公司介绍 */
  name: 'AboutUs',
  components: {
    swiper,
    swiperSlide,
    NullPage
  },
  metaInfo () {
    return {
      title: this.headMetaInfo.title,
      meta: [
        { name: 'keywords', content: this.headMetaInfo.keywords },
        { name: 'discription', content: this.headMetaInfo.content }
      ]
    }
  },
  data () {
    return {
      headMetaInfo: {
        title: '广州帕克西软件开发有限公司',
        keywords: '',
        content: ''
      },
      localResults: [],
      videoSize: {
        width: this.$global.isMobile ? document.documentElement.style.getPropertyValue('--v-width') : 606,
        height: this.$global.isMobile ? '' : 342
      },
      swiperTeam: {
        loop: true,
        autoplay: {
          delay: 2000 // 1秒切换一次
        },
        slidesPerView: 3,
        slidesPerColumn: 1, // 显示2行
        spaceBetween: 10,
        centeredSlides: true,
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperCourses: {
        loop: true,
        autoplay: {
          delay: 2000 // 1秒切换一次
        },
        slidesPerView: this.$global.isMobile ? 1 : 3, // 要跟on.transitionEnd里设置对应
        slidesPerColumn: 1, // 显示2行
        centeredSlides: true,
        effect: 'coverflow',
        coverflowEffect: {
          rotate: 0,
          stretch: 0,
          depth: 250, // depth: 300,
          modifier: 2,
          slideShadows: true
        },
        // 显示分页
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许分页点击跳转
        },
        on: {
          click: function () {
            /* console.log(this.activeIndex, this.realIndex, this.clickedIndex) */
          },
          transitionEnd: function (event) {
            var activeIndex = this.activeIndex // 滑动到第几张
            /* console.log('activeIndex', activeIndex) */
            /*
            $('.developmentHistory-index-list li span').css({
              "background": "none"
            })
            if (activeIndex === 10) { activeIndex = 3 }
            $('.developmentHistory-index-list li:eq('+(activeIndex-3)+')  span').css({
               "background": "antiquewhite"
            })
            */
            var developmentHistoryIndexlist = document.getElementById('developmentHistory-index-list')
            // activeIndex -= 3
            activeIndex -= developmentHistoryIndexlist.getAttribute('value')
            if (developmentHistoryIndexlist) {
              var liSpan = developmentHistoryIndexlist.querySelectorAll('li span')
              if (liSpan) {
                if (activeIndex >= liSpan.length) { activeIndex = 0 }
                for (var i = 0; i < liSpan.length; i++) {
                  if (activeIndex !== i) {
                    liSpan[i].style.background = 'none'
                  } else {
                    liSpan[i].style.background = 'antiquewhite'
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  computed: { },
  created () { },
  mounted () {
    this.headMetaInfo.title = this.$global.head_title
    this.headMetaInfo.keywords = this.$global.head_keywords
    this.headMetaInfo.content = this.$global.head_description
    if (this.$route.params.id) {
      this.nav_id = this.$route.params.id
    } else if (this.$route.query.id) {
      this.nav_id = this.$route.query.id
    } else {
      this.nav_id = -1
    }
    if (this.nav_id !== -1) {
      this.$axios({
        url: 'api/about-us/' + this.nav_id,
        method: 'get'
      }).then((res) => {
        this.localResults = null
        if (res.data) {
          if (res.data.result && res.data.result.length > 0) {
            this.localResults = res.data.result[0]
            // console.log(this.localResults)
            /* 设置Header的banner图 */
            if (this.localResults.banner) {
              this.$emit('commandEvent', 'header-banner', [{ thumb: this.localResults.banner }])
            } else {
              this.$emit('commandEvent', 'header-banner', null)
            }
            if (this.localResults) {
              this.$global.onUpdateHeadTitle('关于我们-公司介绍-广州帕克西软件开发有限公司')
              this.headMetaInfo.title = '关于我们-公司介绍-广州帕克西软件开发有限公司'
              const headKeywords = ['关于我们', '公司介绍', '公司简介', '技术实力', '团队风采', '发展历程', '联系我们']
              this.$global.onUpdateHeadKeywords(headKeywords)
              this.headMetaInfo.keywords = this.$global.getHeadKeywords(headKeywords)
              if (this.localResults.content) {
                const content = this.localResults.content.trim().replace(/\s+|&nbsp;/ig, '')
                this.$global.onUpdateHeadDescription(content)
                this.headMetaInfo.content = content
              }
            }
          } else {
            this.$emit('commandEvent', 'header-banner', null)
          }
        } else {
          this.$emit('commandEvent', 'header-banner', null)
        }
      })
    } else {
      console.log('跳转首页')
      if (window.location.href.indexOf('localhost') === -1) { // 要加上这句判断，否则打包有问题，缺少按照路由文件夹打包
        this.$router.push({ path: '/' })
      } else {
        this.localResults = null
        this.$emit('commandEvent', 'header-banner', null)
      }
    }
  },
  methods: {
    onVideoPlayClick: function () {
      const video = document.querySelector('video')
      if (video.paused) {
        video.play()
      } else {
        video.pause()
      }
    },
    onSwiperChanged (e) {
      // console.log(e)
    }
  }
}
</script>

<style lang="less" scoped>
@padding: 80px 0px 0px 0px;
@title: 32px;
@fontWeight: 700;
.AboutUs {
  width: 100%;
  overflow: hidden;
  text-align: center;
  color: #333;
  font-size: var(--v-font-size-14);
  .about-us-companyProfile-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .about-us-companyProfile-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 50px 0;
      .about-us-companyProfile-content-title {
        font-size: @title;
        font-weight: @fontWeight;
        padding: @padding;
      }
      .about-us-companyProfile-content-list {
        width: var(--v-width);
        margin: 0 auto;
        overflow: hidden;
        padding: 80px 0px 50px 0px;
        .about-us-companyProfile-content-list-left {
          float: left;
          width: 606px;
          height: 342px;
          position: relative;
          cursor: pointer;
          .about-us-companyProfile-content-list-left-placeholder {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 19;
          }
          .about-us-companyProfile-content-list-left-play {
            z-index: 99;
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100px;
            height: 100px;
            margin: -50px 0 0 -50px;
            background: url(../../assets/images/ProductCenter/bigplay.svg)
              no-repeat;
          }
          .about-us-companyProfile-content-list-left-play:hover {
            background-position-y: -100px;
          }
        }
        .about-us-companyProfile-content-list-right {
          float: left;
          width: calc(100% - 606px - 60px);
          height: calc(342px - 100px);
          font-size: 16px;
          font-weight: 400;
          text-align: left;
          color: #666666;
          line-height: 36px;
          padding: 50px 30px;
        }
      }
    }
  }
  .about-us-companyProfile-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .about-us-companyProfile-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 30px 0px 10px 0px;
      .about-us-companyProfile-content-title {
        font-size: var(--v-font-size-32);
        font-weight: @fontWeight;
        margin: 0px auto 10px auto;
      }
      .about-us-companyProfile-content-list {
        width: var(--v-width);
        margin: 0 auto;
        overflow: hidden;
        padding: 10px 0px 10px 0px;
        .about-us-companyProfile-content-list-left {
          width: 100%;
          //height: 342px;
          position: relative;
          cursor: pointer;
          .about-us-companyProfile-content-list-left-placeholder {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 19;
          }
          .about-us-companyProfile-content-list-left-play {
            z-index: 99;
            display: none;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100px;
            height: 100px;
            margin: -50px 0 0 -50px;
            background: url(../../assets/images/ProductCenter/bigplay.svg)
              no-repeat;
          }
          .about-us-companyProfile-content-list-left-play:hover {
            background-position-y: -100px;
          }
        }
        .about-us-companyProfile-content-list-right {
          width: calc(var(--v-width) - 40px);
          font-size: var(--v-font-size-16);
          font-weight: 400;
          text-align: left;
          color: #666666;
          line-height: 24px;
          margin: 10px 20px;
        }
      }
    }
  }
  .about-us-companyForward-pc {
    width: 100%;
    overflow: hidden;
    background-color: #f6f6f6;
    .about-us-companyProfile-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      height: 239px;
      .about-us-companyProfile-content-list {
        text-align:center;
        ul {
          display: inline-block;
          margin: 0 auto;
          li {
            float: left;
            width: 400px;
            padding: 34px 0px;
            text-align: center;
            .about-us-companyProfile-content-list-image {
              width: 58px;
              height: 58px;
              text-align: center;
              margin: 0 auto;
              img {
                height: 100%;
              }
            }
            .about-us-companyProfile-content-list-title {
              font-size: 20px;
              font-weight: 700;
              text-align: center;
              margin: 10px auto 20px auto;
            }
            .about-us-companyProfile-content-list-content {
              width: 216px;
              font-size: 16px;
              text-align: center;
              color: #666666;
              line-height: 30px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
  .about-us-companyForward-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #f6f6f6;
    .about-us-companyProfile-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      //height: 239px;
      .about-us-companyProfile-content-list {
        text-align:center;
        ul {
          display: inline-block;
          margin: 0 auto;
          li {
            float: left;
            width: calc(var(--v-width) / 3 - 20px);
            padding: 10px 5px;
            text-align: center;
            .about-us-companyProfile-content-list-image {
              width: 42px;
              height: 42px;
              text-align: center;
              margin: 0 auto;
              img {
                height: 100%;
              }
            }
            .about-us-companyProfile-content-list-title {
              font-size: var(--v-font-size-20);
              font-weight: 700;
              text-align: center;
              margin: 8px auto 8px auto;
            }
            .about-us-companyProfile-content-list-content {
              width: calc(var(--v-width) / 3 - 20px);
              font-size: var(--v-font-size-14);
              text-align: center;
              color: #666666;
              line-height: 20px;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
  .about-us-technicalStrength-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .about-us-technicalStrength-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 100px 0 80px 0;
      .about-us-technicalStrength-content-title {
        font-size: 32px;
        font-weight: 700;
        text-align: center;
      }
      .about-us-technicalStrength-content-content {
        font-size: 16px;
        font-weight: 400;
        text-align: center;
        color: #666666;
        line-height: 30px;
        margin: 30px 0;
      }
      .about-us-technicalStrength-content-images {
        position: relative;
        width: 100%;
        img {
          height: 100%;
        }
        /*
        .about-us-technicalStrength-content-image01 {
          width: 76px;
          height: 107px;
          position: absolute;
          left: 64px;
          top: calc(50% - 37px);
        }
        .about-us-technicalStrength-content-image02 {
          width: 109px;
          height: 77px;
          position: absolute;
          left: 162px;
          top: 105px;
        }
        .about-us-technicalStrength-content-image03 {
          width: 111px;
          height: 157px;
          position: absolute;
          top: 203px;
          left: 162px;
        }
        .about-us-technicalStrength-content-image04 {
          width: 113px;
          height: 80px;
          position: absolute;
          left: 162px;
          top: 383px;
        }
        .about-us-technicalStrength-content-image05 {
          width: 141px;
          height: 200px;
          position: absolute;
          left: 296px;
          top: 56px;
        }
        .about-us-technicalStrength-content-image06 {
          width: 141px;
          height: 200px;
          position: absolute;
          top: 279px;
          left: 296px;
        }
        .about-us-technicalStrength-content-image07 {
          width: 317px;
          height: 448px;
          position: absolute;
          top: 41px;
          left: 460px;
        }
        .about-us-technicalStrength-content-image08 {
          width: 214px;
          height: 151px;
          position: absolute;
          top: 63px;
          left: 800px;
        }
        .about-us-technicalStrength-content-image09 {
          width: 151px;
          height: 214px;
          position: absolute;
          top: 238px;
          left: 800px;
        }
        .about-us-technicalStrength-content-image10 {
          position: absolute;
          width: 162px;
          height: 115px;
          top: 237px;
          left: 974px;
        }
        .about-us-technicalStrength-content-image11 {
          position: absolute;
          width: 118px;
          height: 83px;
          left: 974px;
          top: 370px;
        }
        */
      }
    }
  }
  .about-us-technicalStrength-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .about-us-technicalStrength-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 30px 0px 30px 0px;
      .about-us-technicalStrength-content-title {
        font-size: var(--v-font-size-32);
        font-weight: 700;
        text-align: center;
      }
      .about-us-technicalStrength-content-content {
        font-size: var(--v-font-size-16);
        font-weight: 400;
        text-align: center;
        color: #666666;
        line-height: 20px;
        margin: 20px 20px;
      }
      .about-us-technicalStrength-content-images {
        position: relative;
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
  }
  .about-us-teamElegantDemeanour-pc {
    width: 100%;
    overflow: hidden;
    background-color: #f6f6f6;
    .about-us-teamElegantDemeanour-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 80px 0 50px 0;
      .about-us-teamElegantDemeanour-content-title {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 50px;
        text-align: center;
      }
      .about-us-teamElegantDemeanour-content-carousel {
        width: 1072px;
        margin: 0 auto;
        .swiper-containers {
          width: 100%;
          position: relative;
          // --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
          // --swiper-navigation-color: #373434; /* 单独设置按钮颜色 */
          // --swiper-navigation-size: 20px; /* 设置按钮大小 */
          .swiper-slide {
            width: 262px; /*设为固定值*/
            width: auto; /*根据内容调整宽度*/
          }
          .swiper-button-prev {
            width: 36px;
            height: 36px;
            // background: #ffffff;
            border-radius: 20px;
            border: 1px solid #d1d1d1;
            font-weight: 700;
            left: -64px;
            z-index: 22;
            outline: none;
            background-size: 42%;
          }
          .swiper-button-next {
            width: 36px;
            height: 36px;
            // background: #ffffff;
            border-radius: 20px;
            border: 1px solid #d1d1d1;
            font-weight: 700;
            text-indent: 6px;
            right: -64px;
            z-index: 22;
            outline: none;
            background-size: 42%;
          }
          .bg-left {
            height: 100%;
            width: 200px;
            position: absolute;
            background-color: #f6f6f6;
            top: 0px;
            left: -200px;
            z-index: 20;
          }
          .bg-right {
            height: 100%;
            width: 200px;
            position: absolute;
            background-color: #f6f6f6;
            top: 0px;
            right: -200px;
            z-index: 20;
          }
        }
      }
      .about-us-teamElegantDemeanour-content-carousel-title {
        font-size: 18px;
        color: #333;
        margin: 10px 0;
      }
    }
  }
  .about-us-teamElegantDemeanour-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #f6f6f6;
    .about-us-teamElegantDemeanour-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 30px 0px 30px 0px;
      .about-us-teamElegantDemeanour-content-title {
        font-size: var(--v-font-size-32);
        font-weight: 700;
        margin-bottom: 20px;
        text-align: center;
      }
      .about-us-teamElegantDemeanour-content-carousel {
        width: calc(var(--v-width) * 2);
        margin: 0 10px;
        .swiper-containers {
          width: 100%;
          position: relative;
          // --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
          // --swiper-navigation-color: #373434; /* 单独设置按钮颜色 */
          // --swiper-navigation-size: 20px; /* 设置按钮大小 */
          .swiper-slide {
            width: var(--v-width); /*设为固定值*/
            width: auto; /*根据内容调整宽度*/
          }
          .swiper-button-prev {
            width: 36px;
            height: 36px;
            // background: #ffffff;
            border-radius: 20px;
            border: 1px solid #d1d1d1;
            font-weight: 700;
            left: -64px;
            z-index: 22;
            outline: none;
            background-size: 42%;
          }
          .swiper-button-next {
            width: 36px;
            height: 36px;
            // background: #ffffff;
            border-radius: 20px;
            border: 1px solid #d1d1d1;
            font-weight: 700;
            text-indent: 6px;
            right: -64px;
            z-index: 22;
            outline: none;
            background-size: 42%;
          }
          .bg-left {
            height: 100%;
            width: 200px;
            position: absolute;
            background-color: #f6f6f6;
            top: 0px;
            left: -200px;
            z-index: 20;
          }
          .bg-right {
            height: 100%;
            width: 200px;
            position: absolute;
            background-color: #f6f6f6;
            top: 0px;
            right: -200px;
            z-index: 20;
          }
        }
      }
      .about-us-teamElegantDemeanour-content-carousel-title {
        font-size: var(--v-font-size-18);
        color: #333;
        margin: 10px 0;
      }
    }
  }
  .about-us-developmentHistory-pc {
    width: 100%;
    // overflow: hidden;
    height: 513px;
    .about-us-developmentHistory-bgimg {
      width: 100%;
      min-width: 1200px;
      height: 513px;
      position: absolute;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .about-us-developmentHistory-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 80px 0 50px 0;
      .about-us-developmentHistory-content-title {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 50px;
        text-align: center;
        color: #fff;
        position: relative;
      }
      .about-us-developmentHistory-content-subscript {
        color: #fff;
        position: relative;
        text-align: center;
        margin-left: 84px;
        ul {
          display: inline-block;
          margin: 0 auto;
          li {
            width: 100px;
            float: left;
          }
        }
        ul:nth-child(1) li {
          // margin-right: 84px;
          text-align: left;
        }
        ul:nth-child(2) {
          margin-top: 10px;
        }
        ul:nth-child(2) li span {
          width: 22px;
          height: 22px;
          margin-right: 84px;
          border: 2px solid #fff;
          border-radius: 20px;
          display: block;
          cursor: pointer;
        }
        ul:nth-child(2) li {
          position: relative;
        }
        ul:nth-child(2) i {
          display: block;
          width: 76px !important;
          height: 1px;
          background-color: #ffffff;
          margin: 0 20px;
          position: absolute;
          top: 12.5px;
          left: 6px;
        }
      }
    }
    #about-us-developmentHistory-content-certify {
      width: var(--v-width);
      margin: 0 auto;
      top: 50px;
      position: relative;
      .swiper-container {
        .swiper-wrapper {
          .swiper-slide {
            // width: 469px ;
            height: 327px !important;
            // padding-right: 30px;
            .swiper-slide-content {
              .swiper-slide-content-list-title {
                width: 100px;
                height: 27px;
                position: relative;
                margin: 30px auto 20px auto;
                span {
                  width: 62px;
                  height: 27px;
                  font-size: 27px;
                  font-weight: 700;
                  text-align: center;
                }
                i {
                  width: 26px;
                  height: 8px;
                  background: #ea6634;
                  position: absolute;
                  bottom: -15px;
                  left: calc(50% - 13px);
                }
              }
              .swiper-slide-content-list-panel {
                padding-top: 15px;
                overflow: hidden;
                .swiper-slide-content-list-panel-left {
                  float: left;
                  width: 80px;
                  height: 40px;
                  text-align: center;
                  font-size: 18px;
                  text-align: center;
                  color: #666666;
                }
                .swiper-slide-content-list-panel-right {
                  float: left;
                  width: calc(100% - 120px);
                  text-align: left;
                  height: 40px;
                  font-size: 14px;
                  color: #666666;
                  line-height: 20px;
                }
              }
            }
          }
        }
        .swiper-wrapper-background-color {
          background-color: transparent;
        }
      }
    }
    #about-us-developmentHistory-content-certify .swiper-container { /* padding-bottom: 60px; */ }
    #about-us-developmentHistory-content-certify .swiper-slide {
      // width: 469px !important;
      // height: 327px !important;
      background: #fff;
      box-shadow: 0 8px 30px #ddd;
      cursor: pointer;
    }
    #about-us-developmentHistory-content-certify .swiper-slide img {
      display: block;
    }
    #about-us-developmentHistory-content-certify .swiper-slide p {
      line-height: 98px;
      padding-top: 0;
      text-align: center;
      color: #636363;
      font-size: 1.1em;
      margin: 0;
    }
    #about-us-developmentHistory-content-certify .swiper-pagination {
      width: 100%;
      bottom: 20px;
    }
    #about-us-developmentHistory-content-certify .swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 0 5px;
      border: 3px solid #fff;
      background-color: #d5d5d5;
      width: 10px;
      height: 10px;
      opacity: 1;
      position: relative;
    }
    #about-us-developmentHistory-content-certify .swiper-pagination-bullets .swiper-pagination-bullet-active {
      border: 3px solid #00aadc;
      background-color: #fff;
    }
    #about-us-developmentHistory-content-certify .swiper-button-prev {
      left: -30px;
      width: 45px;
      height: 45px;
      background-position: 0 0;
      background-size: 100%;
    }
    #about-us-developmentHistory-content-certify .swiper-button-prev:hover {
      background-position: 0 -46px;
      background-size: 100%;
    }
    #about-us-developmentHistory-content-certify .swiper-button-next {
      right: -30px;
      width: 45px;
      height: 45px;
      background-position: 0 -93px;
      background-size: 100%;
    }
    #about-us-developmentHistory-content-certify .swiper-button-next:hover {
      background-position: 0 -139px;
      background-size: 100%;
    }
  }
  .about-us-developmentHistory-mobile {
    width: 100%;
    //overflow: hidden;
    position: relative;
    height: 273px;
    .about-us-developmentHistory-bgimg {
      width: 100%;
      min-width: 1200px;
      //height: 273px;
      position: absolute;
      // 居中
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        width: 100%;
        height: 100%;
      }
    }
    .about-us-developmentHistory-content {
      width: calc(100% - 20px);
      margin: 0 auto;
      overflow: hidden;
      padding: 30px 0px 30px 0px;
      .about-us-developmentHistory-content-title {
        font-size: var(--v-font-size-32);
        font-weight: 700;
        margin-bottom: 20px;
        text-align: center;
        color: #fff;
        position: relative;
      }
      .about-us-developmentHistory-content-subscript {
        color: #fff;
        position: relative;
        text-align: center;
        margin-left: 32px;
        overflow: auto;
        ul {
          overflow: auto;
          white-space:nowrap;
          width: auto;
          width: -moz-fit-content;
          width: -webkit-fit-content;
          width: fit-content;
          float: left;
          display: inline-block;
          margin-left: 10px;
          margin-right: 10px;
          li {
            width: 42px;
            //float: left;
            display: inline-block;
            text-align: center;
          }
        }
        ul:nth-child(1) li {
          text-align: left;
        }
        ul:nth-child(2) {
          margin-top: 10px;
        }
        ul:nth-child(2) li span {
          width: 10px;
          height: 10px;
          margin-right: 64px;
          border: 2px solid #fff;
          border-radius: 10px;
          display: block;
          cursor: pointer;
        }
        ul:nth-child(2) li {
          position: relative;
        }
        ul:nth-child(2) i {
          display: block;
          width: 30px !important;
          height: 1px;
          background-color: #ffffff;
          margin: 0 5px;
          position: absolute;
          top: 6px;
          left: 7px;
        }
      }
    }
    #about-us-developmentHistory-content-certify {
      width: var(--v-width);
      margin: 0 auto;
      top: 20px;
      position: relative;
      .swiper-container {
        .swiper-wrapper {
          .swiper-slide {
            // width: 469px ;
            //height: 327px !important;
            height: 240px !important;
            // padding-right: 30px;
            .swiper-slide-content {
              .swiper-slide-content-list-title {
                width: 100%;
                position: relative;
                margin: 20px auto 8px auto;
                span {
                  width: 62px;
                  height: 20px;
                  font-size: var(--v-font-size-18);
                  font-weight: 700;
                  text-align: center;
                }
                i {
                  width: 26px;
                  height: 3px;
                  background: #ea6634;
                  position: absolute;
                  bottom: -8px;
                  left: calc(50% - 13px);
                }
              }
              .swiper-slide-content-list-panel {
                padding-top: 5px;
                overflow: hidden;
                .swiper-slide-content-list-panel-left {
                  float: left;
                  width: 64px;
                  height: 20px;
                  text-align: center;
                  font-size: var(--v-font-size-18);
                  text-align: center;
                  color: #666666;
                }
                .swiper-slide-content-list-panel-right {
                  float: left;
                  width: calc(100% - 100px);
                  text-align: left;
                  //height: 40px;
                  font-size: 12px;
                  color: #666666;
                  line-height: 20px;
                  position: relative;
                  top: -3px;
                  // https://www.cnblogs.com/xiaosuibu/p/8196067.html
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 3;
                  overflow: hidden;
                }
              }
            }
          }
        }
        .swiper-wrapper-background-color {
          background-color: transparent;
        }
      }
    }
    #about-us-developmentHistory-content-certify .swiper-container {
      /* padding-bottom: 60px; */
      box-shadow: 0px 3px 6px 0px rgba(123, 123, 123, 0.2);
    }
    #about-us-developmentHistory-content-certify .swiper-slide {
      // width: 469px !important;
      // height: 327px !important;
      background: #fff;
      box-shadow: 0 8px 30px #ddd;
      cursor: pointer;
    }
    #about-us-developmentHistory-content-certify .swiper-slide img {
      display: block;
    }
    #about-us-developmentHistory-content-certify .swiper-slide p {
      line-height: 98px;
      padding-top: 0;
      text-align: center;
      color: #636363;
      font-size: 1.1em;
      margin: 0;
    }
    #about-us-developmentHistory-content-certify .swiper-pagination {
      width: 100%;
      bottom: 20px;
    }
    #about-us-developmentHistory-content-certify .swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 0 5px;
      border: 3px solid #fff;
      background-color: #d5d5d5;
      width: 10px;
      height: 10px;
      opacity: 1;
      position: relative;
    }
    #about-us-developmentHistory-content-certify .swiper-pagination-bullets .swiper-pagination-bullet-active {
      border: 3px solid #00aadc;
      background-color: #fff;
    }
    #about-us-developmentHistory-content-certify .swiper-button-prev {
      left: -30px;
      width: 45px;
      height: 45px;
      background-position: 0 0;
      background-size: 100%;
    }
    #about-us-developmentHistory-content-certify .swiper-button-prev:hover {
      background-position: 0 -46px;
      background-size: 100%;
    }
    #about-us-developmentHistory-content-certify .swiper-button-next {
      right: -30px;
      width: 45px;
      height: 45px;
      background-position: 0 -93px;
      background-size: 100%;
    }
    #about-us-developmentHistory-content-certify .swiper-button-next:hover {
      background-position: 0 -139px;
      background-size: 100%;
    }
  }
  .about-us-contactUs-pc {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .about-us-contactUs-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 100px 0;
      .about-us-contactUs-content-title {
        font-size: @title;
        font-weight: @fontWeight;
        padding: 50px 0 50px 0;
      }
      .about-us-contactUs-content-list {
        width: var(--v-width);
        margin: 0 auto;
        overflow: hidden;
        .about-us-contactUs-content-list-left {
          width: 678px;
          height: 397px;
          float: left;
        }
        .about-us-contactUs-content-list-right {
          width: calc(100% - 678px - 77px);
          height: 397px;
          float: left;
          padding-left: 77px;
          ul {
            li {
              width: 322px;
              font-size: 20px;
              text-align: left;
              line-height: 30px;
              padding: 10px 0;
            }
            li:nth-child(1) {
              padding: 5px 0 15px 0 !important;
            }
          }
        }
      }
    }
  }
  .about-us-contactUs-mobile {
    width: 100%;
    overflow: hidden;
    background-color: #fff;
    .about-us-contactUs-content {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      padding: 100px 0px 30px 0px;
      .about-us-contactUs-content-title {
        font-size: var(--v-font-size-32);
        font-weight: @fontWeight;
        padding: 30px 0 10px 0;
      }
      .about-us-contactUs-content-list {
        width: var(--v-width);
        margin: 0 auto;
        overflow: hidden;
        .about-us-contactUs-content-list-left {
          width: calc(var(--v-width) - 20px);
          //height: 397px;
          //float: left;
          padding-left: 10px;
        }
        .about-us-contactUs-content-list-right {
          width: calc(var(--v-width) - 20px);
          //height: 397px;
          //float: left;
          padding-left: 10px;
          padding-top: 10px;
          ul {
            li {
              width: 100%;
              font-size: var(--v-font-size-18);
              text-align: left;
              line-height: 20px;
              padding: 2px 0;
            }
          }
        }
      }
    }
  }
}
</style>
