import request from '@/utils/request'

/* 首页 */
/* 首页 - 全文搜索 */
export function reqHomeSearchResults (params) {
  let data = '?'
  data = data + 'search=' + params.search
  data = data + '&per_page=' + params.per_page
  data = data + '&page=' + params.page
  return request({
    url: 'api/scout-search' + data,
    method: 'GET'
  })
}
/* 首页 - 联系我们 */
export function reqHomeContactUsResults () {
  return request({
    url: 'api/home/contact-us/info',
    method: 'GET'
  })
}
/* 查看更多产品 */
export function reqProductListResults (id, params) {
  let data = '?'
  data = data + '&per_page=' + params.per_page
  data = data + '&page=' + params.page
  return request({
    url: `/api/product/${id}` + data,
    method: 'GET'
  })
}
