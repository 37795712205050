<template>
  <div class="disclaimer-container">
    <div
      :class="$global.isMobile ? 'disclaimer-box-mobile' : 'disclaimer-box-pc'"
    >
      <div class="pc">
        <div class="item_box bgc_F9FBFD pad_t_80 pad_b_80">
          <div class="tit_box mar_b_50">
            <span class="line"></span> <span class="tit_text">免责声明</span>
            <span class="line"></span>
          </div>
          <div class="type_page">
            <div class="text_box">
              <div class="text">
                本网站为广州帕克西软件开发有限公司（下称“我们”）拥有、管理和维护。使用本网站前请您仔细阅读以下条款，使用本网站则表明您已知悉并接受这些条款（以下称“本使用条款”）。若您不接受有关条款和条件的约束，请立即停止查阅本站或站内任何网页。
              </div>
            </div>
            <div class="text_box">
              <div class="title">一、网站使用规则</div>
              <div class="text_1">
                在用户遵守本使用条款的前提下，我们授予用户关于本网站的有限的、不可转让的、非独占的、可撤销的、仅为个人用途的非商业性的使用的权利。在任何情形下，本网站内容及服务（包括但不限于用户获得的用户名等身份信息）仅被许可由用户个人使用而不得被出售或转让。我们保留本使用条款未特别授予的所有权利。
                用户在使用本网站过程中，必须遵循以下原则：
              </div>
              <div class="text">1）遵守所有适用的法律和法规；</div>
              <div class="text">
                2）遵守本使用条款及所有相关的协议、规定和程序，同时也必须遵循与因特网有关的程序和惯例；
              </div>
              <div class="text">
                3）不得利用本网站从事违法犯罪的活动，包括分裂国家、教唆他人犯罪、侵犯计算机安全系统、干扰或混乱网络服务等危害国家安全或有损社会公共利益的行为；
              </div>
              <div class="text">
                4）不得利用本网站进行任何可能对互联网正常运转造成不利影响的行为；
              </div>
              <div class="text">
                5）不得侵犯我们或我们关联企业或其他任何第三方的专利权、著作权、商标权等知识产权、名誉权或其他任何合法权益，或从事损害我们利益或对我们造成不利影响的行为。
              </div>
              <div class="text">
                6）除非已遵守了本使用条款的其它规定或已取得了我们事先许可，用户不得为任何目的从事以下行为：
              </div>
              <div class="text_1">
                · 下载、复制、展示、分发、传播、上载、出版、发行本网站内容；
              </div>
              <div class="text_1">· 修改、编辑或以其他方式演绎本网站内容；</div>
              <div class="text_1">· 转让、转售、出租本网站内容或服务。</div>
              <div class="text_7">
                7）用户在使用本网站时违反任何上述规定，我们可自行判断要求用户改正或采取一切必要的措施（包括但不限于删除用户上传的内容、暂停或终止用户使用本网站的权利）以减轻用户不当行为造成的影响。
              </div>
            </div>
            <div class="text_box">
              <div class="title">二、外部链接</div>
              <div class="text">
                网站内容可能含有与其他网站的链接。请注意：该些外部链接仅为用户提供便利，不代表我们经营或参与经营该些网站，亦不代表用户被授予访问或使用该些网站的许可，用户应以自己的责任（包括但不限于遵守该些网站的使用条款）和费用访问该些网站，我们不对该些网站的内容和行为负责。
              </div>
            </div>
            <div class="text_box">
              <div class="title">三、责任限制</div>
              <div class="text">
                1）我们不能保证向用户提供的服务是连续的、即时的、准确的，不能保证缺陷一定会被及时纠正，也不能保证所提供的服务或服务器不受病毒或黑客等有害成分的侵袭。服务和网站内容依现状提供，我们不作任何形式的有关服务或网站内容的准确性、完整性、令人满意的品质、不侵权或适合某一特殊目的的保证。
              </div>
              <div class="text">
                2）我们保留随时修改、增减、取消、暂停、中断或终止提供本网站的服务（全部或部分）而无需事先告知用户的权利，我们在行使前述各项权利时亦无需对用户
                承担任何责任。
              </div>
              <div class="text">
                3）我们将尽可能地在本网站提供准确的信息。本网站内所涉及的产品图片与实物可能有细微区别，效果演示图和示意图仅供参考（图片为合成图、模拟演示图），有关我们产品的外观（包括但不限于颜色）请以实物为准。限于篇幅，本网站中所包含的信息（包括但不限于产品规格、功能说明等）可能不完整，请以有关产品使用说明书的具体信息为准。产品价格、规格、型号、供应状况如有更改，恕不另行通知。
              </div>
              <div class="text">
                4）我们保留随时更改网站内容所涉及的我们产品的设计、规格、工艺、材料的权利，而无需事先通知用户。
              </div>
              <div class="text">
                5）本网站服务器如因系统维护或升级而需暂停服务时，将尽量事先公告。如因系统维护、升级而需暂停服务或因服务器故障、硬件故障、其他不可抗力因素而导致停止服
                务，于暂停服务期间造成的一切不便和资料、数据等遗失，本网站将尽力挽救恢复；如确实无法恢复，本网站不承担任何责任。
              </div>
              <div class="text">
                6）我们不承担由于用户提供信息而可能产生的任何责任，包括但不限于因其他用户在没有获得授权的情形下使用用户提供信息，或因用户提供信息包含错误、不准确、
                病毒、诽谤、中伤、侵权等著作权法、隐私法或其他任何法律所禁止的内容或其他内容而产生的任何责任。我们亦不对用户提供信息在任何情形下的丢失、删除、
                移除或传输失败承担任何责任，用户应对其提供信息自行保留备份。我们保留随时删除或移除任何用户提供信息的权利，而无需通知用户，且不需承担任何责任。
              </div>
              <div class="text">
                7）对于用户通过本网站或外部链接与第三方发生的任何法律行为，包括但不限于与第三方的交易，由用户与该第三方自行承担全部的责任，我们不承担任何责任。
              </div>
              <div class="text">
                8）用户特此承认并接受：对于用户因使用本网站而遭受的任何直接或间接的损失，包括但不限于由于网站内容或服务的延误、不准确、错误和遗漏而产生的任何损害、责
                任、请求、损失或费用，我们及关联企业不承担任何责任。
              </div>
            </div>
            <div class="text_box">
              <div class="title">四、本使用条款的修改</div>
              <div class="text">
                我们有权在必要时对本使用条款予以修改而无需事先通知用户。一旦发生变动，我们将尽可能及时地在本网站上进行更新。如用户不同意所修改的内容，其可主动停止使用本网站。如用户继续使用本网站，则被视为接受修改后的使用条款。
              </div>
            </div>
            <div class="text_box">
              <div class="title">五、适用法律和争议解决</div>
              <div class="text">
                本网站使用条款的解释和执行均适用中华人民共和国法律。本使用条款及本网站内公布的任何附加条款，构成我们与用户之间关于使用本网站的完整协议。如果该协议的内容与适用的强制性法律规定不一致，则以该强制性法律规定为准。因该协议的履行产生的任何纠纷，首先由双方协商解决；协商不成的，任何一方均可向北京市有管辖权的法院提起诉讼。
              </div>
            </div>
            <div class="text_box">
              <div class="title">六、解释权</div>
              <div class="text">
                本使用条款的解释权及本网站的解释权归广州帕克西软件开发有限公司所有。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Disclaimer',
  metaInfo () {
    return {
      title: this.headMetaInfo.title,
      meta: [
        { name: 'keywords', content: this.headMetaInfo.keywords },
        { name: 'discription', content: this.headMetaInfo.content }
      ]
    }
  },
  data () {
    return {
      headMetaInfo: {
        title: '广州帕克西软件开发有限公司',
        keywords: '',
        content: ''
      }
    }
  },
  created () {
    this.$emit('commandEvent', 'header-banner', [])
  },
  mounted () {
    this.headMetaInfo.title = this.$global.head_title
    this.headMetaInfo.keywords = this.$global.head_keywords
    this.headMetaInfo.content = this.$global.head_description
    this.$emit('commandEvent', 'header-banner', [])
  }
}
</script>

<style lang="less" scoped>
body,
html,
textarea {
  font-family: PingFangSC, FZLTXHK, arial, "Hiragino Sans GB", "Microsoft YaHei",
    "WenQuanYi Micro Hei", sans-serif;
  font-size: 14px;
}
.disclaimer-container {
  .disclaimer-box-pc {
    width: 100%;
    padding-top: 60px;
    .pc .item_box {
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 40px;
      padding-right: 40px;
    }
    .bgc_F9FBFD {
      background: #f9fbfd !important;
    }
    .pad_t_80 {
      padding-top: 80px;
    }
    .pad_b_80 {
      padding-bottom: 80px;
    }
    .line {
      width: 3px;
      height: 18px;
      background: #0e57a2;
    }
    .pc .item_box .tit_box {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pc .item_box .tit_box .tit_text {
      font-size: 30px;
      color: #181818;
      margin: 0 20px;
    }
    .mar_b_50 {
      margin-bottom: 50px;
    }
    .text_box .text,
    .text_box .text_1 {
      font-size: 16px;
      color: #181818;
      line-height: 26px;
    }
    .type_page {
      width: 1200px;
    }
    .title {
      font-size: 24px;
      margin-top: 40px;
      margin-bottom: 30px;
    }
    .text {
      margin-bottom: 20px;
    }
    .text_7 {
      font-size: 16px;
      color: #181818;
      line-height: 26px;
      margin-top: 20px;
    }
  }
  .disclaimer-box-mobile {
    width: 100%;
    .pc .item_box {
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-left: 40px;
      padding-right: 40px;
    }
    .bgc_F9FBFD {
      background: #f9fbfd !important;
    }
    .pad_t_80 {
      padding-top: 80px;
    }
    .pad_b_80 {
      padding-bottom: 80px;
    }
    .line {
      width: 3px;
      height: 18px;
      background: #0e57a2;
    }
    .pc .item_box .tit_box {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .pc .item_box .tit_box .tit_text {
      font-size: 30px;
      color: #181818;
      margin: 0 20px;
    }
    .mar_b_50 {
      margin-bottom: 50px;
    }
    .text_box .text,
    .text_box .text_1 {
      font-size: 16px;
      color: #181818;
      line-height: 26px;
    }
    .type_page {
      width: calc(100% - 20px);
    }
    .title {
      font-size: 24px;
      margin-top: 40px;
      margin-bottom: 30px;
    }
    .text {
      margin-bottom: 20px;
    }
    .text_7 {
      font-size: 16px;
      color: #181818;
      line-height: 26px;
      margin-top: 20px;
    }
  }
}
</style>
