<template>
  <!-- 头部组件布局 -->
  <div class="header">
    <div class="header-nav" id="header-nav"
      @mouseover.stop="$global.isMobile ? (() => {}) : headerNavStyle(true)"
      @mouseout.stop="$global.isMobile ? (() => {}) : headerNavStyle(false)">
      <div class="header_pad">
        <div class="logo">
          <img
            v-if="mouseover_scroll_style"
            class="logo_img"
            src="../../assets/images/Home/logo.png"
            @click="toHomeIndex"
          />
          <img
            v-else
            class="logo_img"
            src="../../assets/images/Home/logo2.png"
            @click="toHomeIndex"
          />
        </div>
        <div v-if="!$global.isMobile" class="search-content">
          <div class="search" id="search">
            <input class="search-input" id="search-input" type="text" />
            <img
              v-if="mouseover_scroll_style"
              class="search-ioc"
              src="../../assets/images/Home/search.png"
              @click.stop="onSearchClick()"
              @keyup.enter="onSearchClick()"
            />
            <img
              v-else
              class="search-ioc"
              src="../../assets/images/Home/lujin.png"
              @click.stop="onSearchClick()"
              @keyup.enter="onSearchClick()"
            />
          </div>
        </div>
        <div v-if="$global.isMobile" class="menutool">
          <span :class="navblockDisplay ? 'menutool-content-close' : 'menutool-content-open'" @click.stop="onBlockDisplayClick(!navblockDisplay)">
            <i></i>
            <i></i>
            <i></i>
          </span>
        </div>
        <div :class="$global.isMobile ? 'nav-mobile' : 'nav-pc'" v-if="globalResults">
          <div class="nav_dl" :style="$global.isMobile ? (navblockDisplay ? 'display:block' : 'display:none') : ''">
            <div class="nav_dt"
              v-for="(item, index) in globalResults"
              :key="index"
              @mouseover.stop="$global.isMobile ? (() => {}) : onNavMouseover(index)"
              @mouseout.stop="$global.isMobile ? (() => {}) : onNavMouseout(index)"
              @click.stop="navmouseoverIndex != index ? onNavMouseover(index) : onNavMouseout(index)"
            >
              <div v-if="item.children && item.children.length > 0 ? true : false">
                <span class="nav_title" :style="'color:' + (index == navselectIndex ? '#ea6634' : '')">{{ item.name }}</span>
              </div>
              <div v-else>
                <span class="nav_title" :style="'color:' + (index == navselectIndex ? '#ea6634' : '')" @click.stop="onNavClick(index, item, item.url, null)">{{ item.name }}</span>
              </div>
              <div>
              <div
                v-if="item.children && item.children.length > 0 ? true : false"
                class="nav_dd"
                id="nav_dd"
                :style="
                  'width:' +
                  180 *
                    (item.children[0].children &&
                    item.children[0].children.length > 0
                      ? item.children.length
                      : 1) +
                  'px' +
                  ';' +
                  'display:' +
                  (index == navmouseoverIndex ? 'block' : 'none')
                "
              >
                <div v-if="item.children[0].children.length > 0">
                  <ul>
                    <li
                      class="nav_ul"
                      v-for="(item_i, index_i) in item.children"
                      :key="index_i"
                      :style="'width:' + 100 / item.children.length + '%'"
                    >
                      <span class="ul_title" @click.stop="$global.isMobile ? (navblockIndex = index_i) : (() => {})">{{ item_i.name }}</span>
                      <ul :style="$global.isMobile ? (navblockIndex !== index_i ? 'display: none' : 'display: block') : 'display: block'">
                        <li
                          class="ul_text"
                          v-for="(item_i_i, index_i_i) in item_i.children"
                          :key="index_i_i"
                          :style="'margin-left: 20px;'+'color:' + (item_i_i.id == navselectId ? '#ea6634' : '')"
                          @click.stop="onNavClick(index, item, item_i_i.url, item_i_i)"
                        >
                          {{ item_i_i.name }}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <ul>
                    <li
                      class="nav_ul"
                      v-for="(item_i, index_i) in item.children"
                      :key="index_i"
                      style="width: 100%; line-height: 48px"
                    >
                      <span
                        class="ul_text"
                        :style="'color:' + (item_i.id == navselectId ? '#ea6634' : '')"
                        @click="onNavClick(index, item, item_i.url, item_i)"
                        >{{ item_i.name }}</span
                      >
                    </li>
                  </ul>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-nav-placeholder" :style="($global.isMobile ? 'display:block' : 'display:none') + '; width: 100%; height: 60px;'"></div>
    <!-- 轮播图 -->
    <div class="header-banner" v-if="banners && banners.length > 0">
      <div class="swiper-container" v-if="banners.length > 1">
        <div class="swiper-wrapper">
          <el-carousel :interval="5000" arrow="always" class="swiper-slide" :height="bannerHeigth+'px'">
            <el-carousel-item v-for="(item, index) in banners" :key="index">
              <img id="header-banner-image" :src="item.thumb" alt="" @load="bannerImageLoaded()" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="swiper-banner" v-else>
        <img :src="banners[0].thumb" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      globalResults: [],
      windowScrollHeight: 0,
      mouseover_scroll_style: true,
      navmouseoverIndex: -1,
      navblockIndex: -1,
      navblockDisplay: false,
      navselectIndex: -1,
      navselectId: -1,
      // 轮播图数据
      bannerDisplay: true,
      bannerLocalStorage: [],
      banners: [],
      bannerHeigth: 800
    }
  },
  created () {
    this.$axios({
      url: 'api/navigation',
      method: 'get'
    }).then((res) => {
      /* console.log(res.data) */
      this.globalResults = res.data.navs
    })
    this.$axios({
      url: '/api/banner',
      method: 'get'
    }).then((res) => {
      /* console.log(res.data) */
      if (this.bannerDisplay) {
        this.banners = res.data.banner
        this.bannerDisplay = true
      }
      this.bannerLocalStorage = res.data.banner
    })
  },
  mounted () {
    // scroll
    window.removeEventListener('scroll', this.windowScroll)
    window.addEventListener('scroll', this.windowScroll)
    // resize
    window.removeEventListener('resize', this.windowResize)
    window.addEventListener('resize', this.windowResize)
    // keydown
    window.removeEventListener('keydown', this.windowKeydown)
    window.addEventListener('keydown', this.windowKeydown)
    /*
    var selectedNavValue = localStorage.getItem('selectedNavValue')
    if (selectedNavValue) {
      this.navselectIndex = selectedNavValue
      localStorage.removeItem('selectedNavValue')
    }
    */
    if (this.$global.isMobile) {
      this.headerNavStyle(true)
    } else {
      this.headerNavStyle(false)
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.windowScroll)
    window.removeEventListener('resize', this.windowResize)
    window.removeEventListener('keydown', this.windowKeydown)
    // localStorage.removeItem('selectedNavValue')
  },
  watch: { },
  methods: {
    onChangeBanner: function (imgs) {
      if (imgs) {
        this.bannerDisplay = (imgs.length > 0)
        this.banners = imgs
        this.headerNavStyle(true)
      } else {
        this.bannerDisplay = true
        this.banners = this.bannerLocalStorage
      }
    },
    toHomeIndex () {
      this.$router.push({ path: '/' })
      this.onChangeBanner(null)
      window.scrollTo(0, 0)
      if (this.$global.isMobile) {
        this.onBlockDisplayClick(false)
      }
    },
    windowScroll: function () {
      /* document.body.clientHeight:整个页面的高度 */
      /* document.documentElement.clientHeight:可见高度 */
      this.windowScrollHeight = document.documentElement.scrollTop || document.body.scrollTop
      this.headerNavStyle(false)
    },
    windowResize: function () {
      this.bannerImageLoaded()
    },
    windowKeydown: function (event) {
      const e = event || window.event
      if (e) {
        /* Enter键: e.keyCode == 13 */
        if (e.keyCode === 13) {
          /* console.log('Enter键') */
          if (!this.$global.isMobile) {
            this.onSearchClick()
          }
        }
      }
    },
    headerNavStyle: function (mouseoverBool) {
      const headerNav = document.getElementById('header-nav')
      const search = document.getElementById('search')
      const searchInput = document.getElementById('search-input')
      const navdd = document.getElementsByClassName('nav_dd')
      if (this.$global.isMobile) {
        this.mouseover_scroll_style = true
        if (headerNav) {
          headerNav.style.background = '#333'
          headerNav.style.color = '#fff'
          headerNav.style.zIndex = '9999'
        }
        if (search) {
          search.style.border = '1px solid #fff'
          searchInput.style.color = '#fff'
        }
        if (navdd) {
          for (var j = 0; j < navdd.length; j++) {
            navdd[j].style.background = '#333'
            navdd[j].style.color = '#fff'
          }
        }
      } else {
        if (this.windowScrollHeight > 500 || this.bannerDisplay === false) {
          this.mouseover_scroll_style = true
          if (headerNav) {
            headerNav.style.background = '#333'
            headerNav.style.color = '#fff'
            headerNav.style.zIndex = '9999'
          }
          if (search) {
            search.style.border = '1px solid #fff'
            searchInput.style.color = '#fff'
          }
          if (navdd) {
            for (var i = 0; i < navdd.length; i++) {
              navdd[i].style.background = '#333'
              navdd[i].style.color = '#fff'
            }
          }
        } else {
          if (this.windowScrollHeight > 200 || mouseoverBool) {
            this.mouseover_scroll_style = false
            if (headerNav) {
              headerNav.style.background = '#fff'
              headerNav.style.color = '#333'
            }
            if (search) {
              search.style.border = '1px solid #333'
              searchInput.style.color = '#333'
            }
            if (navdd) {
              for (var n = 0; n < navdd.length; n++) {
                if (navdd[n]) {
                  navdd[n].style.background = '#fff'
                  navdd[n].style.color = '#333'
                }
              }
            }
          } else {
            if (this.navmouseoverIndex === -1) {
              this.mouseover_scroll_style = true
              if (headerNav) {
                headerNav.style.background = 'none'
                headerNav.style.color = '#fff'
              }
              if (search) {
                search.style.border = '1px solid #fff'
                searchInput.style.color = '#fff'
              }
              if (navdd) {
                for (var l = 0; l < navdd.length; l++) {
                  if (navdd[l]) {
                    navdd[l].style.background = '#fff'
                    navdd[l].style.color = '#333'
                  }
                }
              }
            }
          }
        }
      }
    },
    onNavMouseover: function (index) {
      this.navmouseoverIndex = index
      this.headerNavStyle(true)
    },
    onNavMouseout: function (index) {
      this.navmouseoverIndex = -1
      this.headerNavStyle(false)
    },
    onBlockDisplayClick: function (val) {
      if (val !== undefined || val !== null) {
        this.navblockDisplay = val
      } else {
        this.navblockDisplay = !this.navblockDisplay
      }
      const headerNav = document.getElementById('header-nav')
      const search = document.getElementById('search')
      const searchInput = document.getElementById('search-input')
      const navdd = document.getElementsByClassName('nav_dd')
      if (this.navblockDisplay) {
        this.mouseover_scroll_style = true
        if (headerNav) {
          headerNav.style.background = '#333'
          headerNav.style.color = '#fff'
          headerNav.style.zIndex = '9999'
        }
        if (search) {
          search.style.border = '1px solid #fff'
          searchInput.style.color = '#fff'
        }
        if (navdd) {
          for (var i = 0; i < navdd.length; i++) {
            navdd[i].style.background = '#333'
            navdd[i].style.color = '#fff'
          }
        }
      } else {
        this.headerNavStyle(false)
      }
    },
    onNavClick: function (index, target, url, item) {
      /* console.log(index, target, url, item) */
      this.navmouseoverIndex = -1
      this.navselectIndex = index
      this.navselectId = ((item !== undefined && item !== null) ? item.id : -1)
      // localStorage.setItem('selectedNavValue', this.navselectIndex)
      /*
      switch (target.name) {
        case '核心技术':
          // this.$router.push('/technology?id=' + id)
          this.$router.push('/technology/' + id)
          break
        case '解决方案':
          // this.$router.push('/solution?id=' + id)
          this.$router.push('/solution/' + id)
          break
        case '产品中心':
          // this.$router.push('/product?id=' + id)
          this.$router.push('/product/' + id)
          break
        case '云服务':
          console.log('云服务')
          alert('云服务：暂未开放，敬请期待...')
          break
        case '视频专区':
          this.$router.push('/video')
          break
        case '合作案例':
          this.$router.push('/collaborate-case')
          break
        case '新闻动态':
          this.$router.push('/news')
          break
        case '关于我们':
          switch (item.name) {
            case '公司介绍':
              // this.$router.push('/about-us?id=' + 50)
              this.$router.push('/about-us/' + 50)
              break
            case '加入我们':
              // this.$router.push('/recruitment?id=' + 51)
              this.$router.push('/recruitment/' + 51)
              break
          }
          break
      }
      */
      if (url !== undefined && url) {
        if (url.indexOf('core-technology') !== -1) {
          const urlReg = new RegExp('core-technology', 'g') // g代表全部
          url = url.replace(urlReg, 'technology')
        }
        if (url.indexOf('/?id=') !== -1) {
          const paramsidReg = new RegExp('/\\?id=', 'g') // g代表全部
          url = url.replace(paramsidReg, '/')
        } else if (url.indexOf('?id=') !== -1) {
          const paramsidReg = new RegExp('\\?id=', 'g') // g代表全部
          url = url.replace(paramsidReg, '/')
        } else { }
        this.$router.push(url)
      }
      if (target.name === '云服务') {
        // console.log('云服务')
        // alert('云服务：暂未开放，敬请期待...')
        // window.open('https://facecloud.panxsoft.com/', '_blank')
        window.open('https://facecloud.panxsoft.com/web-admin/#/login', '_blank')
      }
      window.scrollTo(0, 0)
      if (this.$global.isMobile) {
        this.onBlockDisplayClick(false)
      }
    },
    bannerImageLoaded: function () {
      var headerBannerImage = document.getElementById('header-banner-image')
      if (headerBannerImage) {
        this.bannerHeigth = headerBannerImage.height
      }
    },
    onSearchClick: function () {
      var searchInput = document.getElementById('search-input')
      if (searchInput) {
        var text = searchInput.value
        if ((text !== '') && (text.replace(/\s*/g, '') !== '')) {
          localStorage.setItem('SearchValue', text)
          this.$router.push('/search?' + Date.parse(new Date()))
        }
        searchInput.value = ''
        searchInput.blur()
      }
    }
  }
}
</script>

<style scoped lang="less">
.swiper-container {
  img,.swiper-slide {
    width: 100%;
    min-width: var(--v-width);
  }
}
.swiper-banner {
  width: 100%;
  // min-width: var(--v-width);
}
.header {
  position: relative;
  .header-nav {
    position: fixed;
    width: 100%;
    z-index: 999;
    color: #fff;
    .header_pad {
      width: var(--v-width);
      height: 60px;
      line-height: 60px;
      margin: 0 auto;
      .logo {
        float: left;
        position: relative;
        top: 9px;
        left: 15px;
        cursor: pointer;
        .logo_img {
          width: 106px;
          height: 42px;
        }
      }
      .search-content {
        height: 60px;
        width: 214px;
        float: left;
        margin-left: 30px;
        .search {
          color: #fff;
          width: 214px;
          height: 31px;
          position: relative;
          top: 15px;
          border-radius: 18px;
          border: 1px solid #fff;
          background-color: rgba(255, 255, 255, 0);
          .search-input {
            color: #fff;
            width: calc(100% - 38px);
            height: 100%;
            border-radius: 18px;
            border: none;
            background-color: rgba(255, 255, 255, 0);
            text-indent: 20px;
            position: absolute;
            left: 0px;
            top: 0px;
          }
          .search-ioc {
            position: absolute;
            width: 22px;
            height: 22px;
            right: 10px;
            top: 4.5px;
            cursor: pointer;
          }
        }
      }
      .menutool {
        position: absolute;
        float: right;
        width: .56rem;
        height: .56rem;
        right: .5rem;
        top: .5rem;
        .menutool-content-open {
          display: block;
          width: 100%;
          height: .5rem;
          position: absolute;
          i {
            display: block;
            width: 100%;
            height: 1px;
            background-color: #fff;
            position: absolute;
            -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
          }
          i:first-child {
            left: 0;
            top: 0;
            -webkit-transform-origin: left top;
            transform-origin: left top;
          }
          i:nth-child(2) {
            right: 0;
            top: 50%;
            -webkit-transform-origin: right top;
            transform-origin: right top;
          }
          i:nth-child(3) {
            left: 0;
            bottom: -1px;
          }
        }
        .menutool-content-close {
          display: block;
          width: 100%;
          position: absolute;
          i {
            display: block;
            height: 1px;
            background-color: #fff;
            position: absolute;
            -webkit-transition: all .3s ease 0s;
            transition: all .3s ease 0s;
          }
          i:first-child {
            width: 100%;
            left: .1rem;
            top: .09rem;
            transform-origin: left top;
            transform:rotate(45deg);
            /* IE 9 */
            -ms-transform:rotate(45deg);
            /* Firefox */
            -moz-transform:rotate(45deg);
            /* Safari 和 Chrome */
            -webkit-transform:rotate(45deg);
            /* Opera */
            -o-transform:rotate(45deg);
          }
          i:nth-child(2) {
            width: 100%;
            left: -.1rem;
            top: .09rem;
            transform-origin: right top;
            transform:rotate(-45deg);
            /* IE 9 */
            -ms-transform:rotate(-45deg);
            /* Firefox */
            -moz-transform:rotate(-45deg);
            /* Safari 和 Chrome */
            -webkit-transform:rotate(-45deg);
            /* Opera */
            -o-transform:rotate(-45deg);
          }
          i:nth-child(3) {
            left: 0;
            bottom: -1px;
            opacity: 0;
          }
        }
      }
      .nav-pc {
        float: right;
        margin-right: 32px;
        .nav_dl {
          width: auto;
          .nav_dt:first-child {
            margin-left: 0px;
          }
          .nav_dt:hover .nav_title {
            color: #ea6634;
          }
          .ul_text { }
          .ul_text:hover {
            color: #ea6634;
          }
          .nav_dt {
            float: left;
            width: 80px;
            height: 60px;
            text-align: center;
            line-height: 60px;
            margin-left: 24px;
            position: relative;
            font-size: 14px;
            cursor: pointer;
            .nav_dd {
              position: absolute;
              padding: 0px 20px 20px 20px;
              display: none;
              background-color: #fff;
              .nav_ul {
                float: left;
                text-align: left;
                .ul_title {
                  height: 14px;
                  font-size: 14px;
                  font-weight: bold;
                  cursor: default;
                }
                .ul_titles {
                  height: 14px;
                  font-size: 14px;
                  font-weight: bold;
                }
                .ul_text {
                  height: 40px;
                  line-height: 40px;
                }
              }
            }
          }
        }
      }
      .nav-mobile {
        width: 100%;
        height: auto;
        max-height: calc(var(--window-height) - 60px);
        float: right;
        overflow-y: auto;
        .nav_dl {
          //width: 90%;
          //float: right;
          margin-left: 30px;
          .nav_dt:first-child {
            margin-left: 0px;
          }
          .nav_dt:hover .nav_title {
            color: #ea6634;
          }
          .ul_text { }
          .ul_text:hover {
            color: #ea6634;
          }
          .nav_dt {
            width: 100%;
            // height: 50px;
            text-align: left;
            line-height: 50px;
            // margin-left: 24px;
            position: relative;
            font-size: 14px;
            cursor: pointer;
            .nav_dd {
              padding: 0px 20px 0px 20px;
              display: none;
              background-color: #fff;
              .nav_ul {
                text-align: left;
                .ul_title {
                  height: 14px;
                  font-size: 14px;
                  font-weight: bold;
                  cursor: default;
                }
                .ul_titles {
                  height: 14px;
                  font-size: 14px;
                  font-weight: bold;
                }
                .ul_text {
                  height: 40px;
                  line-height: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.none {
  display: none !important;
}
.block {
  display: block !important;
}
</style>
