<template>
  <div id="app">
    <!-- 路由出口 -->
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
  created () {
    var locationHref = window.location.href
    // console.log(locationHref)
    /*
    不必在这里写这些逻辑，已在main.js的router.beforeEach里面实现
    if (locationHref.indexOf('/?id=') !== -1) {
      const idReg = new RegExp('/\\?id=', 'g') // g代表全部
      locationHref = locationHref.replace(idReg, '/')
      window.location.href = locationHref
      return
    } else if (locationHref.indexOf('?id=') !== -1) {
      const idReg = new RegExp('\\?id=', 'g') // g代表全部
      locationHref = locationHref.replace(idReg, '/')
      window.location.href = locationHref
      return
    } else { }
    if (locationHref.indexOf('/core-technology') !== -1) {
      const technologyReg = new RegExp('core-technology', 'g') // g代表全部
      locationHref = locationHref.replace(technologyReg, 'technology')
      window.location.href = locationHref
      return
    }
    if (locationHref.indexOf('technology/split_hair') !== -1) {
      // 头发分割
      // this.$router.push('/technology?id=25')
      this.$router.push('/technology/25')
    } else if (locationHref.indexOf('technology/360_roved') !== -1) {
      // 产品360°展示
      // this.$router.push('/technology?id=37')
      this.$router.push('/technology/37')
    } else if (locationHref.indexOf('technology/2.5d_glass_try_on') !== -1) {
      // 2.5D眼镜试戴
      // this.$router.push('/technology?id=30')
      this.$router.push('/technology/30')
    } else if (locationHref.indexOf('technology/3d_glass_try_on') !== -1) {
      // 3D眼镜试戴
      // this.$router.push('/technology?id=27')
      this.$router.push('/technology/27')
    } else if (locationHref.indexOf('technology/eye_tracking') !== -1) {
      // 视线追踪
      // this.$router.push('/technology?id=16')
      this.$router.push('/technology/16')
    } else if (locationHref.indexOf('technology/ar_game') !== -1) {
      // AR变脸
      // this.$router.push('/technology?id=33')
      this.$router.push('/technology/33')
    } else if (locationHref.indexOf('technology/face_reconstruction') !== -1) {
      // 人脸重建
      // this.$router.push('/technology?id=17')
      this.$router.push('/technology/17')
    } else if (locationHref.indexOf('technology/face_aging') !== -1) {
      // 人脸老化
      // this.$router.push('/technology?id=34')
      this.$router.push('/technology/34')
    // } else if (locationHref.indexOf('news/163') !== -1) {
    //   // 人脸重建，一键生成3D人脸模型
    //   this.$router.push('/news-details?id=163')
    //   this.$router.push('/news-details/163')
    } else {
      var paramsId = this.$route.params.id || this.$route.query.id || -1
      if (locationHref.indexOf('/technology') !== -1 || locationHref.indexOf('/core-technology') !== -1 ||
        locationHref.indexOf('/solution') !== -1 ||
        (locationHref.indexOf('/product') !== -1 && locationHref.indexOf('/product-list') === -1) ||
        locationHref.indexOf('/about-us') !== -1 ||
        locationHref.indexOf('/recruitment') !== -1 ||
        locationHref.indexOf('/news-details') !== -1) {
        try {
          const parseParams = parseInt(paramsId)
          if (paramsId === -1 || parseParams === -1 || isNaN(parseParams)) {
            console.log('跳转首页')
            if (locationHref.indexOf('localhost') === -1) { // 要加上这句判断，否则打包有问题，缺少按照路由文件夹打包
              this.$router.push({ path: '/' })
            }
          }
        } catch (e) {
          // TODO handle the exception
          // console.log(e)
          console.log('跳转首页')
          if (locationHref.indexOf('localhost') === -1) { // 要加上这句判断，否则打包有问题，缺少按照路由文件夹打包
            this.$router.push({ path: '/' })
          }
        }
      } else if (locationHref.indexOf('/news/') !== -1) {
        try {
          const newsReg = new RegExp('/news/', 'g') // g代表全部
          const newsIndex = locationHref.indexOf('/news/')
          const newsString = locationHref.substring(newsIndex).replace(newsReg, '')
          if (newsString !== undefined && newsString !== null && newsString.trim() !== '') {
            const newsParams = parseInt(newsString)
            if (newsParams !== -1 && isNaN(newsParams) === false) {
              // this.$router.push('/news-details?id=' + newsParams)
              this.$router.push('/news-details/' + newsParams)
            } else {
              console.log('跳转首页')
              if (locationHref.indexOf('localhost') === -1) { // 要加上这句判断，否则打包有问题，缺少按照路由文件夹打包
                this.$router.push({ path: '/' })
              }
            }
          }
        } catch (e) {
          // TODO handle the exception
          // console.log(e)
          console.log('跳转首页')
          if (locationHref.indexOf('localhost') === -1) { // 要加上这句判断，否则打包有问题，缺少按照路由文件夹打包
            this.$router.push({ path: '/' })
          }
        }
      } else { }
    }
    */
  },
  mounted () {
    // const locationHref = window.location.href
    // console.log(locationHref)
  }
}
</script>
<style lang="less">
a:hover,
a:focus {
  text-decoration: none;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body {
  -webkit-text-size-adjust: none;
}
fieldset,
img {
  border: 0;
}
img {
  vertical-align: top;
  max-width: 100%;
}
address,
caption,
cite,
code,
dfn,
em,
th,
var {
  font-style: normal;
  font-weight: normal;
}
ol,
ul {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
* html .clearfix {
  zoom: 1;
} /* IE6 */
*:first-child + html .clearfix {
  zoom: 1;
} /* IE7 */
.cli {
  clear: both;
  font-size: 0;
  height: 0;
  overflow: hidden;
  display: block;
}
.lclear {
  clear: left;
  font-size: 0;
  height: 0;
  overflow: hidden;
}
.fl {
  float: left;
}
.fr {
  float: right;
}
.row {
  display: flex;
}
.nones {
  display: none !important;
}
input[type="submit"],
input[type="reset"],
input[type="button"] {
  outline: none;
  filter: chroma(color=#000000);
}
input {
  outline: none;
}
/* ֹ
iframe{nifm2:expression(this.src='about:blank',this.outerHTML='');}
script{no2js:expression((this.src.toLowerCase().indexOf('http')==0)?document.close():'');}
*/
/* ıԼ˶
div{word-wrap: break-word;word-break: normal;}
p{text-align:justify; text-justify:inter-ideograph;}
*/
/*general*/
body {
  font-size: 12px;
  font-family: "微软雅黑", "宋体", "Arial Narrow", Helvetica, sans-serif;
  color: #000;
  line-height: 1.2;
  text-align: left;
}
a {
  color: #333;
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: content-box;
}
ul,
li,
ol {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
em,
i {
  font-style: normal;
}
</style>
