<template>
  <div class="Home">
    <Header ref="headerComponent" />
    <router-view @commandEvent="onCommandEvent"></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
export default {
  name: 'Home',
  components: {
    Header,
    Footer
  },
  methods: {
    /* 使用方式：this.$emit('commandEvent', 'target', 'val') */
    onCommandEvent: function (target, val) {
      /* console.log(target, val) */
      switch (target) {
        case 'header-banner':
          /* this.$emit('commandEvent', 'header-banner', [{ thumb: this.localResults.banner }]) */
          this.$refs.headerComponent.onChangeBanner(val)
          break
      }
    }
  },
  created () { },
  mounted () { }
}
</script>

<style lang="less">
.Home {
  width: 100%;
}
</style>
