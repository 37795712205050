import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import VueResource from 'vue-resource'
import './assets/iconfont/iconfont.css'
import 'amfe-flexible'
import * as filters from '@/filters' // 引入工具类
import global from './api/global.js'
import './styles/global.css'
import './styles/index.less'
import MetaInfo from 'vue-meta-info'

// 注册全局的过滤器
Object.keys(filters).forEach(key => {
  // 注册过滤器
  Vue.filter(key, filters[key])
})

Vue.use(VueResource)
Vue.use(ElementUI)
Vue.use(MetaInfo)
Vue.config.productionTip = false
Vue.prototype.$axios = axios
axios.defaults.baseURL = 'https://web-api.panxsoft.com'

Vue.prototype.$global = global

// https://www.in68.com/course/4238.html
// https://blog.csdn.net/weixin_44143512/article/details/97247527
router.beforeEach((to, from, next) => {
  // console.log('to', to)
  // console.log('from', from)
  if (to.meta) {
    if (to.meta.title) {
      document.title = to.meta.title
      // 存储为全局方便每个页面动态修改
      global.head_title = to.meta.title
    }
    if (to.meta.content) {
      if (to.meta.content.keywords) {
        document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
        // 存储为全局方便每个页面动态修改
        global.head_keywords = to.meta.content.keywords
      } else {
        document.querySelector('meta[name="keywords"]').setAttribute('content', '')
        global.head_keywords = ''
      }
      if (to.meta.content.description) {
        document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
        // 存储为全局方便每个页面动态修改
        global.head_description = to.meta.content.description
      } else {
        document.querySelector('meta[name="description"]').setAttribute('content', '')
        global.head_description = ''
      }
    }
  }
  // next() 这是旧的方案
  // 以下逻辑是新的方案
  const locationHref = window.location.href
  var fullPath = to.fullPath
  var toPath = null
  var regexp = null
  if (fullPath.indexOf('/?id=') !== -1) {
    regexp = new RegExp('/\\?id=', 'g') // g代表全部
    toPath = fullPath.replace(regexp, '/')
  } else if (fullPath.indexOf('?id=') !== -1) {
    regexp = new RegExp('\\?id=', 'g') // g代表全部
    toPath = fullPath.replace(regexp, '/')
  } else { }
  if (fullPath.indexOf('/core-technology') !== -1) {
    regexp = new RegExp('core-technology', 'g') // g代表全部
    toPath = fullPath.replace(regexp, 'technology')
  }
  if (fullPath.indexOf('technology/split_hair') !== -1) {
    // 头发分割
    // toPath = '/technology?id=25'
    toPath = '/technology/25'
  } else if (fullPath.indexOf('technology/360_roved') !== -1) {
    // 产品360°展示
    // toPath = '/technology?id=37'
    toPath = '/technology/37'
  } else if (fullPath.indexOf('technology/2.5d_glass_try_on') !== -1) {
    // 2.5D眼镜试戴
    // toPath = '/technology?id=30'
    toPath = '/technology/30'
  } else if (fullPath.indexOf('technology/3d_glass_try_on') !== -1) {
    // 3D眼镜试戴
    // toPath = '/technology?id=27'
    toPath = '/technology/27'
  } else if (fullPath.indexOf('technology/eye_tracking') !== -1) {
    // 视线追踪
    // toPath = '/technology?id=16'
    toPath = '/technology/16'
  } else if (fullPath.indexOf('technology/ar_game') !== -1) {
    // AR变脸
    // toPath = '/technology?id=33'
    toPath = '/technology/33'
  } else if (fullPath.indexOf('technology/face_reconstruction') !== -1) {
    // 人脸重建
    // toPath = '/technology?id=17'
    toPath = '/technology/17'
  } else if (fullPath.indexOf('technology/face_aging') !== -1) {
    // 人脸老化
    // toPath = '/technology?id=34'
    toPath = '/technology/34'
  } else if (fullPath.indexOf('technology/try_on_contact_lenses') !== -1) {
    // AR美瞳试戴
    // toPath = '/technology?id=60'
    toPath = '/technology/60'
  } else {
    if (fullPath.indexOf('/technology') !== -1 || fullPath.indexOf('/core-technology') !== -1 ||
      fullPath.indexOf('/solution') !== -1 ||
      (fullPath.indexOf('/product') !== -1 && fullPath.indexOf('/product-list') === -1) ||
      fullPath.indexOf('/about-us') !== -1 ||
      fullPath.indexOf('/recruitment') !== -1 ||
      fullPath.indexOf('/news-details') !== -1) {
      if (to.query.id) {
        try {
          const idparam = parseInt(to.query.id)
          if (idparam === -1 || isNaN(idparam)) {
            console.log('跳转首页')
            if (locationHref.indexOf('localhost') === -1) { // 要加上这句判断，否则打包有问题，缺少按照路由文件夹打包
              fullPath = '/'
            }
          }
        } catch (e) {
          // TODO handle the exception
          // console.log(e)
          console.log('跳转首页')
          if (locationHref.indexOf('localhost') === -1) { // 要加上这句判断，否则打包有问题，缺少按照路由文件夹打包
            fullPath = '/'
          }
        }
      }
    } else if (fullPath.indexOf('/news/') !== -1) {
      try {
        const newsReg = new RegExp('/news/', 'g') // g代表全部
        const newsIndex = fullPath.indexOf('/news/')
        const newsString = fullPath.substring(newsIndex).replace(newsReg, '')
        if (newsString !== undefined && newsString !== null && newsString.trim() !== '') {
          const newsParams = parseInt(newsString)
          if (newsParams !== -1 && isNaN(newsParams) === false) {
            // toPath = '/news-details?id=' + newsParams
            toPath = '/news-details/' + newsParams
          } else {
            console.log('跳转首页')
            if (locationHref.indexOf('localhost') === -1) { // 要加上这句判断，否则打包有问题，缺少按照路由文件夹打包
              fullPath = '/'
            }
          }
        }
      } catch (e) {
        // TODO handle the exception
        // console.log(e)
        console.log('跳转首页')
        if (locationHref.indexOf('localhost') === -1) { // 要加上这句判断，否则打包有问题，缺少按照路由文件夹打包
          fullPath = '/'
        }
      }
    } else { }
  }
  // console.log(toPath)
  if (toPath !== null) {
    next(toPath)
  } else {
    if (to.matched.length === 0) {
      if (locationHref.indexOf('localhost') === -1) { // 要加上这句判断，否则打包有问题，缺少按照路由文件夹打包
        from.name ? next({ name: from.name }) : next('/')
      }
    } else {
      next()
    }
  }
})

new Vue({
  router,
  store,
  axios,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
