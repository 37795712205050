// console.log('global.js init...')

/* 判断是否移动端 */
function isMobile () {
  if (navigator.userAgent.match(/(iPhone|iPad|Android|iOS|SymbianOS)/i)) {
    return true
  } else {
    return false
  }
}

const globalImpl = {}
globalImpl.platform = 'PC'
globalImpl.isMobile = false
globalImpl.head_title = null
globalImpl.head_keywords = null
globalImpl.head_description = null

/* 监听浏览器窗口大小 */
window.addEventListener('resize', onWindowResize)
function onWindowResize () {
  /* console.log('onWindowResize...') */
  onGlobalElement()
}

/* 设置全局css属性 */
onGlobalElement()
function onGlobalElement () {
  const vw = document.documentElement.clientWidth
  const vh = document.documentElement.clientHeight
  if (isMobile()) {
    // console.log('移动端')
    globalImpl.platform = 'Mobile'
    globalImpl.isMobile = true
    document.documentElement.style.setProperty('--window-width', vw + 'px')
    document.documentElement.style.setProperty('--window-height', vh + 'px')
    document.documentElement.style.setProperty('--v-width', vw + 'px')
    document.documentElement.style.setProperty('--v-font-size-12', '10px')
    document.documentElement.style.setProperty('--v-font-size-14', '12px')
    document.documentElement.style.setProperty('--v-font-size-16', '13px')
    document.documentElement.style.setProperty('--v-font-size-18', '14px')
    document.documentElement.style.setProperty('--v-font-size-20', '15px')
    document.documentElement.style.setProperty('--v-font-size-24', '16px')
    document.documentElement.style.setProperty('--v-font-size-27', '17px')
    document.documentElement.style.setProperty('--v-font-size-32', '18px')
  } else {
    // console.log('PC端')
    globalImpl.platform = 'PC'
    globalImpl.isMobile = false
    document.documentElement.style.setProperty('--window-width', '100%')
    document.documentElement.style.setProperty('--window-height', '100%')
    document.documentElement.style.setProperty('--v-width', '1200px')
    document.documentElement.style.setProperty('--v-font-size-12', '12px')
    document.documentElement.style.setProperty('--v-font-size-14', '14px')
    document.documentElement.style.setProperty('--v-font-size-16', '16px')
    document.documentElement.style.setProperty('--v-font-size-18', '18px')
    document.documentElement.style.setProperty('--v-font-size-20', '20px')
    document.documentElement.style.setProperty('--v-font-size-24', '24px')
    document.documentElement.style.setProperty('--v-font-size-27', '27px')
    document.documentElement.style.setProperty('--v-font-size-32', '32px')
  }
}

globalImpl.onUpdateHeadTitle = function (val) {
  if (val) {
    document.title = val
    globalImpl.head_title = val
  } else if (globalImpl.head_title) {
    document.title = globalImpl.head_title
  } else {
    document.title = '广州帕克西软件开发有限公司'
    globalImpl.head_title = '广州帕克西软件开发有限公司'
  }
}

globalImpl.onUpdateHeadKeywords = function (val, usedefault = true) {
  // document.querySelector('meta[name="keywords"]').setAttribute('content', val)
  // console.log(val)
  var stringResult = globalImpl.head_keywords
  var headKeywords = []
  if (usedefault === true) {
    if (stringResult && stringResult !== null) {
      headKeywords = stringResult.split(',')
    }
  }
  var valResult = ''
  if (val && val.length > 0) {
    for (var i = 0; i < val.length; i++) {
      if (headKeywords.indexOf(val[i]) === -1) {
        valResult += (val[i] + ',')
      }
    }
  }
  const valResultIndex = valResult.lastIndexOf(',')
  if (valResultIndex !== -1) {
    valResult = valResult.substring(0, valResultIndex)
  }
  if (usedefault === true) {
    if (stringResult && stringResult.length > 0 && valResult && valResult.length > 0) {
      // console.log((stringResult + ',' + valResult))
      document.querySelector('meta[name="keywords"]').setAttribute('content', (stringResult + ',' + valResult))
    } else if (stringResult && stringResult.length > 0) {
      document.querySelector('meta[name="keywords"]').setAttribute('content', stringResult)
    } else if (valResult && valResult.length > 0) {
      document.querySelector('meta[name="keywords"]').setAttribute('content', valResult)
    } else {
      // NULL
    }
  } else {
    if (valResult && valResult.length > 0) {
      document.querySelector('meta[name="keywords"]').setAttribute('content', valResult)
    } else if (stringResult && stringResult.length > 0) {
      document.querySelector('meta[name="keywords"]').setAttribute('content', stringResult)
    } else if (valResult && valResult.length > 0) {

    } else {
      // NULL
    }
  }
}

globalImpl.getHeadKeywords = function (val, usedefault = true) {
  // console.log(val)
  var stringResult = globalImpl.head_keywords
  var headKeywords = []
  if (usedefault === true) {
    if (stringResult && stringResult !== null) {
      headKeywords = stringResult.split(',')
    }
  }
  var valResult = ''
  if (val && val.length > 0) {
    for (var i = 0; i < val.length; i++) {
      if (headKeywords.indexOf(val[i]) === -1) {
        valResult += (val[i] + ',')
      }
    }
  }
  const valResultIndex = valResult.lastIndexOf(',')
  if (valResultIndex !== -1) {
    valResult = valResult.substring(0, valResultIndex)
  }
  if (usedefault === true) {
    if (stringResult && stringResult.length > 0 && valResult && valResult.length > 0) {
      return (stringResult + ',' + valResult)
    } else if (stringResult && stringResult.length > 0) {
      return stringResult
    } else if (valResult && valResult.length > 0) {
      return valResult
    } else {
      // NULL
    }
  } else {
    if (valResult && valResult.length > 0) {
      return valResult
    } else if (stringResult && stringResult.length > 0) {
      return stringResult
    } else {
      // NULL
    }
  }
  return ''
}

globalImpl.onUpdateHeadDescription = function (val) {
  document.querySelector('meta[name="description"]').setAttribute('content', val)
  globalImpl.head_description = val
}

export default globalImpl
