<template>
  <ul :class="$global.isMobile ? 'PageControl-mobile' : 'PageControl-pc'">
    <!-- first -->
    <li
      :class="['page-control-item', 'page-control-item--first', {'page-control-item--disabled' : index === 1}]"
      @click="first">first</li>
    <!-- prev -->
    <li
      :class="['page-control-item', 'page-control-item--prev', {'page-control-item--disabled' : index === 1}]"
      @click="prev">上一页</li>
    <!-- ··· -->
    <li
      :class="['page-control-item', 'page-control-item--more']"
      v-if="prevMore">···</li>
    <!-- page -->
    <li
      v-for="(pageValue, pageIndex) in pageResults" :key="pageIndex"
      :class="['page-control-item', {'page-control-item--current' : index === pageValue}]"
      @click="go(pageValue)"> {{ pageValue }} </li>
    <!-- ··· -->
    <li
      :class="['page-control-item', 'page-control-item--more']"
      v-if="nextMore">···</li>
    <!-- next -->
    <li
      :class="['page-control-item', 'page-control-item--next', {'page-control-item--disabled' : index === pageTotal}]"
      @click="next">下一页</li>
    <!-- last -->
    <li
      :class="['page-control-item', 'page-control-item--last', {'page-control-item--disabled' : index === pageTotal}]"
      @click="last">last</li>
  </ul>
</template>

<script>
/* https://segmentfault.com/a/1190000008606958 */
export default {
  name: 'PageControl',
  /* 通过props来接受从父组件传递过来的值 */
  props: {
    /* 页面中的可见页码，其他的以...替代, 必须是奇数 */
    perPage: {
      type: Number,
      default: 5
    },
    /* 当前页码 */
    pageIndex: {
      type: Number,
      default: 1
    },
    /* 每页显示条数 */
    pageSize: {
      type: Number,
      default: 9
    },
    /* 总记录数 */
    pageCount: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      index: this.pageIndex, /* 当前页码 */
      limit: this.pageSize, /* 每页显示条数 */
      size: this.pageCount || 10, /* 总记录数 */
      prevMore: false,
      nextMore: false
    }
  },
  watch: {
    pageIndex (val) { this.index = val || 1 },
    pageSize (val) { this.limit = val || 9 },
    pageCount (val) { this.size = val || 1 }
    /*
    pageResults: {
      deep: true,
      handler: function (newVal) {
        console.log('newVal', newVal)
      }
    }
    */
  },
  created () { },
  mounted () { },
  computed: {
    /* 计算总页码 */
    pageTotal () {
      return Math.ceil(this.size / this.limit)
    },
    /* 计算页码，当count等变化时自动计算 */
    pageResults () {
      const array = []
      const perPage = this.perPage
      const pageCount = this.pageTotal
      const current = this.index
      const _offset = (perPage - 1) / 2

      const offset = {
        start: current - _offset,
        end: current + _offset
      }

      /* -1, 3 */
      if (offset.start < 1) {
        offset.end = offset.end + (1 - offset.start)
        offset.start = 1
      }
      if (offset.end > pageCount) {
        offset.start = offset.start - (offset.end - pageCount)
        offset.end = pageCount
      }
      if (offset.start < 1) offset.start = 1

      /* this.prevMore = (offset.start > 1)
      this.nextMore = (offset.end < pageCount) */

      for (let i = offset.start; i <= offset.end; i++) {
        array.push(i)
      }

      this.more((offset.start > 1), (offset.end < pageCount), array)

      return array
    }
  },
  methods: {
    first () {
      if (this.index !== 1) {
        this.go(1)
      }
    },
    prev () {
      if (this.index > 1) {
        this.go(this.index - 1)
      }
    },
    next () {
      if (this.index < this.pageTotal) {
        this.go(this.index + 1)
      }
    },
    last () {
      if (this.index !== this.pageTotal) {
        this.go(this.pageTotal)
      }
    },
    go (page) {
      if (this.index !== page) {
        this.index = page
        /* 父组件通过change方法来接受当前的页码 */
        this.$emit('change', this.index)
      }
    },
    more (prevControl, nextControl, newVal) {
      /* console.log(prevControl, nextControl, newVal) */
      this.prevMore = prevControl
      this.nextMore = nextControl
    }
  }
}
</script>

<style scoped lang="less">
.PageControl-pc {
  display: inline-block;
  padding: 0;
  /* 样式一 */
  /*
  margin: 1rem 0;
  */
  margin: 0;
  font-size: 0;
  list-style: none;
  user-select: none;
  .page-control-item {
    display: inline;
    position: relative;
    cursor: pointer;
    /* 样式一 */
    /*
    font-size: 14px;
    line-height: 1.42857143;
    padding: 6px 12px;
    text-decoration: none;
    border: 1px solid #ccc;
    background-color: #fff;
    margin-left: -1px;
    color: #0275d8;
    */
    /* 样式二 */
    font-size: 16px;
    float: left;
    width: 48px;
    height: 38px;
    line-height: 38px;
    font-weight: bold;
    margin: 0 10px;
    color: #666666;
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      background-color: #f0f0f0;
      /* 样式一 */
      /*
      color: #0275d8;
      */
      color: #ea6634;
    }
    &.page-control-item--disabled,
    &.page-control-item--more{
        background-color: #fff;
        color: #505050;
    }
    //禁用
    &.page-control-item--disabled {
        cursor: not-allowed;
        opacity: .75;
    }
    &.page-control-item--more,
    &.page-control-item--current {
        cursor: default;
    }
    //选中
    &.page-control-item--current {
      /* 样式一 */
      /*
      background-color: #0275d8;
      color:#fff;
      position: relative;
      z-index: 1;
      border-color: #0275d8;
      */
      /* 样式二 */
      background: #ea6634;
      border-radius: 4px;
      color: #fff !important;
    }
  }
  .page-control-item--first{
    display: none;
  }
  .page-control-item--last{
    display: none;
  }
}
.PageControl-mobile {
  display: inline-block;
  padding: 0;
  /* 样式一 */
  /*
  margin: 1rem 0;
  */
  margin: 0;
  font-size: 0;
  list-style: none;
  user-select: none;
  .page-control-item {
    display: inline;
    position: relative;
    cursor: pointer;
    /* 样式一 */
    /*
    font-size: 14px;
    line-height: 1.42857143;
    padding: 6px 12px;
    text-decoration: none;
    border: 1px solid #ccc;
    background-color: #fff;
    margin-left: -1px;
    color: #0275d8;
    */
    /* 样式二 */
    font-size: var(--v-font-size-16);
    float: left;
    width: 38px;
    height: 28px;
    line-height: 28px;
    font-weight: bold;
    margin: 0 5px;
    color: #666666;
    &:first-child {
      margin-left: 0;
    }
    &:hover {
      background-color: #f0f0f0;
      /* 样式一 */
      /*
      color: #0275d8;
      */
      color: #ea6634;
    }
    &.page-control-item--disabled,
    &.page-control-item--more{
        background-color: #fff;
        color: #505050;
    }
    //禁用
    &.page-control-item--disabled {
        cursor: not-allowed;
        opacity: .75;
    }
    &.page-control-item--more,
    &.page-control-item--current {
        cursor: default;
    }
    //选中
    &.page-control-item--current {
      /* 样式一 */
      /*
      background-color: #0275d8;
      color:#fff;
      position: relative;
      z-index: 1;
      border-color: #0275d8;
      */
      /* 样式二 */
      background: #ea6634;
      border-radius: 4px;
      color: #fff !important;
    }
  }
  .page-control-item--first{
    display: none;
  }
  .page-control-item--last{
    display: none;
  }
  .page-control-item--prev,.page-control-item--next {
    width: 48px;
    height: 28px;
  }
  .page-control-item--more {
    width: 28px;
    height: 28px;
  }
}
</style>
