<template>
  <div class="News">
    <div :class="$global.isMobile ? 'news-nav-mobile' : 'news-nav-pc'">
      <div class="news-nav-list">
        <ul>
          <li v-for="(item, index) in newsnavResults" :key="index">
            <div @click="onNewsNavClick(item, index)">
              <div class="news-nav-list-titlt">{{ item.name }}</div>
              <div
                class="news-nav-list-line"
                v-if="newsreq_tag_id == item.id ? true : false"
              ></div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div :class="$global.isMobile ? 'news-content-mobile' : 'news-content-pc'">
      <div
        v-if="
          localResults &&
          localResults['data'] &&
          localResults['data'].length > 0
        "
      >
        <div class="news-content-videolist">
          <ul>
              <!-- @click.stop="toNewsDetails(item)" -->
              <li
                v-for="(item, index) in localResults.data"
                :key="index"
              >
                <router-link target="_blank" :to="'/news-details/' + item.id">
                  <div class="news-content-videolist-left">
                    <img :src="item.thumb" style="height: 100%" />
                  </div>
                  <div class="news-content-videolist-right">
                    <div class="news-content-videolist-right-title">
                      {{ item.title }}
                    </div>
                    <div class="news-content-videolist-right-description">
                      {{ item.description }}
                    </div>
                    <div
                      v-for="(keyword_item, keyword_index) in item.keywords"
                      :key="keyword_index"
                    >
                      <div class="news-content-videolist-right-keyword">
                        {{ keyword_item.name }}
                      </div>
                    </div>
                    <div class="news-content-videolist-right-updated">
                      {{ item.created_at | formatDate }}
                    </div>
                  </div>
                </router-link>
                <div class="news-content-videolist-line"></div>
              </li>
          </ul>
        </div>
        <div class="news-content-pagelist">
          <template v-if="newsDataTotal > 0">
            <PageControl
              :perPage ="$global.isMobile ? 3 : 5"
              :pageIndex="newsreq_page"
              :pageSize="newsreq_per_page"
              :pageCount="newsDataTotal"
              @change="onNewsPageChanged"
            ></PageControl>
          </template>
        </div>
      </div>
      <div v-else>
        <NullPage />
      </div>
    </div>
  </div>
</template>

<script>
import NullPage from '@/components/NullPage'
import PageControl from '@/components/PageControl'
export default {
  /* 新闻动态 */
  name: 'News',
  components: {
    NullPage,
    PageControl
  },
  metaInfo () {
    return {
      title: this.headMetaInfo.title,
      meta: [
        { name: 'keywords', content: this.headMetaInfo.keywords },
        { name: 'discription', content: this.headMetaInfo.content }
      ]
    }
  },
  data () {
    return {
      headMetaInfo: {
        title: '广州帕克西软件开发有限公司',
        keywords: '',
        content: ''
      },
      /* 当前导航栏选中的下标 */
      newsnavIndex: 0,
      /* 导航栏菜单 */
      newsnavResults: [],
      /* 页面数据 */
      localResults: [],
      /* 加载哪些tag_id的内容，默认：0（全部） */
      newsreq_tag_id: 0,
      /* 一页显示多少个内容，默认：9 */
      newsreq_per_page: this.$global.isMobile ? 6 : 9,
      /* 当前页数，默认：1（第一页） */
      newsreq_page: 1,
      /* 数据总页数 */
      newsPageCount: 0,
      /* 数据总计数 */
      newsDataTotal: 0
    }
  },
  watch: {},
  created () {
    this.reqBanner()
    /* 请求导航栏数据 */
    this.reqNavData()
  },
  mounted () {
    this.headMetaInfo.title = this.$global.head_title
    this.headMetaInfo.keywords = this.$global.head_keywords
    this.headMetaInfo.content = this.$global.head_description
    /* 请求页面数据 */
    this.reqNewsData(this.newsreq_tag_id, this.newsreq_per_page, this.newsreq_page)
  },
  computed: {},
  methods: {
    reqBanner: function () {
      this.$axios({
        url: '/api/other-banner?type=news',
        method: 'get'
      }).then((res) => {
        /* console.log(res.data) */
        if (res.data && res.data.result) {
          this.$emit('commandEvent', 'header-banner', [{ thumb: res.data.result.banner }])
        } else {
          this.$emit('commandEvent', 'header-banner', null)
        }
      })
    },
    /* 请求导航栏数据 */
    reqNavData: function () {
      this.newsnavResults = []
      this.$axios({
        url: '/api/news-tag',
        method: 'get'
      }).then((res) => {
        /* console.log(res.data) */
        if (res.data) {
          if (res.data.tags && res.data.tags.length > 0) {
            for (var i = 0; i < res.data.tags.length; i++) {
              this.newsnavResults.push({
                id: res.data.tags[i].id,
                name: res.data.tags[i].name
              })
            }
          }
        }
        if (this.newsnavResults.length === 0) {
          this.newsnavResults.push({ id: 0, name: '全部' })
        }
        this.newsreq_tag_id = this.newsnavResults[0].id
        this.reqNewsData(this.newsreq_tag_id, this.newsreq_per_page, this.newsreq_page)
      })
    },
    /* 请求页面数据 */
    /* tagId：tag_id，数据类型 */
    /* perPage：per_page，获取多少条数据 */
    /* toPage：page，获取第几页数据 */
    reqNewsData: function (tagId, perPage, toPage) {
      this.$axios({
        url:
          '/api/news' +
          '?per_page=' +
          perPage +
          '&page=' +
          toPage +
          (tagId !== 0 ? '&tag_id=' + tagId : ''),
        method: 'get'
      }).then((res) => {
        /* console.log(res.data) */
        this.localResults = null
        this.newsPageCount = 0
        this.newsDataTotal = 0
        if (res.data) {
          this.localResults = res.data.news
          // console.log(this.localResults)
          if (this.localResults) {
            this.newsPageCount = this.localResults.last_page
            this.newsDataTotal = this.localResults.total
            // 处理 meta[name="keywords"]
            if (this.localResults.data && this.localResults.data.length > 0) {
              var headKeywords = []
              var headContent = ''
              for (var i = 0; i < this.localResults.data.length; i++) {
                const localResultsKeywords = this.localResults.data[i].keywords
                if (localResultsKeywords && localResultsKeywords.length > 0) {
                  for (var j = 0; j < localResultsKeywords.length; j++) {
                    if (localResultsKeywords[j] && localResultsKeywords[j].name) {
                      if (headKeywords.indexOf(localResultsKeywords[j].name) === -1) {
                        headKeywords.push(localResultsKeywords[j].name)
                      }
                    }
                  }
                }
                if (this.localResults.data[i].title) {
                  headContent = headContent + (headContent !== '' ? '；' : '') + this.localResults.data[i].title
                }
              }
              if (this.newsnavResults !== undefined && this.newsnavResults != null && this.newsnavResults.length > 0) {
                for (var n = 0; n < this.newsnavResults.length; n++) {
                  if (this.newsnavResults[n] && this.newsnavResults[n].name && this.newsnavResults[n].name.indexOf('全部') === -1) {
                    headKeywords.push(this.newsnavResults[n].name)
                  }
                }
              }
              if (headKeywords !== null && headKeywords.length > 0) {
                this.$global.onUpdateHeadKeywords(headKeywords)
                this.headMetaInfo.keywords = this.$global.getHeadKeywords(headKeywords)
              }
              if (headContent !== '') {
                this.$global.onUpdateHeadDescription(headContent)
                this.headMetaInfo.content = headContent
              }
            }
          }
        }
      })
    },
    /* 导航栏被点击，切换页面数据类型 */
    onNewsNavClick: function (item, index) {
      this.newsnavIndex = index
      this.newsreq_tag_id = item.id
      this.newsreq_page = 1
      this.reqNewsData(
        this.newsreq_tag_id,
        this.newsreq_per_page,
        this.newsreq_page
      )
    },
    /* 请求加载切换页面的内容 */
    onNewsPageChanged: function (page) {
      this.newsreq_page = page
      this.reqNewsData(
        this.newsreq_tag_id,
        this.newsreq_per_page,
        this.newsreq_page
      )
    },
    /* 跳转到详情页面 */
    toNewsDetails: function (item) {
      // this.$router.push('/news-details?id=' + item.id)
      this.$router.push('/news-details/' + item.id)
    }
  }
}
</script>

<style lang="less" scoped>
ul {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}
img {
  vertical-align: top;
  max-width: 100%;
}
.News {
  width: 100%;
  overflow: hidden;
  text-align: center;
  color: #333;
  .news-nav-pc {
    width: 100%;
    height: 59px;
    box-shadow: 0px 3px 6px 0px rgba(123, 123, 123, 0.16);
    padding: 80px 0px 0px 0px;
    .news-nav-list {
      width: var(--v-width);
      margin: 0 auto;
      overflow: hidden;
      ul {
        display: inline-block;
        li {
          float: left;
          height: 59px;
          width: 160px;
          line-height: 59px;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          position: relative;
          cursor: pointer;
          .news-nav-list-titlt {
          }
          .news-nav-list-line {
            position: absolute;
            width: 160px;
            height: 3px;
            background: #ea6634;
            border-radius: 2px;
            bottom: 0px;
            left: 0px;
          }
        }
      }
    }
  }
  .news-nav-mobile {
    width: 100%;
    //height: 59px;
    box-shadow: 0px 3px 6px 0px rgba(123, 123, 123, 0.16);
    padding: 10px 0px 0px 0px;
    .news-nav-list {
      width: var(--v-width);
      margin: 0 auto;
      overflow: auto;
      text-align:center;
      ul {
        overflow: auto;
        white-space:nowrap;
        width: auto;
        width: -moz-fit-content;
        width: -webkit-fit-content;
        width: fit-content;
        float: left;
        display: inline-block;
        margin-left: 10px;
        margin-right: 10px;
        li {
          //float: left;
          //height: 59px;
          //width: 160px;
          //line-height: 59px;
          height: 36px;
          line-height: 36x;
          text-align: center;
          margin: 0px 10px;
          font-size: var(--v-font-size-20);
          font-weight: bold;
          position: relative;
          display: inline-block;
          cursor: pointer;
          .news-nav-list-titlt { }
          .news-nav-list-line {
            position: absolute;
            width: 100%;
            height: 3px;
            background: #ea6634;
            border-radius: 2px;
            bottom: 0px;
            left: 0px;
          }
        }
      }
    }
  }
  .news-content-pc {
    width: 100%;
    padding: 50px 0px 0px 0px;
    .news-content-videolist {
      width: var(--v-width);
      margin: 0 auto;
      ul {
        display: inline-block;
        li {
          overflow: hidden;
          padding: 45px 0;
          position: relative;
          cursor: pointer;
          .news-content-videolist-left {
            float: left;
            width: 370px;
            height: 171px;
          }
          .news-content-videolist-right {
            width: calc(100% - 370px - 25px);
            height: 171px;
            float: left;
            padding-left: 25px;
            .news-content-videolist-right-title {
              font-size: 20px;
              font-weight: bold;
              text-align: left;
              margin-top: 20px;
            }
            .news-content-videolist-right-description {
              font-size: 14px;
              text-align: left;
              line-height: 20px;
              margin: 20px 0;
              // https://www.cnblogs.com/xiaosuibu/p/8196067.html
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 4;
              overflow: hidden;
            }
            .news-content-videolist-right-keyword {
              float: left;
              width: 57px;
              height: 24px;
              font-size: 14px;
              text-align: center;
              color: #707070;
              background: #ebebeb;
              border-radius: 2px;
              line-height: 24px;
              font-weight: bold;
              margin-right: 10px;
            }
            .news-content-videolist-right-updated {
              float: right;
              font-size: 14px;
              color: #666666;
              text-align: right;
            }
          }
          .news-content-videolist-line {
            position: absolute;
            width: 95%;
            height: 1px;
            background-color: #d1d1d1;
            bottom: 0px;
            left: 2.5%;
          }
        }
      }
    }
    .news-content-pagelist {
      width: var(--v-width);
      overflow: hidden;
      background-color: #fff;
      margin: 80px auto 100px auto;
      ul {
        display: inline-block;
        margin: 0 auto;
      }
      /*
      ul {
        display: inline-block;
        margin: 0 auto;
        li {
          float: left;
          width: 48px;
          height: 38px;
          line-height: 38px;
          font-weight: bold;
          font-size: 16px;
          margin: 0 10px;
          color: #666666;
          cursor: pointer;
          .pitchOn {
            background: #ea6634;
            border-radius: 4px;
            color: #fff !important;
          }
        }
      }
      */
    }
  }
  .news-content-mobile {
    width: 100%;
    //padding: 8px 0px 0px 0px;
    .news-content-videolist {
      width: var(--v-width);
      margin: 0 auto;
      text-align:center;
      ul {
        display: inline-block;
        li {
          margin-top: 8px !important;
          margin-bottom: 8px !important;
          overflow: hidden;
          //padding: 45px 0;
          position: relative;
          cursor: pointer;
          text-align:center;
          //border: 1px dashed rgba(123, 123, 123, 0.2);
          .news-content-videolist-left {
            float: left;
            //width: 370px;
            //height: 171px;
            width: 100%;
          }
          .news-content-videolist-right {
            width: calc(var(--v-width) - 20px);
            //height: 171px;
            float: left;
            //padding-left: 25px;
            padding: 10px;
            .news-content-videolist-right-title {
              font-size:  var(--v-font-size-20);
              font-weight: bold;
              text-align: left;
              // margin-top: 8px;
              // https://www.cnblogs.com/xiaosuibu/p/8196067.html
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
            .news-content-videolist-right-description {
              font-size:  var(--v-font-size-14);
              text-align: left;
              line-height: 20px;
              margin: 10px 0;
              // https://www.cnblogs.com/xiaosuibu/p/8196067.html
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }
            .news-content-videolist-right-keyword {
              float: left;
              width: 57px;
              height: 24px;
              font-size: 14px;
              text-align: center;
              color: #707070;
              background: #ebebeb;
              border-radius: 2px;
              line-height: 24px;
              font-weight: bold;
              margin-right: 10px;
            }
            .news-content-videolist-right-updated {
              float: right;
              font-size: var(--v-font-size-14);
              color: #666666;
              text-align: right;
            }
          }
          .news-content-videolist-line {
            position: absolute;
            width: 95%;
            height: 1px;
            background-color: #d1d1d1;
            bottom: 0px;
            left: 2.5%;
          }
        }
      }
    }
    .news-content-pagelist {
      width: var(--v-width);
      overflow: hidden;
      background-color: #fff;
      margin: 10px auto 30px auto;
      ul {
        display: inline-block;
        margin: 0 auto;
      }
      /*
      ul {
        display: inline-block;
        margin: 0 auto;
        li {
          float: left;
          width: 48px;
          height: 38px;
          line-height: 38px;
          font-weight: bold;
          font-size: 16px;
          margin: 0 10px;
          color: #666666;
          cursor: pointer;
          .pitchOn {
            background: #ea6634;
            border-radius: 4px;
            color: #fff !important;
          }
        }
      }
      */
    }
  }
}
</style>
